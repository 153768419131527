import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
    
    get_all_articles_req,
    get_all_articles_err,
    get_all_articles_success,


    get_article_by_article_id_req,
    get_article_by_article_id_err,
    get_article_by_article_id_success,

    


} from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import {
    
        GET_ALL_ARTICLES_REQ,
        GET_ARTICLE_BY_ARTICLE_ID_REQ,

    } from './action.type';
    import toaster from "../../../utils/ReactTostify";
    import {isLoading} from '../action.loader/action.function.js';

    function* allArticlesSaga(payload) {
        const {data,callback} =payload;
          try {
              const store = yield select();
              // const userId =  store.user.user?.userId;
          console.log("store values ==========", store?.user?.user?.userId)
          yield put(isLoading(true));
            // let response = yield call(API.get,API_URL.GET_ALL_ARTICLES,API.getHeaders());
            let response = yield call(API.get,API_URL.GET_ALL_ARTICLES);
            yield put(isLoading(false));
            const result  = response.data;
            console.log("all articles saga result === ", result);
            yield put(get_all_articles_success(result));
            callback(null,result);
            
           
          }
          catch (error) {
            yield put(get_all_articles_err(error));
            callback(error,null);
            yield put(isLoading(false));
          }
        }


        // GET ARTICLE BY ARTICLE ID

        function* articleByArticleIdSaga(payload) {
          const {data,callback} =payload;
            try {
                const store = yield select();
                // const userId =  store.user.user?.userId;
            console.log("store values ==========", store?.user?.user?.userId)
            yield put(isLoading(true));
              // let response = yield call(API.post,API_URL.VIEW_ARTICLE,data,API.getHeaders());
              let response = yield call(API.post,API_URL.VIEW_ARTICLE,data);
              yield put(isLoading(false));
              const result  = response.data;
              console.log("get_article_by_article_id saga result === ", result);
              yield put(get_article_by_article_id_success(result));
              callback(null,result);
              
             
            }
            catch (error) {
              yield put(get_article_by_article_id_err(error));
              callback(error,null);
              yield put(isLoading(false));
            }
          }


        export default function* articleSaga() { 
            
            yield takeEvery(GET_ALL_ARTICLES_REQ, allArticlesSaga);

            yield takeEvery(GET_ARTICLE_BY_ARTICLE_ID_REQ, articleByArticleIdSaga);
    
            
            
        } 