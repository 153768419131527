import * as constance from "../constance/action.constance";
import {  
    
     // Add TO_CART
     ADD_TO_CART_REQUEST,
     ADD_TO_CART_SUCCESS,
     ADD_TO_CART_ERROR,
 
     // DELETE FROM CART
     DELETE_FROM_CART_REQUEST,
     DELETE_FROM_CART_SUCCESS,
     DELETE_FROM_CART_ERROR,


      // REFRESH_CART
      REFRESH_CART_REQUEST,
      REFRESH_CART_SUCCESS,
      REFRESH_CART_ERROR,
  

 
 
   } from "./action.type";


   // ADD TO CART


export function add_to_cart_success(result) {
    return {
      type: ADD_TO_CART_SUCCESS,
      result,
      status: constance.SUCCESS,
    };
  }
  export function add_to_cart_request(data,callback) {
    return {
      type: ADD_TO_CART_REQUEST,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  export function add_to_cart_error(error) {
    return {
      type: ADD_TO_CART_ERROR,
      error: error,
      status: constance.ERROR,
    };
  }



  // REFRESH CART


export function refresh_cart_success(result) {
  return {
    type: REFRESH_CART_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function refresh_cart_request() {
  return {
    type: REFRESH_CART_REQUEST,
    status: constance.REQUESTING,
  };
}
export function refresh_cart_error(error) {
  return {
    type: REFRESH_CART_ERROR,
    error: error,
    status: constance.ERROR,
  };
}



  // DELETE FROM CART


export function delete_from_cart_success(result) {
    return {
      type: DELETE_FROM_CART_SUCCESS,
      result,
      status: constance.SUCCESS,
    };
  }
  export function delete_from_cart_request(data,callback) {
    return {
      type: DELETE_FROM_CART_REQUEST,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  export function delete_from_cart_error(error) {
    return {
      type: DELETE_FROM_CART_ERROR,
      error: error,
      status: constance.ERROR,
    };
  }