import React, { useState, useEffect,useCallback } from "react";
import { connect } from 'react-redux';
import moment from "moment";
// import socketIOClient from "socket.io-client";
import Dropdown from "react-bootstrap/Dropdown";
import Collapse from '@material-ui/core/Collapse';
import '../components/myStyles.css';
// import { API_URL_ROOT } from "../redux-services/api/api.js";
import {socket} from "./SocketDetails.js";
import { get_all_custom_notification_req,post_custom_notification_read_status_by_id_request } from "../redux-services/actions/action.customnotification/action.function";
function CustomNotificationHeader(props){
  //   const [checked, setChecked] = React.useState(false);
  // const handleChangeReadMoreLess = () => {
  //   setChecked((prev) => !prev);
  // };
  
  // const api_base_url = "https://lab.landclues.com/backend"
  
  
      // notification data state
      // const [socket, setSocket] = useState(null)

   
    

  const [customNotificationAllData, setCustomNotificationAllData] = useState([]);
  
  const [customNotificationData, setCustomNotificationData] = useState([]);
  
  console.log("customNotificationAllData".customNotificationAllData);
  console.log("customNotificationData".customNotificationData);

  const nextPathDataPush = (path,data) =>{
    props.history.push({pathname: path,state: {
    data: data,
    }
  })
  }

  // const getAllCustomNotificationApiCall = useCallback(async() =>{
  //   if(props.user?.userId != null){

   
  //   await props.all_custom_notification_req(null,(error,success)=>{
  //     if(error){
        
  //       console.log("error",error);
  //     }    
  //     if(success.status == 200 ){
  //       if (success && success?.response.length > 0) {
  //         var filterData = [];

  //         success.response.map((cstm_notification, idx) =>{
  //           if((cstm_notification.read_status == 0)){
  //             filterData.push(cstm_notification);
  //           }
  //       })
  //       setCustomNotificationData(filterData);
  //      let socValue = success.response;
  //         socket.emit("data_capture", socValue);
          

  //       }
  //       if (success && success?.response.length <= 0) {
  //         console.log("data custom notification length empty ",success?.message);
          
  //       }
  //      }
  //      });
  //     }
  //   // handle the click event
  // }, [customNotificationData]);

//     useEffect(()=>{
//     if(socket === null)
//      {
      
//    // socket connection 
//   const socketData = socketIOClient(api_base_url,{
//     withCredentials: true,
//     extraHeaders: {
//       "my-custom-header": "abcd"
//     },
//     transports: ["websocket"]
//   });
//          setSocket(socketData)
//      }
//      if(socket)
//      {
//     //  socket.on('connect', () => {
    
//     //  console.log("Connected")
//     //  })
//   }

//   console.log("sockets called useEffect");

// },[])


// useEffect(() =>{
//   // socket.on("connect_error", () => {
//   //   console.error("socket error!");
//   //   socket.close();
//   // });

 
// },[])

// working code for getAllCustomNotificationApiCall
  
  const getAllCustomNotificationApiCall = async() =>{
    if(props.user?.userId != null){

    await props.all_custom_notification_req(null,(error,success)=>{
      if(error){
        
        console.log("error",error);
      }    
      if(success.status == 200 ){
        if (success && success?.response.length > 0) {
          var filterData = [];

          success.response.map((cstm_notification, idx) =>{
            if((cstm_notification.read_status == 0)){
              filterData.push(cstm_notification);
            }
        })
        // let Max_value=[...filterData];
        setCustomNotificationData(filterData.slice(0,5));
        setCustomNotificationAllData(filterData);
        console.log("customNotificationData".customNotificationAllData);

       
       let socValue = success.response;
        // socket.emit("data_capture", filterData);
        // setCustomNotificationAllData(success.response)
          // socket.emit("customNotification", socValue);
          

        }
        if (success && success?.response.length <= 0) {
          console.log("data custom notification length empty ",success?.message);
          
        }
       }
       });
      }

  }


  useEffect(() =>{

     getAllCustomNotificationApiCall();
  },[])


  // useEffect(() => {

  
  //   // getAllCustomNotificationApiCall();

  //     socket.on("connect", () =>{
  //       console.log("socket id obtained ===",socket.id);
  //       console.log("socket ===",socket)
        
  //     })
  //     socket.on("postcustomnotification",(infoData) => {
  //       console.log("emitted data information = ", infoData);
  
  //       if (infoData.action === "create") {
  //         if(infoData.response.length > 0){
  //         let filterSocketData = [];
  //         infoData.map((cstm_notification, idx) =>{
  //           if((cstm_notification.read_status == 0)){
  //             filterSocketData.push(cstm_notification);
  //           }
  //       })
  //       setCustomNotificationData(filterSocketData);
  //     }
  //       }
  //     });
  


  //   return () => {
  //     socket.off('connection');
  //   }
    
  // },[customNotificationData])

  useEffect(() => {
  
    // socket.on("customNotification", (inf) => {
    //   console.log("emitted text information = ", inf);

    //   if (inf && inf.length > 0) {
    //     let filterSocketData = [];
    //     inf.map((cstm_notification, idx) =>{
    //       if((cstm_notification.read_status == 0)){
    //         filterSocketData.push(cstm_notification);
    //       }
    //   })
    //   setCustomNotificationData(filterSocketData);
    //   }
    // });
    // socket.on("sent_data_values",(inf) => {
    //     console.log("emitted text information = ", inf);
  
    //     if (inf && inf.length > 0) {
    //       let filterSocketData = [];
    //       inf.map((cstm_notification, idx) =>{
    //         if((cstm_notification.read_status == 0)){
    //           filterSocketData.push(cstm_notification);
    //         }
    //     })
    //     setCustomNotificationData(filterSocketData);
    //     }
    //   })



  },[])

  useEffect(() => {
       socket.on("connect", () =>{
        console.log("socket id obtained ===",socket.id);
        console.log("socket ===",socket)
        
      })
      socket.on("postcustomnotification",(infoData) => {
        console.log("emitted data information = ", infoData);
  
        if (infoData.action === "create") {
          if(infoData?.response.length != 0){
          let filterSocketData = [];
          infoData.response.map((cstm_notification, idx) =>{
            if((cstm_notification.read_status == 0)){
              filterSocketData.push(cstm_notification);
            }
        })
        setCustomNotificationData(filterSocketData);
      }
        }
      });
  


    return () => {
      socket.off('connection');
    }
    

  },[])

  

  const postCustomNotificationReadStatusApiCall = async(dataValue) =>{
     console.log("data from onClick post cunstom notifications api call value ===", dataValue);
      const data = {
        "customNotificationId":dataValue.id 
    }
     await props.custom_notification_read_status_by_id_request(data,(error,success)=>{
        if(error){
          
          console.log("error",error);
        } 
        if(success.status == 200 ){
         
           getAllCustomNotificationApiCall();
          nextPathDataPush(`/Notifications/NotificationsView`,dataValue);
          
          }
      })
   
  }

 

  

  const onClickIndNotification = (data) =>{
    console.log("data is sent onClickNotification", data)
    postCustomNotificationReadStatusApiCall(data);
  }
  
  // const onClickViewAll=(filterData) =>{
  //   setCustomNotificationAllData(filterData);
    
  // }
  // setCustomNotificationData(filterData.slice(0,5));
  // setCustomNotificationAllData(filterData);
  
   const handleClick = () => 

  {
    
    setCustomNotificationData(customNotificationAllData);

  }
 
  return (
    <>
    <Dropdown className="notify">
                <Dropdown.Toggle
                  className="dropdown-toggle nav-link"
                  id="dropdown-basic"
                >
                  <i className="fa fa-bell"></i>{" "}
                 {(customNotificationData.length > 0)?(<span className="badge badge-pill">{(customNotificationData.length > 0)? customNotificationAllData.length : 0}</span>):null} 
                </Dropdown.Toggle>

                <Dropdown.Menu className="notification-list">
                 
                
                <Dropdown.Item
                    href="/"
                    className="notification-message"
                  >
                    <span>Notification</span>
                  </Dropdown.Item>

                  {(customNotificationData.length > 0)?(
               
                  customNotificationData.map((cstm_notification, idx) =>(
                    <Dropdown.Item
                    className="notification-message"
                    
                    key={idx}
                    
                  
                    onClick={() => onClickIndNotification(cstm_notification)}
                  >
                       
                    <div className="media">
                    
                      <div className="media-body">
                      
                        <p className="noti-details">
                          <span className="noti-title">{((cstm_notification.title != "undefined")||(cstm_notification.title != undefined) || (cstm_notification.title != null)) ? cstm_notification.title : '--'}</span>
                        </p>
                        <p className="noti-details">
                          <span className="noti-title">{((cstm_notification.message != "undefined")||(cstm_notification.message != undefined) || (cstm_notification.message != null)) ? cstm_notification.message : '--'}</span>
                        </p>

                        {/* <p className="noti-time">
                          <span className="notification-time">{((cstm_notification.created_at != "undefined")||(cstm_notification.created_at != undefined) || (cstm_notification.created_at != null)) ? `${moment(cstm_notification.created_at).format('HH:mm')}` : null}</span>
                        </p> */}
                      </div>
                    </div>
                  </Dropdown.Item>
                  
                  ))
                 ):null}
                 
                 {/* <Collapse in={checked} collapsedSize={3}>
                          <p className="comment-content">
                          {(customNotificationData.length > 0)?0 : 0}
                          </p>
                          </Collapse>
                          <div onMouseOver={handleChangeReadMoreLess} style={{
                                                    cursor: "pointer",
                                                }}>
                              {(checked == true)?"Read less": "Read More"}
                          </div> */}
                  <Dropdown.Item
                    // href="/Notifications/NotificationsViewAll"
                    className="notification-message text-center"
                  >
                   
                    {/* <button className="text-center"
                    type="button"
                     onClick={onClickViewAll}
                     >View all</button> */}
                      <div
                     
                      type="button"
                      onClick = {handleClick}
                      style={{
                        cursor: "pointer",
                    }}>
                     
                              {( customNotificationAllData)?"Read more": "Read Less"}
                      </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
    </>
  )
}

const memoPrevDataCompare = (prevProps, nextProps) =>{
    const prevPropData = JSON.stringify(prevProps.notificationData);
    const nextPropData = JSON.stringify(nextProps.notificationData);
  
   const dataFetchedComparator = prevProps.notificationData.map((cstm_notification, idx) =>{
  
    nextProps.notificationData.map((cstmnext_notification, idx) =>{
      if((cstm_notification.read_status !== cstmnext_notification.read_status)){
        return true;   
      }
      return false;
    })
  
  
  
              
          })
  
  return dataFetchedComparator
    
  }

const mapStateToProps = (state) => ({
    status: state.user.status,
    error: state.user.error,
  user_by_id:state.user.user_by_id,
  cart:state.cart.cartVal,
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn,
    requesting: state.user.requesting,
  notificationData:state.customnotification.all_customnotification,
})


const mapDispatchToProps = (dispatch) => {
	return {
		
     all_custom_notification_req: (data,callback) =>dispatch(get_all_custom_notification_req(data,callback)),
    custom_notification_read_status_by_id_request: (data,callback) =>dispatch(post_custom_notification_read_status_by_id_request(data,callback)),
	};}

export default connect(mapStateToProps, mapDispatchToProps)(CustomNotificationHeader);