import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
    create_support_request,
    create_support_success,
    create_support_error,
    get_all_support_req,
    get_all_support_err,
    get_all_support_success,

    create_support_conversation_by_ticket_id_success,
    create_support_conversation_by_ticket_id_request,
    create_support_conversation_by_ticket_id_error,

    get_support_conversation_by_ticket_id_req,
    get_support_conversation_by_ticket_id_fail,
    get_support_conversation_by_ticket_id_success,

} from './action.function.js';
import {
   
      CREATE_SUPPORT_REQUEST,
      CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ,
      GET_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ,
      GET_ALL_SUPPORT_REQ,
 

  } from './action.type';
import { 
    API_URL,
    } from '../../api/api.js';
import {API} from '../../../utils/networkApi';
import toaster from "../../../utils/ReactTostify";
import {isLoading} from '../action.loader/action.function.js';    


function* allSupportSaga(payload) {
    const {data,callback} =payload;
      try {
          const store = yield select();
          // const userId =  store.user.user?.userId;
      console.log("store values ==========", store?.user?.user?.userId)
      yield put(isLoading(true));
        let response = yield call(API.get,API_URL.GET_ALL_SUPPORT,API.getHeaders());
        yield put(isLoading(false));
        const result  = response.data;
        console.log("all support saga result === ", result);
        yield put(get_all_support_success(result));
        callback(null,result);
        
       
      }
      catch (error) {
        yield put(get_all_support_err(error));
        callback(error,null);
        yield put(isLoading(false));
      }
    }










function* createSupportSaga(payload) {
  const {data,callback} =payload;
  console.log("data sent to create order  saga", data)
  try {
    // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
    yield put(isLoading(true));
    let response = yield call(API.post,API_URL.CREATE_SUPPORT, data,API.getHeaders());
    yield put(isLoading(false));
    const result  = response.data;
    console.log("create support response saga",result);
    yield put(create_support_success(result))
    callback(null,result);
  }
  catch(error) {
    yield put(create_support_error(error));
    callback(error,null);
    yield put(isLoading(false));
    toaster("error", error);
  }
}


function* createSupportConversationByTicketIdSaga(payload) {
  const {data,callback} =payload;
  console.log("data sent to create support conversation saga", data)
  try {
    // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
    yield put(isLoading(true));
    let response = yield call(API.post,API_URL.CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID, data,API.getHeaders());
    yield put(isLoading(false));
    const result  = response.data;
    console.log("create support conversation response saga",result);
    yield put(create_support_conversation_by_ticket_id_success(result))
    callback(null,result);
  }
  catch(error) {
    yield put(create_support_conversation_by_ticket_id_error(error));
    callback(error,null);
    yield put(isLoading(false));
    toaster("error", error);
  }
}


function* getSupportConversationByTicketIdSaga(payload) {
  const {data,callback} =payload;
  console.log("data sent to get support conversation saga", data)
  try {
    // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
    yield put(isLoading(true));
    let response = yield call(API.post,API_URL.GET_SUPPORT_CONVERSATION_BY_TICKET_ID, data,API.getHeaders());
    yield put(isLoading(false));
    const result  = response.data;
    console.log("get support conversation response saga",result);
    yield put(get_support_conversation_by_ticket_id_success(result))
    callback(null,result);
  }
  catch(error) {
    yield put(get_support_conversation_by_ticket_id_fail(error));
    callback(error,null);
    yield put(isLoading(false));
    toaster("error", error);
  }
}


export default function* supportSaga() { 
  
    yield takeEvery( CREATE_SUPPORT_REQUEST, createSupportSaga);
    yield takeEvery( GET_ALL_SUPPORT_REQ, allSupportSaga);

    yield takeEvery( CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ, createSupportConversationByTicketIdSaga);
    yield takeEvery( GET_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ, getSupportConversationByTicketIdSaga);
    
    
} 