import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
     
    // all tests 
    get_all_test_req,
    get_all_test_err,
    get_all_test_success,

     // all popular tests 
     get_all_popular_test_req,
     get_all_popular_test_err,
     get_all_popular_test_success,

     // all tests 
    get_all_test_call_back_req,
    get_all_test_call_back_err,
    get_all_test_call_back_success,

     // all categorys 
     get_all_category_call_back_req,
     get_all_category_call_back_err,
     get_all_category_call_back_success,
    
  } from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import { 
         GET_ALL_TEST_REQ,
         GET_ALL_POPULAR_TEST_REQ,
         GET_ALL_CATEGORY_REQ


    } from './action.type';
    import {isLoading} from '../action.loader/action.function.js';
    // function* allTestSaga() {
    //     try {
    //       yield put(isLoading(true));
    //       let response = yield call(API.get,API_URL.ALL_TEST);
    //       yield put(isLoading(false));
    //       const result  = response.data;
    //       console.log("all test saga result === ", result);
    //       yield put(get_all_test_success(result));
          
         
    //     }
    //     catch (error) {
    //       yield put(isLoading(false));
    //       yield put(get_all_test_err(error));
    //     }
    //   }

    function* allTestSaga(payload) {
      const {data,callback} = payload;
      try {
        yield put(isLoading(true));
        let response = yield call(API.get,API_URL.ALL_TEST);
        yield put(isLoading(false));
        const result  = response.data;
        console.log("all test saga result === ", result);
        yield put(get_all_test_success(result));
        callback(null,result);
       
      }
      catch (error) {
        yield put(isLoading(false));
        callback(error,null);
        yield put(get_all_test_err(error));
      }
    }
      function* allPopularTestSaga() {
        try {
          yield put(isLoading(true));
          let response = yield call(API.get,API_URL.ALL_POPULAR_TEST);
          yield put(isLoading(false));
          const result  = response.data;
          console.log("popular test saga result === ", result);
          yield put(get_all_popular_test_success(result));
          
         
        }
        catch (error) {
          yield put(isLoading(false));
          yield put(get_all_popular_test_err(error));
        }
      }



      function* allTestCallbackSaga(payload) {
        const {data,callback} =payload;
            // console.log("data sent to get o saga", data)
        try {
          yield put(isLoading(true));
          let response = yield call(API.get,API_URL.ALL_TEST);
          yield put(isLoading(false));
          const result  = response.data;
          console.log("all test saga result === ", result);
          yield put(get_all_test_call_back_success(result));
          callback(null,result);
        }
        catch (error) {
          yield put(isLoading(false));
          
          yield put(get_all_test_call_back_err(error));
          callback(error,null);
        }
      }

      function* allCategoryCallbackSaga(payload) {
        const {data,callback} =payload;
            // console.log("data sent to get o saga", data)
        try {
          yield put(isLoading(true));
          let response = yield call(API.get,API_URL.ALL_CATEGORY);
          yield put(isLoading(false));
          const result  = response.data;
          console.log("all category saga result === ", result);
          yield put(get_all_category_call_back_success(result));
          callback(null,result);
        }
        catch (error) {
          yield put(isLoading(false));
          
          yield put(get_all_category_call_back_err(error));
          callback(error,null);
        }
      }

      
export default function* testSaga() { 
     yield takeEvery(GET_ALL_TEST_REQ, allTestSaga);
    // yield takeEvery(GET_ALL_TEST_REQ, allTestCallbackSaga);
    yield takeEvery(GET_ALL_POPULAR_TEST_REQ, allPopularTestSaga);
     yield takeEvery(GET_ALL_CATEGORY_REQ, allCategoryCallbackSaga);
}