import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Modal,ModalHeader,ModalBody } from "reactstrap";
import IMG01 from "../../../../assets/images/placeholderImg300x300.png";
import "./checkboxStyle.css";
import SelectPatient from "../../bookings/selectPatient/index";
import SelectAddress from "../../bookings/selectAddress/index";
import UserBooking from "../../bookings/booking/index";
import { refresh_cart_request } from "../../../../redux-services/actions/action.cart/action.function.js"
import { create_order_request, verify_order_request } from "../../../../redux-services/actions/action.order/action.function.js";
import testImage from "../../../../assets/images/tests.png";
import packagesImage from "../../../../assets/images/packages.png";

import { get_all_family_member_req } from "../../../../redux-services/actions/action.familymember/action.function.js";
import { get_all_my_address_req } from "../../../../redux-services/actions/action.myaddress/action.function.js";
import { verify_coupon_by_id_request } from "../../../../redux-services/actions/action.order/action.function.js";
import { get_all_coupons_req, get_delivery_charges_req} from "../../../../redux-services/actions/action.order/action.function.js";

import AddFamilyMember from "../../../users/dashboard/familyMembers/addFamilyMember/index.js";
import AddAddress from "../../../users/dashboard/myAddress/addAdress/index.jsx";
// import uploadPrescription from "../../dashboard/uploadPrescription";
import { CONSTANAT_MESSAGES, paymentMethod, paymentMethodCancellApply } from "../../../../utils/constants";
import toaster from "../../../../utils/ReactTostify";
import { API_URL, RAZORPAY_KEY_ID } from "../../../../redux-services/api/api.js";
import { API } from '../../../../utils/networkApi.js';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
 import {upload_prescription_req,upload_prescription_success} from "../../../../redux-services/actions/action.dashboard/action.function.js";
import dashboard from "../../dashboard";
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { API_URL_ROOT } from "../../../../redux-services/api/api.js";
import { get_all_prescriptions_req } from "../../../../redux-services/actions/action.dashboard/action.function.js";
import UploadPrescription from "../../dashboard/uploadPrescription";
import { Option } from "antd/lib/mentions";
function UserCheckout(props) {
  const { SearchBar, ClearSearchButton } = Search;
  const [familyMemberData, setFamilyMemberData] = useState([]);
  const [addFamilymemberButton, setAddFamilymemberButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectoptionsFamilyMember, setSelectoptionsFamilyMember] = useState(
    []
  );
  const [optionValueFamilyMember, setOptionValueFamilyMember] = useState([]);

  const [myAddressData, setMyAddressData] = useState([]);
  const [myAddressArea, setMyAddressArea] = useState(""); 
  const [addMyAddressButton, setAddMyAddressButton] = useState(false);
  const [openModalAddress, setOpenModalAddress] = useState(false);
  const [selectoptionsMyAddress, setSelectoptionsMyAddress] = useState([]);
  const [optionValueMyAddress, setOptionValueMyAddress] = useState([]);

//  const[prescription_photo,setPrescription_photo]=useState(null);

//  const prescription_photo=null;

  const [couponData, setCouponData] = useState([]);
  const [couponShow, setCouponShow] = useState(false);
  const [openModalCoupon, setOpenModalCoupon] = useState(false);
  const [selectoptionsCoupon, setSelectoptionsCoupon] = useState([]);
  const [optionValueCoupon, setOptionValueCoupon] = useState([]);
  const [discountValueCoupon, setDiscountValueCoupon] = useState("");
  const [showcountValueCoupon, setShowcountValueCoupon] = useState("");
  console.log("hisunil!!",optionValueCoupon.discount_value);
  console.log("hisuni@@",discountValueCoupon);
  console.log("hisunil%%",showcountValueCoupon);


  const [discountNameCoupon, setDiscountNameCoupon] = useState("");
  const [discountCouponId, setDiscountCouponId] = useState(null);
  const [couponValidateErrorMessage, setCouponValidateErrorMessage] =useState("");
  const [couponValidateState, setCouponValidateState] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [timeSlotDayUpdate, setTimeSlotDayUpdate] = useState("");
  const [timeSlotUpdate, setTimeSlotUpdate] = useState("");

  const [paymentMethodState, setPaymentMethodState] = useState("");

  const [serviceIdArray, setServiceIdArray] = useState([]);
  const [packageIdArray, setPackageIdArray] = useState([]);
  const [prescriptionArray, setPrescriptionArray] = useState([]);

  const [prescriptionRequiredState, setPrescriptionRequiredState] = useState(false);
  const [prescriptionRequiredFile, setPrescriptionRequiredFile] = useState(null);
  const [prescriptionRequiredFileName, setPrescriptionRequiredFileName] = useState("");
  const [prescriptionRequiredSelectState, setPrescriptionRequiredSelectState] = useState(false);
  const [prescriptionsData, setPrescriptionsData] = useState([]);
  const [noPrescriptionsData, setNoPrescriptionsData] = useState("");
  const [imgUploadPrescriptionStatus, setImgUploadPrescriptionStatus] = useState(false);
  const [handleTermsConditionClickState, setHandleTermsConditionClickState] = useState(false);

  const [paymentModeButtonState, setPaymentModeButtonState] = useState(false);
  //disable button
  const[remove_coupon,setRemove_coupon]=useState(false)
  const[apply_coupon,setApply_coupon]=useState(false)
  
  // console.log("discountValueCoupon", discountValueCoupon);
  // console.log("showcountValueCoupon", showcountValueCoupon);
  // const [submitButton, setSubmitButton] = useState(false);
  const [uploadPrescriptionModal, setUploadPrescriptionModal] = useState(false);

  
  const getFamilyMembersApiCall = async () => {
    await props.get_all_family_member(null, (error, success) => {
      if (error) {
        console.log("error", error);
      }
      if (success.status == 200) {
        

        if (success && success?.response.length > 0) {
          setFamilyMemberData(success?.response);
          const options =
            success?.response.length > 0
              ? success.response.map((familyMemberSelect, idx) => ({
                patient_id: familyMemberSelect.patient_id,
                patient_name: `${familyMemberSelect.patient_firstname} ${familyMemberSelect.patient_lastname}`
              }))
              : null;
          setSelectoptionsFamilyMember(options);
        }
        if (success && success?.response.length <= 0) {
          console.log("data length empty", success?.message);
          setAddFamilymemberButton(true);
        }
      }
    });
  };

  const getMyAddressApiCall = async () => {
    await props.get_all_my_address(null, (error, success) => {
      if (error) {
        console.log("error", error);
      }
      if (success.status == 200) {
        if (success && success?.response.length > 0) {
          setMyAddressData(success?.response);
          const optionsAddress =
            success?.response.length > 0
              ? success.response.map((myAddressSelect, idx) => ({
                address_id: myAddressSelect.address_id,
                address: `No ${myAddressSelect.house_number} ${myAddressSelect.street_name} ${myAddressSelect.area} ${myAddressSelect.landmark} ${myAddressSelect.city} ${myAddressSelect.state} ${myAddressSelect.pincode}`
              }))
              : null;
          setSelectoptionsMyAddress(optionsAddress);
        }
        if (success && success?.response.length <= 0) {
          console.log("data length empty", success?.message);
          setAddMyAddressButton(true);
        }
      }
    });
  };



  const getCouponApiCall = async () => {
    await props.get_all_coupons(null, (error, success) => {
      if (error) {
        console.log("error", error);
      }
      if (success.status == 200) {
        if (success && success?.response.length > 0) {
          setCouponData(success?.response);
          setCouponShow(true);
          const options =
            success?.response.length > 0
              ? success.response.map((couponSelect, idx) => ({
                coupon_id: couponSelect.couponid,
                coupon_name: couponSelect.coupon_name,
                 discount_value: couponSelect.discount_value
              }))
              : null;
          setSelectoptionsCoupon(options);
        }
        if (success && success?.response.length <= 0) {
          console.log("data length empty", success?.message);
          setCouponShow(false);
          //setAddCouponButton(true);
        }
      }
    });
  };

  const getPrescriptionsApiCall = async () => {

    await props.get_all_prescriptions(null, (error, success) => {
      if (error) {

        console.log("error", error);
      }
      if (success.status == 200) {
        if (success && success?.response.length > 0) {
          setPrescriptionsData(success?.response);
        }
        if (success && success?.response.length <= 0) {
          console.log("data Prescriptions length empty ", success?.message);
          setNoPrescriptionsData(success?.message);
        }
      }
    });


  }

  const getDeliveryChargesApiCall = async () => {

    await props.get_delivery_charges(null, (error, success) => {
      if (error) {

        console.log("error", error);
      }
      if (success.status == 200) {
        if (success && success?.response.length > 0) {
          
          setDeliveryFee(success?.response[0].customer_delivery_charge);
        }
        if (success && success?.response.length <= 0) {
          console.log("data DeliveryFee length empty ", success?.message);
          setDeliveryFee(0);
        }
      }
    });


  }

  const serviceidsArray = [];
  const packageidsArray = [];

  const cartValueSegregate = () => {
    console.log("hitting cart value");

    if (props.cart.length > 0) {
      let filteredService = props.cart.filter(
        (cartItemVal) => cartItemVal.serviceid
      );

      filteredService.forEach((serviceItem, idx) => {
        serviceidsArray.push({ service_id: serviceItem.serviceid });

      });
      setServiceIdArray(serviceidsArray);

      let filteredPopularService = props.cart.filter(
        (cartItemVal) => cartItemVal.id
      );
      filteredPopularService.forEach((servicePopularItem, idx) => {
        serviceidsArray.push({ service_id: servicePopularItem.id });

      });
      setServiceIdArray(serviceidsArray);

      let filteredPackage = props.cart.filter(
        (cartItemVal) => cartItemVal.package_id
      );
      filteredPackage.forEach((packageItem, id) => {
        packageidsArray.push({ package_id: packageItem.package_id });

      });
      setPackageIdArray(packageidsArray)


    }
  };

  const cartPrescriptionArray = [];
  const cart_prescription = () => {
    console.log("cart item values ====", props.cart);
    props.cart.forEach((cartItem, id) => {
      if (cartItem.prescription == 1) {
        cartPrescriptionArray.push({ "`prescription${id}`": cartItem.prescription });
      }


    });
    setPrescriptionArray(cartPrescriptionArray);

  }

  useEffect(() => {
    if ((props.user?.userId != null) || (props.user?.user_by_id != null)) {
      getFamilyMembersApiCall();
      getMyAddressApiCall();
      getDeliveryChargesApiCall();
    
     

      if (props.cart.length > 0) {
        getCouponApiCall();
        cart_prescription();
        getPrescriptionsApiCall();
        

      }
      
      cartValueSegregate();
    }

  }, []);
  const history = useHistory();

  const handleSuccess = (data) => {
    history.push({
      pathname: "/bookings/UserbookingSuccess",
      state: data
    });
  };

  const handleLogin = () => {
    history.push({
      pathname: "/home/login",
    });
  }

  // const handleBack = () => {
  //   props.history.goBack();

  //   props.reloadAddress();
  // };

  const reloadDataModal = async () => {
    await getFamilyMembersApiCall();
    await setOpenModal(!openModal);
    await setAddFamilymemberButton(false);
    await getPrescriptionsApiCall()
  };

  const reloadDataModalPrescription = async()=>
  {
    await getPrescriptionsApiCall();
    await getPrescriptionsApiCall();
    
  }
  const handleModalClick = () => setOpenModal(!openModal);

  const reloadDataModalAddress = async () => {
    await getMyAddressApiCall();

    await setOpenModalAddress(!openModalAddress);
    await setAddMyAddressButton(false);
  };

  const handleModalAddressClick = () => setOpenModalAddress(!openModalAddress);

  const handleModalCouponClick = () => 

  {
    setApply_coupon(false);
    setRemove_coupon(false);
    // setDiscountValueCoupon(props.discount_value);
    setShowcountValueCoupon(showcountValueCoupon);
    setOpenModalCoupon(!openModalCoupon);
  };
  

  //  const handleModalPrescriptionClick = () => setPrescriptionRequiredState(!prescriptionRequiredState);

  const validate = Yup.object({
    patient: Yup.string()
      .required("Patient must not be empty"),
    address: Yup.string().required("Address must not be empty"),
    timeslotId: Yup.string().required("Time Slot must not be empty"),
    // prescription_name: Yup.string().required('Prescription name must not be empty'),

    //  coupon: Yup.string().nullable(true),
    // coupon: Yup.string().required("payment method is required"),
    paymentMethod: Yup.string().required("payment method is required"),
    termsandcondition: Yup.boolean()
      .oneOf([true], "You must accept the terms and conditions")
  });

  // console.log("packageIdArray values", packageIdArray);
  // console.log("serviceIdArray values", serviceIdArray);
  // console.log("packageIdArray of values", packageidsArray);
  // console.log("serviceIdArray of values", serviceidsArray);
  console.log("cart item values ====", props.cart);
  const handleTermsConditionClick = (event) => {


    if (handleTermsConditionClickState == true) {
      setHandleTermsConditionClickState(false);
      formik.setFieldValue("termsandcondition", false);
    }
    if (handleTermsConditionClickState == false) {
      setHandleTermsConditionClickState(true);
      formik.setFieldValue("termsandcondition", true);
    }

  }

  const formik = useFormik({
    initialValues: {
      patient: "",
      address: "",
      address_id: "",
      timeslotId: "",
      coupon: "",
      paymentMethod: "",
      termsandcondition: false,
      prescription_photo:" "
    },
    validationSchema: validate,

    onSubmit: async (values) => {
      // setSubmitButton(true);
      console.log("values submission", values);
      console.log("user id ==", props.user.userId);
      var dt = formik.values;

      // "user_id":"3",
      // "patient_id":"67",
      // "coupon_id":"6",
      // "timeslot_id":"3",
      // "order_package":[{"package_id":"6"},{"package_id":"7"}],
      // "order_service":[{"service_id":"3"}],
      // "mobile":"9480364546",
      // "totalcost":"1500.00",
      // "address":"shimoga",
      // "payment_method":"COD"/"Online"

      var jsondata = {
        // user_id: props.user.userId,
        patient_id: dt.patient,
        address: dt.address,
        address_id: dt.address_id,
        timeslot_id: dt.timeslotId,
        timestamp: timeSlotDayUpdate,
        coupon_id: discountCouponId !== null ? discountCouponId : null,
        payment_method: dt.paymentMethod,
        mobile: props.user.mobile,
        prescription_name:dt.prescription_name,
        prescription_photo:dt.prescription_photo,
        order_package: packageIdArray,
        order_service: serviceIdArray,
        totalcost: ((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
          props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee) - discountValueCoupon :
          (props.user_by_id?.pamount > 0) ?
            props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee) :
            (discountValueCoupon !== "") ? props.total_cart_value + parseInt(deliveryFee) - discountValueCoupon :
              props.total_cart_value + parseInt(deliveryFee),
        // totalcost:((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
        // discountValueCoupon +  props.user_by_id?.pamount: props.total_cart_value + deliveryFee,
       
        // totalcost:props.total_cart_value,
        orderamount: props.total_cart_value,
        discountamount: (discountValueCoupon !== "") ? discountValueCoupon : 0,
        pamount: props.user_by_id?.pamount,
        // prescription_photo:prescription_photo,
        // prescription_name:"",

      };
      
      // console.log("values submission jsonData", orderamount);
      // console.log("values submission jsonData", discountamount);
      // console.log("values submission jsonData", pamount);
      console.log("values submission jsonData", jsondata);
      const data = new FormData();
      data.append('patient_id', dt.patient);
      data.append('address', dt.address);
      data.append('area', myAddressArea);
      data.append('address_id', dt.address_id);
      data.append('timeslot_id', dt.timeslotId);
      data.append('timestamp', timeSlotDayUpdate);
      data.append('coupon_id', discountCouponId !== null ? discountCouponId : null);
      data.append('payment_method', dt.paymentMethod);
      data.append('mobile', props.user.mobile);
      data.append('order_package', packageIdArray.length > 0 ? JSON.stringify(packageIdArray) : null);
      data.append('order_service', serviceIdArray.length > 0 ? JSON.stringify(serviceIdArray) : null);
      data.append('totalcost', parseFloat(jsondata.totalcost));
      data.append('upload_prescription', prescriptionRequiredFile);
      data.append('orderamount', parseFloat(jsondata.orderamount));
      data.append('discountamount',  parseFloat(jsondata.discountamount));
      data.append('pamount', jsondata.pamount);
      data.append('prescription_name', dt.prescription_name);
      data.append('prescription_photo',dt.prescription_photo);

      // data.append('prescription_name',"");

      // data.append('prescription_name'.dt.prescription_name);
      // await props.upload_prescription(data,(error,success)=>{
      //   if(error){
          
      //     console.log("error",error);
      //   }    
      //   if(success.status == 200 ){
      //     toaster("success", success.message);
      //     handleBack();
          
      //   }
      //    });



      if (props.cart.length > 0) {
        // if(prescriptionArray.length > 0) {
        //   setPrescriptionRequiredState(true);
        //   if(dt.paymentMethod === "ONLINE" ){

        //     await props.create_order(data, (error, success) => {
        //       if(error) {
        //         console.log("error", error);
        //       }
        //       if(success.status == 200){
        //         // handleBack();
        //         console.log("success",success);
        //         var options = {
        //           "key": RAZORPAY_KEY_ID, // Enter the Key ID generated 
        //           "amount": `${success.amount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        //           "currency": `${success.currency}`,
        //           "name": "PadmaShree Diagnostics",
        //           "description": "Thank you for your payment. Please give us some time",
        //           "image": `${API_URL.LOGO}`,
        //           "order_id": `${success.razorpay_order_id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        //           "handler": function (response){
        //             if(response) {
        //               const data = {
        //                 "order_id":`${response.razorpay_order_id}`,
        //                 "payment_id":`${response.razorpay_payment_id}`,
        //                 "razorpay_signature":`${response.razorpay_signature}`
        //               }

        //                props.verify_order(data, (error, success) => {
        //                 if(error) {
        //                   console.log("error", error);
        //                 }
        //                 if(success){
        //                   if(success.signatureIsValid === "true"){
        //                     var dataSent = {
        //                       order_id:`${response.razorpay_order_id}`,
        //                       timestamp: timeSlotDayUpdate
        //                     }
        //                     props.refresh_cart();
        //                     handleSuccess(dataSent);


        //                   }
        //                   if(success.signatureIsValid === "false"){
        //                     console.log("false success from razorpay",success);
        //                   }
        //                 }
        //               })
        //             }

        //           },
        //           "prefill": {
        //               "name": `${props.user_by_id?.response[0].first_name} ${props.user_by_id?.response[0].last_name}`,
        //               "email": `${props.user_by_id?.response[0].email}`,
        //               "contact": `${props.user_by_id?.response[0].mobile}`
        //           },
        //           // "notes": {
        //           //     "address": "Razorpay Corporate Office"
        //           // },
        //           "theme": {
        //               "color": "#3065b5"
        //           }
        //       };
        //       var razor = new window.Razorpay(options);
        //         razor.open();
        //       }
        //     });
        //   }
        //   if(dt.paymentMethod === "COD"){
        //     await props.create_order(data, (error, success) => {
        //       if(error){
        //         console.log("error", error);
        //       }
        //       if(success.status == 200){
        //         // handleBack();
        //         console.log("success",success);
        //         props.refresh_cart();
        //         let dataSentCOD = {
        //           order_id:`${success.orderid}`,
        //           timestamp: timeSlotDayUpdate
        //         }
        //         handleSuccess(dataSentCOD);
        //       }
        //     });

        //   }






        // }

        // else{
        //   if(dt.paymentMethod === "ONLINE" ){

        //     await props.create_order(data, (error, success) => {
        //       if(error) {
        //         console.log("error", error);
        //       }
        //       if(success.status == 200){
        //         // handleBack();
        //         console.log("success",success);
        //         var options = {
        //           "key": RAZORPAY_KEY_ID, // Enter the Key ID generated 
        //           "amount": `${success.amount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        //           "currency": `${success.currency}`,
        //           "name": "PadmaShree Diagnostics",
        //           "description": "Thank you for your payment. Please give us some time",
        //           "image": `${API_URL.LOGO}`,
        //           "order_id": `${success.razorpay_order_id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        //           "handler": function (response){
        //             if(response) {
        //               const data = {
        //                 "order_id":`${response.razorpay_order_id}`,
        //                 "payment_id":`${response.razorpay_payment_id}`,
        //                 "razorpay_signature":`${response.razorpay_signature}`
        //               }

        //                props.verify_order(data, (error, success) => {
        //                 if(error) {
        //                   console.log("error", error);
        //                 }
        //                 if(success){
        //                   if(success.signatureIsValid === "true"){
        //                     var dataSent = {
        //                       order_id:`${response.razorpay_order_id}`,
        //                       timestamp: timeSlotDayUpdate
        //                     }
        //                     props.refresh_cart();
        //                     handleSuccess(dataSent);


        //                   }
        //                   if(success.signatureIsValid === "false"){
        //                     console.log("false success from razorpay",success);
        //                   }
        //                 }
        //               })
        //             }

        //           },
        //           "prefill": {
        //               "name": `${props.user_by_id?.response[0].first_name} ${props.user_by_id?.response[0].last_name}`,
        //               "email": `${props.user_by_id?.response[0].email}`,
        //               "contact": `${props.user_by_id?.response[0].mobile}`
        //           },
        //           // "notes": {
        //           //     "address": "Razorpay Corporate Office"
        //           // },
        //           "theme": {
        //               "color": "#3065b5"
        //           }
        //       };
        //       var razor = new window.Razorpay(options);
        //         razor.open();
        //       }
        //     });
        //   }
        //   if(dt.paymentMethod === "COD"){
        //     await props.create_order(data, (error, success) => {
        //       if(error){
        //         console.log("error", error);
        //       }
        //       if(success.status == 200){
        //         // handleBack();
        //         console.log("success",success);
        //         props.refresh_cart();
        //         let dataSentCOD = {
        //           order_id:`${success.orderid}`,
        //           timestamp: timeSlotDayUpdate
        //         }
        //         handleSuccess(dataSentCOD);
        //       }
        //     });

        //   }


        // }

        if (dt.paymentMethod === "ONLINE") {

          await props.create_order(data, (error, success) => {
            // setSubmitButton(false);
            if (error) {
              console.log("error", error);
            }
            if (success.status == 200) {
              // handleBack();
              console.log("success", success);
              var options = {
                "key":RAZORPAY_KEY_ID, // Enter the Key ID generated 
                "amount": `${success.amount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": `${success.currency}`,
                "name": "PadmaShree Diagnostics",
                "description": "Thank you for your payment. Please give us some time",
                "image": `${API_URL.LOGO}`,
                "order_id": `${success.razorpay_order_id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": async function(response) {
                  if (response) {
                    const data = {
                      "order_id": `${response.razorpay_order_id}`,
                      "payment_id": `${response.razorpay_payment_id}`,
                      "razorpay_signature": `${response.razorpay_signature}`,
                      "userid": `${props.user.userId}`,
                    }

                    await props.verify_order(data, (error, success) => {
                      if (error) {
                        console.log("error", error);
                      }
                      if (success) {
                        if (success.signatureIsValid === "true") {
                          var dataSent = {
                            order_id: `${response.razorpay_order_id}`,
                            timestamp: timeSlotDayUpdate
                          }
                          props.refresh_cart();
                          handleSuccess(dataSent);


                        }
                        if (success.signatureIsValid === "false") {
                          console.log("false success from razorpay", success);
                          toaster('error', `${success.message}`);
                          

                        }
                      }
                    })
                  }

                },
                "prefill": {
                  "name": `${props.user_by_id?.first_name} ${props.user_by_id?.last_name}`,
                  "email": `${props.user_by_id?.email}`,
                  "contact": `${props.user_by_id?.mobile}`
                },
                // "notes": {
                //     "address": "Razorpay Corporate Office"
                // },
                "theme": {
                  "color": "#3065b5"
                },
                "modal": {
                  "escape": false,
                  "ondismiss": async function(){
                      // console.log('Checkout form closed');
                      let data = {
                        "order_id": `${success.razorpay_order_id}`
                      }

                      const result = await API.post(API_URL.ORDER_FAILED,data,API.getHeaders());
			                  let response = result.data;
			 console.log("response values of order failed ",response);
			 if(response.status === 200){
			    toaster('error', response.message);
       
			 
			 }
                      // toaster('error', `Payment verification failed`);
                      // toaster('error', `${success.message}`);
                  }
              }
              };
              var razor = new window.Razorpay(options);
              razor.open();
            }
          });
        }
        if (dt.paymentMethod === "COD") {
          await props.create_order(data, (error, success) => {
            console.log("error_message", error);
            if (error) {
              if(error.status == 404){
                toaster('error', "Failed to create order please try again");
              }
             
            }
            if (success.status == 200) {
              // handleBack();
              console.log("success", success);
              props.refresh_cart();
              let dataSentCOD = {
                order_id: `${success.orderid}`,
                timestamp: timeSlotDayUpdate
              }
              handleSuccess(dataSentCOD);
            }
          
            if(success.status === 412){
            toaster('error',`${success.message}`);
            }
          });

        }


      }



    },
    onReset: (values) => { }
  });

  const validateCoupon = async () => {
    
    
    if (discountNameCoupon !== null) {
      let data = {
         amount: parseFloat(props.total_cart_value),
        // amount:props.total_cart_value ,
        couponcode: discountNameCoupon
       
      };
       console.log("amount",props.total_cart_value);
      // setCouponValidateState(false);
      await props.verify_coupon_by_id(data, (error, success) => {
        // setDiscountValueCoupon("0")
        setApply_coupon(true);
        if (error) {
          console.log("error", error);
        }
        if (success.status == 200) {
          // toaster('error', success.message);
          console.log("error_message",success.final_amount
          )
          console.log("error_message",CONSTANAT_MESSAGES.MAX_EXCEED)
          console.log("successaaaaa!!!",showcountValueCoupon);
          console.log("successaaaaa######",success.discount_amount );
          console.log("successaaaaa@@@@@",success.final_amount);
          console.log("successaaaaa@@@@@",optionValueCoupon.discount_value);
          // setShowcountValueCoupon(success.final_amount);
          setDiscountValueCoupon( optionValueCoupon.discount_value);
          
          
          if (success.message === CONSTANAT_MESSAGES.MAX_EXCEED) {
            console.log("hi akshay")
            setCouponValidateState(true);
            toaster('error',success.message);
            console.log("error_message",success.message)

            // toaster('error', response.message);
            // setCouponValidateErrorMessage(CONSTANAT_MESSAGES.MAX_EXCEED);
            toaster('error', `${CONSTANAT_MESSAGES.MAX_EXCEED}`);
          }
          if (success.message === CONSTANAT_MESSAGES.MIN_AMT_RQRED) {
            setDiscountValueCoupon(showcountValueCoupon);
            setCouponValidateState(true);
            setShowcountValueCoupon(showcountValueCoupon)
            
            // optionValueCoupon.discount_value
            // setCouponValidateErrorMessage(CONSTANAT_MESSAGES.MIN_AMT_RQRED);
            toaster('error', `${CONSTANAT_MESSAGES.MIN_AMT_RQRED}`);
          }

          if (success.message === CONSTANAT_MESSAGES.COUPON_EXPIRED) {
            setCouponValidateState(true);
            setShowcountValueCoupon(showcountValueCoupon);
            setDiscountValueCoupon(discountValueCoupon);
            // setCouponValidateErrorMessage(CONSTANAT_MESSAGES.COUPON_EXPIRED);
           toaster('error',  `${CONSTANAT_MESSAGES.COUPON_EXPIRED}`);
            // toaster('success', `${CONSTANAT_MESSAGES.COUPON_EXPIRED}`);

          }

          if (success.message === CONSTANAT_MESSAGES.COUPON_SUCCESS) {
            // setDiscountValueCoupon(success.final_amount);
            setRemove_coupon(true);
            toaster('success', `${CONSTANAT_MESSAGES.COUPON_SUCCESS}`);
          }


        }
      });
    }
  };
//delete_coupon_name
  const delete_coupon_value = () => {
    setDiscountNameCoupon("");


  }
  const reloadPrecriptionDataModal = async() => {
  
   await setUploadPrescriptionModal(!uploadPrescriptionModal);
  }
  //

  console.log("formik values initialization  === ", formik.values);
  const handlePatient = (e) => {
    console.log("values of select patient ", e);
    formik.setFieldValue("patient", e.patient_id);
    setOptionValueFamilyMember(e);
  };

  const handleAddress = (e) => {
    console.log("values of select address ", e);
    formik.setFieldValue("address", e.address);
    formik.setFieldValue("address_id", e.address_id);
    setOptionValueMyAddress(e);

    const areaValue = myAddressData.map((addressValue, idx) => {
      if (addressValue.address_id == e.address_id) {
        return addressValue.area
        // addressValue.area
      }
    })
    setMyAddressArea(areaValue);
  };

  const handleCoupon = (e) => {
    console.log("values of select coupon ", e);
    console.log("showcountValueCoupon ", showcountValueCoupon);
    // formik.setFieldValue("coupon", e.coupon_id);
    setDiscountCouponId(e.coupon_id);
    setOptionValueCoupon(e);
    //  setDiscountValueCoupon(e.discount_value);
    setShowcountValueCoupon(showcountValueCoupon);

    setOpenModalCoupon(!openModalCoupon);
    setDiscountNameCoupon(e.coupon_name);
  };
  console.log("appply coupon Value=======", discountValueCoupon);
  console.log("appply coupon Value=======!!!!", showcountValueCoupon);
  console.log("appply coupon Value=======", discountNameCoupon);


  
  console.log(" select day Value=======", timeSlotDayUpdate);

  console.log(" select time slot=======", timeSlotUpdate);




  const handleTimeslot = ({ dayValue, timeslotid }) => {

    // debugger
    formik.setFieldValue("timeslotId", timeslotid);
    setTimeSlotDayUpdate(dayValue);
    // setTimeSlotUpdate(timeSlotIdUpdate);
  };
  // const handlePrescriptionUpload = (event) => {
  //   console.log("prescription required files uploaded", event.currentTarget.files[0]);
  //   setPrescription_photo(event.target.files[0])
  //   if (event.target.files.length !== 0) {
  //     setImgUploadPrescriptionStatus(true);
  //   var  prescription_photo = event.target.files[0];
  //     setPrescriptionRequiredFile(URL.createObjectURL(event.target.files[0]));
  //     setPrescriptionRequiredFileName(event.target.files[0].name)

  //   }
   
  // }
  const handleRadioOptn = (event) => {
    if (event.target.value == "Online Payment") {
      setPaymentMethodState(event.target.value);
      formik.setFieldValue("paymentMethod", "ONLINE");
      setPaymentModeButtonState(true);
    }
    if (event.target.value == "Cash On Delivery (COD)") {
      setPaymentMethodState(event.target.value);
      formik.setFieldValue("paymentMethod", "COD");
      setPaymentModeButtonState(false);
    }
  };

  console.log("payment method == ", paymentMethodState);
  // upload prescription
  // console.log("prescription_photo == ", prescription_photo);

  const handleModalPrescriptionClick = () => {
    // console.log("prescription_photo_with == ", prescription_photo);
    // const formData= { 
    //   'prescription_photo':prescription_photo,
    //   'prescription_name':"",
    // }

     setPrescriptionRequiredState((prevState)=>!prevState);
    // await props.upload_prescription(formData,(error,success)=>{
  
    //   console.log("prescription_photo_formdata == ", prescription_photo);

    //   if(error){

        
    //     console.log("error",error);
    //   }    
    //   if(success.status == 200 ){
    //     toaster("success", success.message);
    //     handleBack();
        
    //   }
    //    }
    // );

  }

  const handleModalUploadPrescriptionClick = () => {
    setPrescriptionRequiredState(true);

  }

  // select prescription
  const handleModalPrescriptionSelectClick = () => setPrescriptionRequiredSelectState(!prescriptionRequiredSelectState);

  const handleModalSelectPrescriptionClick = () => {
    setPrescriptionRequiredSelectState(true);
   
  }


  const prescriptionColumn = [
    {
      dataField: "prescription_photo",
      text: "Prescription Image",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        return (
          <div className="row">
            <img
              className="avatar-img w-25 h-25 rounded-circle mr-1"
              src={(row.prescription_photo == null) ? IMG01 : `${API_URL_ROOT}/${row.prescription_photo}`}
              // src={IMG01}
              alt=""
            />
          </div>
        );
      }
    },
    {
      dataField: "prescription_name",
      text: "Name",
      formatter: (cell, row, rowIndex) => {
        console.log("row values ==", row);
        return <div> {row.prescription_name ? `${row.prescription_name}` : null}</div>;
      }
    },

  ];



  
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });

  const selectPrescriptionRow = {
    mode: 'radio',
    clickToSelect: true,
    selectionHeshowcountValueCouponaderRenderer: () => 'Select Prescription',
    headerColumnStyle: { width: '10px' },
    selectColumnPosition: 'left',
    onSelect: (row, isSelect, rowIndex, e) => {
      let precriptionFileSelectData = row.prescription_photo;
      setPrescriptionRequiredFile(precriptionFileSelectData);
    }
  };

  console.log("PrescriptionRequiredFile", prescriptionRequiredFile);

  
  
  return (
    <div>
      <div className="content">
        <div className="container">

          <div className="row">
            <div className="col-md-7 col-lg-8">
              <div className="card">
                <div className="card-body">
                  <form
                    // action="bookings/booking-success/index"
                    onSubmit={formik.handleSubmit}
                  >
                    <div>
                      {/* family members 1st look*/}

                      {/* <div className="row">
                        <div className="col-md-6 col-lg-6">
                          <h4 className="card-title">Select family member</h4>

                          <Select
                            options={selectoptionsFamilyMember}
                            
                            onChange={(event) => {
                              handlePatient(event);
                            }}
                            id="exampleFormControlInput2"
                            name="select_patient"
                            getOptionLabel={(selectoptionsFamilyMember) =>
                              selectoptionsFamilyMember["patient_name"]
                            }
                            getOptionValue={(selectoptionsFamilyMember) =>
                              selectoptionsFamilyMember["patient_id"]
                            }

                          />

                         
                          {formik.touched.patient && formik.errors.patient ? (
                            <div
                              style={{
                                color: "red"
                              }}
                              className="mb-2"
                            >
                              {formik.errors.patient}
                            </div>
                          ) : null}
                          </div>
                          <div className="col-md-6 col-lg-6">
                          <div className="mt-3 float-right">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleModalClick}
                            >
                              <span>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span>Add Family Member</span>
                            </button>
                          </div>
                        </div>
                          </div>
                          </div> */}

                      <div className="row">
                        <div className="col-md-6 col-lg-6">
                          <div className="row">
                            <div className="col-md-6 col-lg-6">
                              <h4 className="card-title">Select Member</h4>
                            </div>
                            <div className="col-md-6 col-lg-6">
                              <div className="float-right">
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleModalClick}
                                  >
                                    <span>
                                      <i
                                        className="fa fa-plus-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span>Member</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>


                          <Select
                            options={selectoptionsFamilyMember}

                            onChange={(event) => {
                              handlePatient(event);
                            }}
                            id="exampleFormControlInput2"
                            name="select_patient"
                            getOptionLabel={(selectoptionsFamilyMember) =>
                              selectoptionsFamilyMember["patient_name"]
                            }
                            getOptionValue={(selectoptionsFamilyMember) =>
                              selectoptionsFamilyMember["patient_id"]
                            }

                          />


                          {formik.touched.patient && formik.errors.patient ? (
                            <div
                              style={{
                                color: "red"
                              }}
                              className="mb-2"
                            >
                              {formik.errors.patient}
                            </div>
                          ) : null}
                        </div>

                        
                          <div className="col-md-6 col-lg-6">
                            <div className="row">
                              <div className="col-md-6 col-lg-6">
                                <h4 className="card-title">Select Address</h4>
                              </div>
                              <div className="col-md-6 col-lg-6">
                                <div className="float-right">
                                  <div className="btn-group">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={handleModalAddressClick}

                                    >
                                      <span>
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span>Address</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <Select
                              options={selectoptionsMyAddress}

                              onChange={(event) => {
                                handleAddress(event);
                              }}
                              id="exampleFormControlInput2"
                              name="select_patient"
                              getOptionLabel={(selectoptionsMyAddress) =>
                                selectoptionsMyAddress["address"]
                              }
                              getOptionValue={(selectoptionsMyAddress) =>
                                selectoptionsMyAddress["address_id"]
                              }
                            />


                            {formik.touched.address && formik.errors.address ? (
                              <div
                                style={{
                                  color: "red"
                                }}
                                className="mb-2"
                              >
                                {formik.errors.address}
                              </div>
                            ) : null}
                          </div>

                        
                      

                    </div>
                </div>
              

              {/* address */}
              {/* <div>
                      <div className="row">
                        <div className="col-md-6 col-lg-6">
                          <h4 className="card-title">Select Address</h4>

                          <Select
                            options={selectoptionsMyAddress}
                            
                            onChange={(event) => {
                              handleAddress(event);
                            }}
                            id="exampleFormControlInput2"
                            name="select_patient"
                            getOptionLabel={(selectoptionsMyAddress) =>
                              selectoptionsMyAddress["address"]
                            }
                            getOptionValue={(selectoptionsMyAddress) =>
                              selectoptionsMyAddress["address_id"]
                            }
                          />

                          
                          {formik.touched.address && formik.errors.address ? (
                            <div
                              style={{
                                color: "red"
                              }}
                              className="mb-2"
                            >
                              {formik.errors.address}
                            </div>
                          ) : null}
                          </div>
                          <div className="col-md-6 col-lg-6">
                          <div className="mt-3 float-right">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleModalAddressClick}
                              
                            >
                              <span>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span>Add My Address</span>
                            </button>
                          </div>
                        </div>
                          </div>
                          </div>
                    </div> */}

              {/* prescription upload */}

              <div>

                {(prescriptionArray.length > 0) ? (
                  <div className="">
                    <h4 className="card-title">Prescription</h4>
                    <div className="row">
                      <div className="btn-group ml-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleModalUploadPrescriptionClick}

                        >
                          <span>
                            <i
                              className="fa fa-plus-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>Upload a prescription</span>
                        </button>
                      </div>
                      <div className="mx-2 my-auto">
                        <strong>OR</strong>
                      </div>

                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleModalSelectPrescriptionClick}

                        >
                          <span>
                            <i
                              className="fa fa-plus-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>Select a prescription</span>
                        </button>
                      </div>

                    </div>
                  </div>
                ) : null}
              </div>

              <div className="payment-widget">
                <div>
                  <h4 className="card-title">Select Time Slot</h4>
                  <UserBooking
                    setTimeSlotUpdate={setTimeSlotUpdate}
                    setTimeSlotDayUpdate={setTimeSlotDayUpdate}

                    onChangeValue={handleTimeslot}

                  />

                  {formik.touched.timeslotId &&
                    formik.errors.timeslotId ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.timeslotId}
                    </div>
                  ) : null}
                </div>
                <div></div>

                <h4 className="card-title">Payment Method</h4>
                {(props.user_by_id?.cancelcount > 3) ? (
                  <>
                    {paymentMethodCancellApply.map((option, idxopt) => (
                      <div className="payment-list" key={idxopt}>
                        <label className="payment-radio credit-card-option">
                          <input
                            type="radio"
                            name="radioBtn"
                            id={`optn[${idxopt}]`}
                            value={option}
                            checked={paymentMethodState === option}
                            onChange={(event) => {
                              handleRadioOptn(event);
                            }}
                          />
                          <span className="checkmark"></span>
                          {option}
                        </label>
                      </div>
                    ))}
                    {formik.touched.paymentMethod &&
                      formik.errors.paymentMethod ? (
                      <div
                        style={{
                          color: "red"
                        }}
                        className="mb-2"
                      >
                        {formik.errors.paymentMethod}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    {paymentMethod.map((option, idxopt) => (
                      <div className="payment-list" key={idxopt}>
                        <label className="payment-radio credit-card-option">
                          <input
                            type="radio"
                            name="radioBtn"
                            id={`optn[${idxopt}]`}
                            value={option}
                            checked={paymentMethodState === option}
                            onChange={(event) => {
                              handleRadioOptn(event);
                            }}
                          />
                          <span className="checkmark"></span>
                          {option}
                        </label>
                      </div>
                    ))}
                    {formik.touched.paymentMethod &&
                      formik.errors.paymentMethod ? (
                      <div
                        style={{
                          color: "red"
                        }}
                        className="mb-2"
                      >
                        {formik.errors.paymentMethod}
                      </div>
                    ) : null}
                  </>)
                }


                <div className="terms-accept col-md-6  row">
                  <div className="custom-checkbox row ">
                    <input type="checkbox"
                      value={handleTermsConditionClickState}
                      onChange={(event) => handleTermsConditionClick(event)}
                    />
                    <label className="labelstyle">
                      I have read and accept{" "}
                      <a href="/pages/terms">Terms &amp; Conditions</a>
                    </label>
                  </div>
                  {formik.touched.termsandcondition &&
                    formik.errors.termsandcondition ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.termsandcondition}
                    </div>
                  ) : null}
                </div>

                <div className="submit-section mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary submit-btn"
                    // onClick={() => handleChange()}
                 
                    disabled={(props.cart.length > 0) ? false : true}
                    // disabled={submitButton}
                   // props.cart.length == 0 &&
                  >
                    {/* {console.log("submitButton??",submitButton)} */}
                  {(paymentModeButtonState == true)?`Confirm and pay`:`Confirm`}
                    {/* Confirm and pay */}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <Modal
            size="lg"
            isOpen={openModal}
            toggle={handleModalClick}
            className="addFamilyMember"
          >
            <ModalHeader toggle={handleModalClick} className="titleName">
              <strong> Add Family Members </strong>
            </ModalHeader>
            <ModalBody>
              <AddFamilyMember
                {...props}
                toggle={handleModalClick}
                reloadFamilyMembers={reloadDataModal}
              />
            </ModalBody>
          </Modal>
          {/* Address */}
          <Modal
            size="lg"
            isOpen={openModalAddress}
            toggle={handleModalAddressClick}
            className="addAddress"
          >
            <ModalHeader toggle={handleModalAddressClick} className="titleName">
              <strong> Add Address </strong>
            </ModalHeader>
            <ModalBody>
              <AddAddress
                {...props}
                toggle={handleModalAddressClick}
                reloadAddress={reloadDataModalAddress}
              />
            </ModalBody>
          </Modal>


        </div>
      </div>

      <div className="col-md-5 col-lg-4 theiaStickySidebar">
        <div className="card booking-card">
          <div className="card-header">
            <h4 className="card-title">Booking Summary</h4>
          </div>
          {props.cart.length > 0 ? (
            <>
              {props.cart.map((cartItem, idx) => (
                <div className="card-body" key={idx}>
                  <div className="booking-doc-info">
                    <Link to="#" className="booking-doc-img">
                      <img
                        src={
                          cartItem.serviceid ? testImage : packagesImage
                        }
                        alt="product"
                      />
                    </Link>
                    <div className="booking-info">
                      <h4>
                        <Link to="#">
                          {cartItem.serviceid
                            ? cartItem.name
                            : cartItem.package_name}
                        </Link>
                      </h4>
                      <h6>
                        <Link to="#">
                          {cartItem.serviceid
                            ? cartItem.short_description
                            : cartItem.short_description}
                        </Link>
                      </h6>
                      <h6>
                        <Link to="#">
                          {cartItem.serviceid
                             ? `₹ ${parseInt(cartItem.cost)}`
                             : `₹ ${parseInt(cartItem.package_cost)}`}
                        </Link>
                      </h6>


                    </div>
                  </div>
                </div>
              ))}
              <div className="booking-summary">
                <div className="booking-item-wrap">
                  <ul className="booking-fee mx-2">
                    <li>
                    {console.log("deliveryFee",deliveryFee)}
                      {/* Delivery Fee <span>₹{deliveryFee}</span> */}
                      Delivery Fee <span>₹{parseInt(deliveryFee)}</span>
                    </li>
                    {
                      (props.user_by_id?.pamount > 0) ? (
                        <li>
                          Cancellation Fee of previous order<span>₹ {props.user_by_id?.pamount}</span>
                        </li>
                      ) : null
                    }
                    {/* <li>
                            Cancellation Fee of previous order<span>₹10</span>
                          </li> */}
{console.log("discountValueCoupon",deliveryFee)}
                    <li>
                      Discount Applied{" "}
                      <span>
                        ₹
                        {couponValidateState == true
                          ? "00"
                          : optionValueCoupon.discount_value!== ""
                            ?optionValueCoupon.discount_value
                            : "00"}
                      </span>
                    </li>

                    <li className="row mr-1 ml-1">
                      <input
                        type="text"
                        className="form-control w-50"
                        id="exampleFormControlInput1"
                        placeholder="Select Coupon"
                        // disabled={disabledInput}
                        name="coupon"
                        value={
                          discountNameCoupon !== ""
                            ? discountNameCoupon
                            : ""
                        }
                      // onChange={(event) => onChangeCouponName(event)}
                      //  onBlur={(event) =>onBlurCouponName(event)}
                      // {...formik.getFieldProps("coupon")}
                      />
                      <span className="mt-1">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleModalCouponClick}
                        >
                          <span>
                            <i
                              className="fa fa-plus-circle text-white"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span className="text-white">
                            Select Coupon
                          </span>
                        </button>
                      </span>
                    </li>
                  </ul>

                  <div className="text-center">
                
                    <button
                      type="button"
                      className="btn btn-primary mr-4 ml-6"
                      onClick={validateCoupon}
                      disabled={apply_coupon}
                    >
                      {/* <span>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </span> */}

                      <span>Apply Coupon</span>
                    </button>
                    
                     
                  
                    <button 
                      type="button"
                      className="btn btn-primary"
                      onClick={delete_coupon_value}
                      disabled={remove_coupon}
                    >
                      <span>Remove Coupon</span>
                    </button>
                    </div>
                
                 
                  {/* {couponValidateState == true ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="my-1 text-center"
                    >
                      {couponValidateErrorMessage}
                    </div>
                  ) : null} */}
                  <div className="booking-total">
                    <ul className="booking-total-list mb-1">
                      <li>
                        <span>Total</span>
                        {/* <span className="total-cost mr-3">
                          
                          ₹ {((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
                            props.total_cart_value + props.user_by_id?.pamount + deliveryFee - discountValueCoupon :
                            (props.user_by_id?.pamount > 0)  ?
                              props.total_cart_value + props.user_by_id?.pamount + deliveryFee :
                              (discountValueCoupon !== "") ? props.total_cart_value + deliveryFee - discountValueCoupon :
                                props.total_cart_value + deliveryFee}
                        </span> */}
<span className="total-cost mr-3">
  ₹ {Math.round(((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
    props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee) - discountValueCoupon :
    (props.user_by_id?.pamount > 0)  ?
      props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee) :
      (discountValueCoupon !== "") ? props.total_cart_value + parseInt(deliveryFee) - discountValueCoupon :
        props.total_cart_value + parseInt(deliveryFee))}
</span>


                        {/* <span className="total-cost mr-3">
                          ₹ {((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
                           discountValueCoupon +  props.user_by_id?.pamount: props.total_cart_value + deliveryFee}
                           
                               
                        </span>  */}
                        {/* <span className="total-cost mr-3"> ₹ {discountValueCoupon}</span> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="card-body">
              <h4>
                <Link to="#">No items in cart </Link>
              </h4>
            </div>
          )}
        </div>
        {/* coupon modal */}
        <Modal
          size="lg"
          isOpen={openModalCoupon}
          toggle={handleModalCouponClick}
          className="couponSelect"
        >
          <ModalHeader
            toggle={handleModalCouponClick}
            className="titleName"
          >
            <strong>Select Discount coupon </strong>
          </ModalHeader>
          <ModalBody>


            <div>
              {couponShow === true ? (
                <>
                  <Select
                    // <option value="1">Apple</option>
                   

                    options={selectoptionsCoupon}
                    // styles={customStyles}
                    onChange={(event) => {
                      handleCoupon(event);
                    }}
                    id="exampleFormControlInput4"
                    name="select_coupon"
                    getOptionLabel={(selectoptionsCoupon) =>
                      selectoptionsCoupon["coupon_name"]
                    }
                    getOptionValue={(selectoptionsCoupon) =>
                      selectoptionsCoupon["coupon_id"]
                    }
                  />
                </>
              ) : null}
            </div>
          </ModalBody>
        </Modal>
        {/* Upload Prescription */}
       
        <Modal
          size="lg"
          isOpen={prescriptionRequiredState}
          toggle={handleModalPrescriptionClick}
          className="addPrescription"
        >
          <ModalHeader toggle={handleModalPrescriptionClick} className="titleName">
            <strong> Add Prescription </strong>
          </ModalHeader>
          <ModalBody>
          <UploadPrescription
                {...props}
                reloadDashboard={reloadPrecriptionDataModal}
                  
                      
                  
                toggle={handleModalPrescriptionClick}
                reloadAddress={reloadDataModalPrescription}
              />
          </ModalBody>
        </Modal>
         
        {/* handleModalPrescriptionSelectClick */}

        <Modal
          size="lg"
          isOpen={prescriptionRequiredSelectState}
          toggle={handleModalPrescriptionSelectClick}
          className="addPrescription"
        >
          <ModalHeader toggle={handleModalPrescriptionSelectClick} className="titleName">
            <strong> Select Prescription </strong>
          </ModalHeader>
          <ModalBody>
            <div className="card card-table mb-0 mt-2">
              <div className="card-body">
                <div className="table-responsive">

                  <ToolkitProvider
                    bootstrap4
                    keyField='prescriptionid'
                    data={prescriptionsData}
                    columns={prescriptionColumn}
                    search
                  >

                    {
                      props => (
                        <div>
                          {/* <h6>Input something at below input field:</h6> */}
                          <SearchBar {...props.searchProps} />
                          <ClearSearchButton {...props.searchProps} />
                          <hr />
                          <BootstrapTable striped bordered hover
                            // defaultSorted={defaultSorted}
                            selectRow={selectPrescriptionRow}
                            pagination={pagination}
                            noDataIndication={() => (
                              <div className="text-alert">
                                <i>{noPrescriptionsData}</i>
                              </div>
                            )}
                            {...props.baseProps}
                          />
                        </div>
                      )
                    }
                  </ToolkitProvider>

                </div>
              </div>
            </div>

            <div className="row mt-3 tex-center">
              <div className="submit-section ml-2 mr-4">
                <button type="button" className="btn btn-primary submit-btn" 
                onClick={handleModalPrescriptionSelectClick}>Submit</button>
              </div>

              <div className="submit-section ml-2">
                <button
                  type="button"
                  className="btn btn-secondary submit-btn"
                  onClick={handleModalPrescriptionSelectClick}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
        
          
        </div >
      </div >
    </div >
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    get_all_family_member: (data, callback) =>
      dispatch(get_all_family_member_req(data, callback)),
      get_all_prescriptions: (data, callback) =>
      dispatch(get_all_prescriptions_req(data, callback)),
 
    get_all_my_address: (data, callback) =>
      dispatch(get_all_my_address_req(data, callback)),
    get_all_coupons: (data, callback) =>
      dispatch(get_all_coupons_req(data, callback)),
      get_delivery_charges: (data, callback) =>
      dispatch(get_delivery_charges_req(data, callback)),

    verify_coupon_by_id: (data, callback) =>
      dispatch(verify_coupon_by_id_request(data, callback)),

    create_order: (data, callback) =>
      dispatch(create_order_request(data, callback)),
    verify_order: (data, callback) =>
      dispatch(verify_order_request(data, callback)),

    refresh_cart: () =>
      dispatch(refresh_cart_request()),
    get_all_prescriptions: (data, callback) => dispatch(get_all_prescriptions_req(data, callback)),
  };
};
const mapStateToProps = (state) => {
  return {
    status: state.user.status,
    error: state.user.error,
    
    all_family_member: state.familymember.all_family_member,
    all_my_address: state.myaddress.all_my_address,
    create_order: state.order.create_order,
    all_coupons: state.order.all_coupons,
    user_by_id: state.user.user_by_id,
    cart: state.cart.cartVal,
    total_cart_value: state.cart.total_cost,
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn,
    requesting: state.user.requesting,
   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCheckout);
