import * as constance from "../constance/action.constance";
import { 
  
  
   // CREATE BOOK ON CALL
   CREATE_BOOK_ON_CALL_SUCCESS,
   CREATE_BOOK_ON_CALL_REQUEST,
   CREATE_BOOK_ON_CALL_ERROR,

   //Get  all prescriptions
GET_ALL_PRESCRIPTIONS_REQ,
GET_ALL_PRESCRIPTIONS_ERR,
GET_ALL_PRESCRIPTIONS_SUCC,
UPLOAD_PRESCRIPTION_REQ, 
UPLOAD_PRESCRIPTION_ERR, 
UPLOAD_PRESCRIPTION_SUCC,
  
  } from "./action.type";


  //CREATE BOOK ON CALL
export function create_book_on_call_success(result) {
    return {
      type:  CREATE_BOOK_ON_CALL_SUCCESS,
      result,
      status: constance.SUCCESS,
    };
  }
  export function create_book_on_call_request(data,callback) {
    return {
      type: CREATE_BOOK_ON_CALL_REQUEST,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  export function create_book_on_call_error(error) {
    return {
      type:CREATE_BOOK_ON_CALL_ERROR,
      error: error,
      status: constance.ERROR,
    };
  }


  // GET_ALL_PRESCRIPTIONS

export function get_all_prescriptions_req(data,callback) {
  return {
    type:GET_ALL_PRESCRIPTIONS_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_all_prescriptions_err(error) {
  return {
    type: GET_ALL_PRESCRIPTIONS_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_all_prescriptions_success(result) {
  return {
    type: GET_ALL_PRESCRIPTIONS_SUCC,
    result,
    status: constance.SUCCESS,
  };
}


// upload_PRESCRIPTION

export function upload_prescription_req(data,callback) {
  return {
    type:UPLOAD_PRESCRIPTION_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function upload_prescription_err(error) {
  return {
    type: UPLOAD_PRESCRIPTION_ERR,
    error,
    status: constance.ERROR,
  };
}

export function upload_prescription_success(result) {
  return {
    type: UPLOAD_PRESCRIPTION_SUCC,
    result,
    status: constance.SUCCESS,
  };
}

