import * as faqActionType from "../../actions/action.faq/action.type";

const faq = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallfaq_error: null,
    getallfaq_status: null,

    // faq

    get_all_faq: [],
     all_faq: null,
    
    },
    action
) =>{

    switch (action.type) {
    
       
          // all faq get
          case faqActionType.GET_ALL_FAQ_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case faqActionType.GET_ALL_FAQ_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_faq: action.result,
            });
          case faqActionType.GET_ALL_FAQ_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

             
    
          
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default faq;
  