import React from 'react';
import io from 'socket.io-client';
import { API_URL_ROOT } from "../redux-services/api/api.js";
const api_base_url = `${API_URL_ROOT}/`;

export const socket = io(api_base_url, { 
    withCredentials:false, 
    // transports: ['websocket'] 
     transports: ["websocket"],
});
