import { all } from "redux-saga/effects";
import userSaga from "../actions/action.users/userSaga.js"
import testSaga from "../actions/action.test/testSaga.js";
import packageSaga from "../actions/action.packages/packageSaga.js"
import faqSaga from "../actions/action.faq/faqSaga.js";
import family_memberSaga from "../actions/action.familymember/familyMemberSaga.js";
import delete_prescriptionSaga from "../actions/action. uploaddiscription/action.saga.js";
import my_addressSaga from "../actions/action.myaddress/myaddressSaga.js";
import cartSaga from "../actions/action.cart/cartSaga.js";
import my_orderSaga from "../actions/action.order/orderSaga.js";
import dashboardSaga from "../actions/action.dashboard/dashboardSaga.js";
import supportSaga from "../actions/action.support/supportSaga.js";
import feedbackSaga from "../actions/action.feedback/feedbackSaga.js";
import articleSaga from "../actions/action.article/articleSaga.js";
import customNotificationSaga from "../actions/action.customnotification/customNotificationSaga.js";
import check_logout_Saga from "../actions/action.logout/action.saga.js";

/**
 * Root saga
 * @returns {IterableIterator<AllEffect | GenericAllEffect<any> | *>}
 */
export default function* rootSaga() {
  yield all([userSaga(),
             testSaga(),
             packageSaga(),
             faqSaga(),
             family_memberSaga(),
             delete_prescriptionSaga(),
             my_addressSaga(),
             cartSaga(),
             my_orderSaga(),
             dashboardSaga(),
             supportSaga(),
             feedbackSaga(),
             articleSaga(),
             customNotificationSaga(),
             check_logout_Saga(),
            ]);
  
}