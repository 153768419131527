import React, {Component} from 'react';
import Aboutleft from "../../../assets/images/about-left.png";

class Aboutus extends Component{
    render(){
        return(
            <section className="aboutus-col">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="about-left">
                            <img src={Aboutleft} alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="about-right">

                            <h6>Caring for diagnostics</h6>
                            <h2>Certificate of Accreditation</h2>
                            {/* <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.</p>
                            <p>Ddoloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inve ntore veritatis et quasi architecto beatae vitae dicta sunt explicabo Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolo remque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                            <p className="mb-0">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inve ntore veritatis et quasi architecto beatae vitae dicta sunt explicabo, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolo remque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo Sed ut perspi ciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p> */}
                      <p>NABL stands for National Accreditation Board for Testing And Calibration Laboratories. NABL has agreements with ILAC (International Laboratory Accreditation Conference) and APLAC (Asia Pacific Laboratory Accreditation Cooperation). These are especially valuable for International recognition and mutual acceptance of test results. In short accreditation has worldwide acceptance.</p>
                      <p>NABL follows ISO 15189:2012, which is specific ISO followed worldwide for medical laboratories. This standard is used by APLAC (Asia Pacific Laboratory Accreditation Cooperation) and ILAC (International Laboratory Accreditation Co-operation) etc.</p>
                      <p>This means that an NABL accredited laboratory in India follows the same guidelines as any other accredited laboratory in the world.</p>
                      <p>NABL accreditation is based on evaluation of technical competence whereas ISO certification is based upon verification of proper documentation. Though documentation forms an essential and important part of NABL, it lays down great emphasis on quality and the lab can be denied accreditation if one does not comply with the quality standards.</p>
                       <p>It means that our lab is continuously striving for quality and this will further progress our cause of providing quality services. Laboratory Accreditation provides formal recognition of competent laboratories like ours, thus providing a ready means for customers to find reliable testing services in order to meet the demands.</p> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }
}

export default Aboutus;
	
    