import * as family_memberActionType from "../../actions/action.familymember/action.type";

const familymember = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallfamily_member_error: null,
    getallfamily_member_status: null,
    // getallpopularfamily_member_error: null,
    // getallpopularfamily_member_status: null,

    // family_member

    add_family_member: null,
    get_all_family_member: [],
     all_family_member: null,
    //  get_all_popular_family_member: [],
    //  all_popular_family_member: null,

    //  family_member_by_id: [],
    //  family_member_by_id_loading: false,
    //  family_member_by_id_error: null,
    //  family_member_enable_disable_status: null,
     update_family_member_details: null,
     delete_family_member: null
    },
    action
) =>{

    switch (action.type) {
    
        //family_member reducers type
        
    
        //add new family_member
        case family_memberActionType.ADD_FAMILY_MEMBER_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case family_memberActionType.ADD_FAMILY_MEMBER_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            add_family_member: action.result,
          });
        case family_memberActionType.ADD_FAMILY_MEMBER_ERROR:
          return Object.assign({}, state, {
            getallfamily_member_status: action.status,
            getallfamily_member_error: action.error,
          });
         
          // all family_members get
          case family_memberActionType.GET_ALL_FAMILY_MEMBER_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case family_memberActionType.GET_ALL_FAMILY_MEMBER_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_family_member: action.result,
            });
          case family_memberActionType.GET_ALL_FAMILY_MEMBER_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });


            // all popular family_members get
        //   case family_memberActionType.GET_ALL_POPULAR_FAMILY_MEMBER_REQ:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //     });
        //   case family_memberActionType.GET_ALL_POPULAR_FAMILY_MEMBER_SUCC:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       all_popular_family_member: action.result,
        //     });
        //   case family_memberActionType.GET_ALL_POPULAR_FAMILY_MEMBER_ERR:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       error: action.error,
        //     });
    
          // get family_members by id 
        //   case family_memberActionType.GET_FAMILY_MEMBER_BY_ID_REQ:
        //   return Object.assign({}, state, {
        //     family_member_by_id: [],
        //     family_member_by_id_loading: true,
        //     family_member_by_id_error: null,
        //   });
        // case family_memberActionType.GET_FAMILY_MEMBER_BY_ID_SUCCESS:
        //   return Object.assign({}, state, {
        //     family_member_by_id: action.payload,
        //     family_member_by_id_loading: false,
        //     family_member_by_id_error: null,
        //   });
        // case family_memberActionType.GET_FAMILY_MEMBER_BY_ID_ERR:
        //   return Object.assign({}, state, {
        //     family_member_by_id: [],
        //     family_member_by_id_loading: false,
        //     family_member_by_id_error: action.payload,
        //   });
          // update enable disable family_member status 
        //   case family_memberActionType.PUT_FAMILY_MEMBER_STATUS_BY_ID_REQ:
        //     return Object.assign({}, state, {
        //       status: action.status,
              
        //     });
        //   case family_memberActionType.PUT_FAMILY_MEMBER_STATUS_BY_ID_SUCC:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       family_member_enable_disable_status:action.payload,
        //     });
        //   case family_memberActionType.PUT_FAMILY_MEMBER_STATUS_BY_ID_ERR:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       error: action.error,
        //     });
    
              // UPDATE family_member DETAILS
        case family_memberActionType.UPDATE_FAMILY_MEMBER_DETAILS_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case family_memberActionType.UPDATE_FAMILY_MEMBER_DETAILS_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            update_family_member_details: action.result,
          });
        case family_memberActionType.UPDATE_FAMILY_MEMBER_DETAILS_ERROR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });

             // delete family_member
    case family_memberActionType.DELETE_FAMILY_MEMBER_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
      });
    case family_memberActionType.DELETE_FAMILY_MEMBER_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        delete_family_member: action.result,
      });
    case family_memberActionType.DELETE_FAMILY_MEMBER_ERROR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default familymember;
    
    
    