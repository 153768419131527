//create Order
export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";

//Get  all Orders
export const GET_ALL_ORDERS_REQ = "GET_ALL_ORDERS_REQ";
export const GET_ALL_ORDERS_ERR = "GET_ALL_ORDERS_ERR";
export const GET_ALL_ORDERS_SUCC = "GET_ALL_ORDERS_SUCC";

//Get order details by order id 
export const GET_ORDER_DETAILS_BY_ORDER_ID_REQ = "GET_ORDER_DETAILS_BY_ORDER_ID_REQ";
export const GET_ORDER_DETAILS_BY_ORDER_ID_ERR = "GET_ORDER_DETAILS_BY_ORDER_ID_ERR";
export const GET_ORDER_DETAILS_BY_ORDER_ID_SUCC = "GET_ORDER_DETAILS_BY_ORDER_ID_SUCC";


//Verify Order
export const VERIFY_ORDER_REQUEST = "VERIFY_ORDER_REQUEST";
export const VERIFY_ORDER_ERROR = "VERIFY_ORDER_ERROR";
export const VERIFY_ORDER_SUCCESS = "VERIFY_ORDER_SUCCESS";

//Get  all time slot
export const GET_ALL_TIME_SLOT_REQ = "GET_ALL_TIME_SLOT_REQ";
export const GET_ALL_TIME_SLOT_ERR = "GET_ALL_TIME_SLOT_ERR";
export const GET_ALL_TIME_SLOT_SUCC = "GET_ALL_TIME_SLOT_SUCC";
//GET ALL COUPONS
export const GET_ALL_COUPONS_REQ = "GET_ALL_COUPONS_REQ";
export const GET_ALL_COUPONS_ERR = "GET_ALL_COUPONS_ERR";
export const GET_ALL_COUPONS_SUCC = "GET_ALL_COUPONS_SUCC";

// POST_VERIFY_COUPON_BY_ID

export const POST_VERIFY_COUPON_BY_ID_REQ = "POST_VERIFY_COUPON_BY_ID_REQ";
export const POST_VERIFY_COUPON_BY_ID_ERR = "POST_VERIFY_COUPON_BY_ID_ERR";
export const POST_VERIFY_COUPON_BY_ID_SUCC = "POST_VERIFY_COUPON_BY_ID_SUCC";


//DELETE ORDER_BY_ORDER_ID
export const DELETE_ORDER_BY_ORDER_ID_REQUEST = "DELETE_ORDER_BY_ORDER_ID_REQUEST";
export const DELETE_ORDER_BY_ORDER_ID_ERROR = "DELETE_ORDER_BY_ORDER_ID_ERROR";
export const DELETE_ORDER_BY_ORDER_ID_SUCCESS = "DELETE_ORDER_BY_ORDER_ID_SUCCESS";


//Get  deliverycharges
export const GET_DELIVERY_CHARGES_REQ = "GET_DELIVERY_CHARGES_REQ";
export const GET_DELIVERY_CHARGES_ERR = "GET_DELIVERY_CHARGES_ERR";
export const GET_DELIVERY_CHARGES_SUCC = "GET_DELIVERY_CHARGES_SUCC";