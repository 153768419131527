import * as constance from "../constance/action.constance";
import {  
    // get all faq
     GET_ALL_FAQ_REQ,
     GET_ALL_FAQ_SUCC,
     GET_ALL_FAQ_ERR,
   } from "./action.type";

   // GET ALL  FAQ
export function get_all_faq_req() {
    return {
      type: GET_ALL_FAQ_REQ,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_faq_err(error) {
    return {
      type: GET_ALL_FAQ_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_faq_success(result) {
    return {
      type: GET_ALL_FAQ_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }
