import React from 'react'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io'

import * as RiIcons from 'react-icons/ri'
import * as HiIcons from 'react-icons/hi'
import * as FcIcons from 'react-icons/fc'

 export const SidebarData = [

    {
        title: 'MY ACCOUNT',
        path: '/myaccount',
        icon: <RiIcons.RiAccountPinBoxFill/>,
        cName: 'nav-text'
      },
      {
        title: 'ORDER',
        path: '/order',
        icon: <RiIcons.RiShoppingBag3Fill />,
        cName: 'nav-text'
      },
    
    {
      title: 'REPORT',
      path: '/report',
      icon: <HiIcons.HiDocumentReport />,
      cName: 'nav-text'
    },
    
      {
        title: 'SUPPORT',
        path: '/support',
        icon: <IoIcons.IoMdHelpCircle />,
        cName: 'nav-text'
      },

      {
        title: 'FEEDBACK',
        path: '/feedback',
        icon: <FcIcons.FcFeedback />,
        cName: 'nav-text'
      }
      
    ];
     