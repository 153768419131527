import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import moment from "moment";
import {connect} from "react-redux";
import DashboardSidebar from '../../sidebar/sidebar.jsx';
import photoImg from '../../../../../assets/images/person.png';
import StickyBox from "react-sticky-box";
import { create_support_conversation_by_ticket_id_request,get_support_conversation_by_ticket_id_req } from '../../../../../redux-services/actions/action.support/action.function';
import "./supportView.css";
function SupportView(props) {
    const [statusOptionValue, setStatusOptionValue] = useState("");
    const [ticketConversationData, setTicketConversationData] = useState([]);
    const [ticketConversationEmptyMessage, setTicketConversationEmptyMessage] = useState("");

    console.log("EmptyMessage from ticket list", props.history.location.state?.data.ticketdata);
    const ticketData =  props.history.location.state?.data.ticketdata;
   
    const [activeModal, setActiveModal] = useState("");

    const [changeTyping, setChangeTyping] = useState("");

    const [blurTyping, setBlurTyping] = useState("");
    const handleBack = () => {
        props.history.goBack();
      };

      const getSupportConversationByTicketIdApiCall = async() =>{

        var data = {
            "ticketid":`${ticketData.ticketid}`
        }
       await props.get_support_conversation_by_ticket_id(data,(error,success)=>{
        if(error){
          
          console.log("error",error);
        }    
        if(success.status == 200 ){
          if (success && success?.response.length > 0) {
            setTicketConversationData(success?.response);
            setStatusOptionValue( 
                 `${((success && success.response && success.response[0].ticket_status))}`
            )
          }
          if (success && success?.response.length <= 0) {
            console.log("data length empty",success?.message);
            setTicketConversationData([]);
            setTicketConversationEmptyMessage(success?.message)
          }
         }
         });
        
    }

    useEffect(() => {
         getSupportConversationByTicketIdApiCall();
     }, []);

     const onChangeTyping = (event) =>{
        event.preventDefault();
            setChangeTyping(event.target.value);
      }

      const onBlurTyping = (event) =>{
        event.preventDefault();
            setBlurTyping(event.target.value)
    }

    const handleSendButtonClick = async() =>{
        var data = {
            "ticketid":`${ticketData.ticketid}`,
            "message":`${blurTyping}`
        }

        await props.create_support_conversation_by_ticket_id(data,(error,success)=>{
            if(error){
              
              console.log("error",error);
            }    
            if(success.status == 200 ){
                setChangeTyping("");
                setBlurTyping("");
                
                getSupportConversationByTicketIdApiCall();
                
             }
             });

    }
   
    return (
        <div>
        <div className="breadcrumb-bar">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/home">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Support View</li>
                        </ol>
                    </nav>
                    <h2 className="breadcrumb-title">Support View</h2>
                </div>
            </div>
        </div>
    </div>
    <div className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-5 col--lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                    <DashboardSidebar />
                </StickyBox>
               </div>

               <div className="col-md-7 col-lg-8 col-xl-9">
<div className="card">
<div className="card-body">
    
<Row className="mb-2">
          <Col xs="6" sm="12" lg="4" >
            <div className="bg-white shadow rounded pb-3">

            
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Title </strong>
              </div>
            </div>
            <div className="titleName p-2">
            {((ticketData != null) || (ticketData != undefined))?`${ticketData.issue_description}`:'--'}
            </div>

            </div>
          </Col>

          <Col xs="6" sm="12" lg="4">
          <div className="bg-white shadow rounded pb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Ticket ID </strong>
              </div>
            </div>
            <div className="titleName p-2">
            {((ticketData != null) || (ticketData != undefined))?`${ticketData.ticketid}`:'--'}
            </div>
            </div>

          </Col>

          <Col xs="6" sm="12" lg="4">
          <div className="bg-white shadow rounded pb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Order ID </strong>
              </div>
            </div>
            <div className="titleName p-2">
            {((ticketData != null) || (ticketData != undefined))?`${ticketData.orderid}`:'--'}
            </div>
            </div>

          </Col>
          </Row>
  
        <div className="">

            
          {(statusOptionValue == "resolved")?(
            <div className="row rounded">
            <div className="col-xl-12">
                                    <div className="chat-window">
                                    <i>This Ticket ID is resolved, no converstion to display, if any issues faced kindly re create support with same order id again</i>
                                    </div>
                            </div>
                            </div>        
          ):(
          <div className="row rounded">
                                <div className="col-xl-12">
                                    <div className="chat-window">
                                    
                                        
                                        {/* <div className="chat-cont-left">
                                            <div className="chat-header">
                                                <span>Chats</span>
                                                <a href="#0" className="chat-compose">
                                                    <i className="material-icons">control_point</i>
                                                </a>
                                            </div>
                                            <form className="chat-search">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <i className="fas fa-search"></i>
                                                    </div>
                                                    <input type="text" className="form-control" placeholder="Search"/>
                                                </div>
                                            </form>
                                            <div className="chat-users-list">
                                                <div className="chat-scroll">
                                                    <a href="#0" className="media">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-away">
                                            <img src={IMG01} alt="User" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="user-name">Richard Wilson</div>
                                                                <div className="user-last-chat">Hey, How are you?</div>
                                                            </div>
                                                            <div>
                                                                <div className="last-chat-time block">2 min</div>
                                                                <div className="badge badge-success badge-pill">15</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#0" className="media read-chat active">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-online">
                                        <img src={IMG02} alt="User" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="user-name">Charlene Reed</div>
                                                                <div className="user-last-chat">I'll call you later </div>
                                                            </div>
                                                            <div>
                                                                <div className="last-chat-time block">8:01 PM</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#0" className="media">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-away">
                                        <img src={IMG03} alt="User" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="user-name">Travis Trimble </div>
                                                                <div className="user-last-chat">Give me a full explanation about our project</div>
                                                            </div>
                                                            <div>
                                                                <div className="last-chat-time block">7:30 PM</div>
                                                                <div className="badge badge-success badge-pill">3</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#0" className="media read-chat">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-online">
                                    <img src={IMG04} alt="User" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="user-name">Carl Kelly</div>
                                                                <div className="user-last-chat">That's very good UI design</div>
                                                            </div>
                                                            <div>
                                                                <div className="last-chat-time block">6:59 PM</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#0" className="media read-chat">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-offline">
                                            <img src={IMG04} alt="User" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="user-name">Michelle Fairfax</div>
                                                                <div className="user-last-chat">Yesterday i completed the task</div>
                                                            </div>
                                                            <div>
                                                                <div className="last-chat-time block">11:21 AM</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#0" className="media read-chat">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-online">
                                        <img src={IMG05} alt="User" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="user-name">Gina Moore</div>
                                                                <div className="user-last-chat">What is the major functionality?</div>
                                                            </div>
                                                            <div>
                                                                <div className="last-chat-time block">10:05 AM</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#0" className="media read-chat">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-away">
                                        <img src={IMG06} alt="User" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="user-name">Elsie Gilley</div>
                                                                <div className="user-last-chat">This has allowed me to showcase not only my technical skills</div>
                                                            </div>
                                                            <div>
                                                                <div className="last-chat-time block">Yesterday</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#0" className="media read-chat">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-offline">
                                            <img src={IMG07} alt="User" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="user-name">Joan Gardner</div>
                                                                <div className="user-last-chat">Let's talk briefly in the evening. </div>
                                                            </div>
                                                            <div>
                                                                <div className="last-chat-time block">Sunday</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#0" className="media read-chat">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-online">
                                            <img src={IMG08} alt="User" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="user-name">Daniel Griffing</div>
                                                                <div className="user-last-chat">Do you have any collections? If so, what of?</div>
                                                            </div>
                                                            <div>
                                                                <div className="last-chat-time block">Saturday</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#0" className="media read-chat">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-away">
                                    <img src={IMG08} alt="User" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div>
                                                                <div className="user-name">Walter Roberson</div>
                                                                <div className="user-last-chat">Connect the two modules with in 1 day.</div>
                                                            </div>
                                                            <div>
                                                                <div className="last-chat-time block">Friday</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                        <div className="chat-cont-right shadow rounded">
                                            {/* <div className="chat-header">
                                                <a id="back_user_list" href="#0" className="back-user-list">
                                                    <i className="material-icons">chevron_left</i>
                                                </a>
                                                <div className="media">
                                                    <div className="media-img-wrap">
                                                        <div className="avatar avatar-online">
                                         <img src={photoImg} alt="User" className="avatar-img rounded-circle" />
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="user-name">{((ticketData != null) || (ticketData != undefined))?`${ticketData.user_firstname} ${ticketData.user_lastname}`:'--'}</div>
                                                        <div className="user-status">{((ticketData != null) || (ticketData != undefined))?`${ticketData.orderid}`:'--'}</div>
                                                    </div>
                                                </div>
                                                <div className="chat-options">
                                                    <a href="#0" data-toggle="modal" data-target="#voice_call" onClick={()=> openModal('voice')}>
                                                        <i className="material-icons">local_phone</i> 
                                                    </a>
                                                    <a href="#0" data-toggle="modal" data-target="#video_call" onClick={()=> openModal('video')}>
                                                        <i className="material-icons">videocam</i>
                                                    </a>
                                                    <a href="#0">
                                                        <i className="material-icons">more_vert</i>
                                                    </a>
                                                </div>
                                            </div> */}
                                            <div className="chat-body">
                                                <div className="chat-scroll">

                                                {
                                                        (ticketConversationData.length > 0)?(ticketConversationData[0].conversation.map((conData, idx) =>(
                                                            
                                                            <ul className="list-unstyled" key={idx}>
                                                   
                                                   <li 
                                                //    className="media received"
                                                   className={(conData.messageby == "user")?"media received":"media sent"}
                                                   >
                                                       <div className="media-body">
                                                           <div className="msg-box">
                                                               <div>
                                                                   <p>{conData.message}</p>
                                                                   <ul className="chat-msg-info">
                                                                       <li>
                                                                           <div className="chat-time">
                                                                               <span>{moment(conData.created_at).format('hh:mm A')}</span>
                                                                           </div>
                                                                       </li>
                                                                   </ul>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   </li>
                                                   {/* <li className="media sent">
                                                       <div className="avatar">
                              
                                                       </div>
                                                       <div className="media-body">
                                                           <div className="msg-box">
                                                               <div>
                                                                   <p>We will look into the issue raised from your side.</p>
                                                                   <p>Kindly wait for some time while our team will shortly get back to you</p>
                                                                   <ul className="chat-msg-info">
                                                                       <li>
                                                                           <div className="chat-time">
                                                                               <span>8:35 AM</span>
                                                                           </div>
                                                                       </li>
                                                                   </ul>
                                                               </div>
                                                           </div>
                                                           
                                                       </div>
                                                   </li> */}
                                                   
                                               </ul>
                                                        ))
                                                            
                                                        ):(
                                                            <div>
                                                                {ticketConversationEmptyMessage}
                                                            </div>
                                                        )
                                                    }
                                                    
                                                   
                                                </div>
                                            </div>
                                            <div className="chat-footer">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        {/* <div className="btn-file btn">
                                                            <i className="fa fa-paperclip"></i>
                                                            <input type="file" />
                                                        </div> */}
                                                    </div>
                                                    <input 
                                                    type="text" 
                                                    className="input-msg-send form-control" 
                                                    placeholder="Type something"
                                                    value={changeTyping}
                                                    onChange={(event) => onChangeTyping(event)}
                                                    onBlur={(event) =>onBlurTyping(event)}

                                                    />
                                                    <div className="input-group-append">
                                                        <button 
                                                        type="button" 
                                                        className="btn msg-send-btn"
                                                        onClick={() => handleSendButtonClick()}
                                                        >
                                                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        
                                    </div>
                                </div>
                            </div>
                            )}                    
                        

         




            <div className="submit-section mt-2">
                    <button type="button" className="btn btn-primary submit-btn"  onClick={handleBack}>Back</button>
                </div>
        </div>
      
 
   
    
</div>
</div>
</div>
            </div>
        </div>
    </div>      
    </div>
);
        }


        const mapDispatchToProps = (dispatch) => {
            return {
           
            
                get_support_conversation_by_ticket_id : (data,callback) => dispatch(get_support_conversation_by_ticket_id_req(data,callback)),
                create_support_conversation_by_ticket_id : (data,callback) => dispatch(create_support_conversation_by_ticket_id_request(data,callback)),
            
            };
          };
          const mapStateToProps = (state) => {
            return {
              
              user: state.user.user,
              
            };
          };
          
          export default connect(mapStateToProps, mapDispatchToProps)(SupportView);
