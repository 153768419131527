//create SUPPORT
export const CREATE_SUPPORT_REQUEST = "CREATE_SUPPORT_REQUEST";
export const CREATE_SUPPORT_ERROR = "CREATE_SUPPORT_ERROR";
export const CREATE_SUPPORT_SUCCESS = "CREATE_SUPPORT_SUCCESS";

//Get  all SUPPORTs
export const GET_ALL_SUPPORT_REQ = "GET_ALL_SUPPORT_REQ";
export const GET_ALL_SUPPORT_ERR = "GET_ALL_SUPPORT_ERR";
export const GET_ALL_SUPPORT_SUCC = "GET_ALL_SUPPORTS_SUCC";

// get support conversation by ticket id 
export const GET_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ = "GET_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ";
export const GET_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR = "GET_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR";
export const GET_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS = "GET_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS";


// CREATE support conversation by ticket id 
export const CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ = "CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ";
export const CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR = "CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR";
export const CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS = "CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS";
