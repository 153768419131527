//GET ALL  TEST
export const GET_ALL_TEST_REQ = "GET_ALL_TEST_REQ";
export const GET_ALL_TEST_ERR = "GET_ALL_TEST_ERR";
export const GET_ALL_TEST_SUCC = "GET_ALL_TEST_SUCC";

//GET ALL POPULAR TEST
export const GET_ALL_POPULAR_TEST_REQ = "GET_ALL_POPULAR_TEST_REQ";
export const GET_ALL_POPULAR_TEST_ERR = "GET_ALL_POPULAR_TEST_ERR";
export const GET_ALL_POPULAR_TEST_SUCC = "GET_ALL_POPULAR_TEST_SUCC";

//GET ALL CATEGORY
export const GET_ALL_CATEGORY_REQ = "GET_ALL_CATEGORY_REQ";
export const GET_ALL_CATEGORY_ERR = "GET_ALL_CATEGORY_ERR";
export const GET_ALL_CATEGORY_SUCC = "GET_ALL_CATEGORY_SUCC";