import React, {useEffect,useState } from "react";
// import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//images
import { IMG01, IMG02,IMG03, IMG04, IMG05, IMG06,IMG_th01, IMG_th02, IMG_th03, IMG_th04, IMG_th05, IMG_th06,IMG_th07} from './img.jsx';

//components
// import BlogListSearch from '../search/search.jsx';
// import LastestBlog from '../lastestblog';
// import CategoryWidget from '../categoryWidget';
// import TagsWidget from '../tagswidget';

function Infrastructure(props){
    const nextPathDataPush = (path,data) =>{
        props.history.push({
            pathname: path,
            state: {
            data: data,
        }
      })
      }


 const onClickData = (imgPath,textData) =>{
    var data = {
        "textdata":textData,
         "imagedata":imgPath
       
      }
   
        nextPathDataPush(`/home/infrastructure/infrastructuredetails`,data);
        console.log("data",data);
 }

        return(
            <div>
            <div className="breadcrumb-bar">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-12 col-12">
                        <nav aria-label="breadcrumb" className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                {/* <li className="breadcrumb-item active" aria-current="page">Blog</li> */}
                            </ol>
                        </nav>
                        <h2 className="breadcrumb-title">Infrastructure</h2>
                    </div>
                </div>
            </div>
        </div>

        <div className="content">
				<div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                            <div className="row blog-grid-row">
								<div className="col-md-4 col-sm-12">
                                <div className="blog grid-blog">
                                        <div className="blog-image">
                                            <span onClick={() =>onClickData('infrastrucure_1.jpg','Biochemistry')}><img className="img-fluid" src={require('../../../assets/images/doctors/infrastrucure_1.jpg')}  alt="Post" /></span>
                                        </div>
                                        <div className="blog-content">
                                            {/* <ul className="entry-meta meta-item">
                                                <li>
                                                    <div className="post-author">
                                                        <Link to="/doctor-profile"><img src={IMG_th01} alt="Post Author" /> 
                                                        <span>Dr. Ruby Perrin</span></Link>
                                                    </div>
                                                </li>
                                                <li><i className="far fa-clock"></i> 4 Dec 2019</li>
                                            </ul> */}
                                        <span onClick={() =>onClickData('infrastrucure_1.jpg','Biochemistry')}  style={{
                      cursor: "pointer",
                    }}>
                                             <h3 className="text-muted"  onClick={() =>onClickData('infrastrucure_1.jpg','Biochemistry')} style={{
                      cursor: "pointer",
                    }}>Biochemistry</h3>


                                      
                                             {/* <h3 onClick={() => onClickImg(<h3>Biochmistry</h3>)}></h3> */}

                                   
                                                </span>
                                               
                                            {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                <div className="blog grid-blog">
                                        <div className="blog-image">
                                        <span onClick={() =>onClickData('infrastrucure_2.jpg','Haematology')}><img className="img-fluid" src={require('../../../assets/images/doctors/infrastrucure_2.jpg')} alt="Post" /></span>
                                        </div>
                                        <div className="blog-content">
                                            {/* <ul className="entry-meta meta-item">
                                                <li>
                                                    <div className="post-author">
                                                        <Link to="/doctor-profile"><img  src={IMG_th02} alt="Post Author" /> 
                                                        <span>Dr. Darren Elder</span></Link>
                                                    </div>
                                                </li>
                                                <li><i className="far fa-clock"></i> 3 Dec 2019</li>
                                            </ul> */}
                                            <span onClick={() =>onClickData('infrastrucure_2.jpg','Haematology')}  style={{cursor: "pointer",}}>
                      
                    
                                            <h3 className="text-muted" onClick={() =>onClickData('infrastrucure_2.jpg','Haematology')}  style={{cursor: "pointer",}}>
                                                Haematology </h3>
                                                </span>
                                            {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                <div className="blog grid-blog">
                                    <div className="blog-image">
                                    <span onClick={() =>onClickData('infrastrucure_3.jpg','Microbiology and Serology')}  style={{cursor: "pointer",}}><img className="img-fluid" src={require('../../../assets/images/doctors/infrastrucure_3.jpg')}  alt="Post" /></span>
                                    </div>
                                    <div className="blog-content">
                                        {/* <ul className="entry-meta meta-item">
                                            <li>
                                                <div className="post-author">
                                                    <Link to="/doctor-profile"><img  src={IMG_th03} alt="Post Author" />
                                                     <span>Dr. Deborah Angel</span></Link>
                                                </div>
                                            </li>
                                            <li><i className="far fa-clock"></i> 3 Dec 2019</li>
                                        </ul> */}
                                        <span onClick={() =>onClickData('infrastrucure_3.jpg','Microbiology and Serology')}  style={{cursor: "pointer",}}>
                                        {/* <span onClick={() =>onClickImg('infrastrucure_2.jpg')}  style={{
                      cursor: "pointer",
                    }}> */}
                                        <h3 className="text-muted" onClick={() =>onClickData('infrastrucure_3.jpg','Microbiology and Serology')}  style={{cursor: "pointer",}}>Microbiology and Serology</h3>
                                        {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p> */}
                                        </span>
                                    </div>
                                </div>
                                 </div>
                                 <div className="col-md-4 col-sm-12">
                                 <div className="blog grid-blog">
                                        <div className="blog-image">
                                        <span onClick={() =>onClickData('infrastrucure_4.jpg','Clinical Pathology')}  style={{cursor: "pointer",}}><img className="img-fluid" src={require('../../../assets/images/doctors/infrastrucure_4.jpg')}  alt="Post" /></span>                                        </div>
                                        <div className="blog-content">
                                            {/* <ul className="entry-meta meta-item">
                                                <li>
                                                    <div className="post-author">
                                                        <Link to="/doctor-profile"><img  src={IMG_th04} alt="Post Author" />
                                                         <span>Dr. Sofia Brient</span></Link>
                                                    </div>
                                                </li>
                                                <li><i className="far fa-clock"></i> 2 Dec 2019</li>
                                            </ul> */}
                                          <span onClick={() =>onClickData('infrastrucure_4.jpg','Clinical Pathology')}  style={{cursor: "pointer",}}>

                                            <h3 className="text-muted" onClick={() =>onClickData('infrastrucure_4.jpg','Clinical Pathology')}  style={{cursor: "pointer",}}>Clinical Pathology</h3>
                                            {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p> */}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                            <div className="blog-image">
                                            <span onClick={() =>onClickData('infrastrucure_5.jpg','Cytology')}  style={{cursor: "pointer",}}><img className="img-fluid" src={require('../../../assets/images/doctors/infrastrucure_5.jpg')}  alt="Post" /></span>                                            </div>
                                            <div className="blog-content">
                                                {/* <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                        <Link to="/doctor-profile"><img  src={IMG_th05} alt="Post Author" />
                                                         <span>Dr. Marvin Campbell</span></Link>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i> 1 Dec 2019</li>
                                                </ul> */}
                                                <span onClick={() =>onClickData('infrastrucure_5.jpg','Cytology')}  style={{cursor: "pointer",}} >

                                                <h3 className="text-muted" onClick={() =>onClickData('infrastrucure_5.jpg','Cytology')}  style={{cursor: "pointer",}}> Cytology</h3>
                                                </span>
                                                {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                            <div className="blog-image">
                                            <span onClick={() =>onClickData('infrastrucure_6.jpg','Histopathology')}  style={{cursor: "pointer",}}><img className="img-fluid" src={require('../../../assets/images/doctors/infrastrucure_6.jpg')}  alt="Post" /></span>                                            </div>
                                            <div className="blog-content">
                                                {/* <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                        <Link to="/doctor-profile"><img  src={IMG_th06} alt="Post Author" />
                                                         <span>Dr. Marvin Campbell</span></Link>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i>30 Nov 2019</li>
                                                </ul> */}
                                                  <span onClick={() =>onClickData('infrastrucure_6.jpg','Histopathology')}  style={{cursor: "pointer",}}>

                                                 
                                                <h3 className="text-muted" onClick={() =>onClickData('infrastrucure_6.jpg','Histopathology')}  style={{cursor: "pointer",}}>Histopathology</h3>
                                                </span>
                                                {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                <div className="blog grid-blog">
                                        <div className="blog-image">
                                        <span onClick={() =>onClickData('infrastrucure_7.jpg','Referral Lab')}  style={{cursor: "pointer",}}><img className="img-fluid" src={require('../../../assets/images/doctors/infrastrucure_7.jpg')}  alt="Post" /></span>                                        </div>
                                        <div className="blog-content">
                                            {/* <ul className="entry-meta meta-item">
                                                <li>
                                                    <div className="post-author">
                                                        <Link to="/doctor-profile"><img src={IMG_th01} alt="Post Author" /> 
                                                        <span>Dr. Ruby Perrin</span></Link>
                                                    </div>
                                                </li>
                                                <li><i className="far fa-clock"></i> 4 Dec 2019</li>
                                            </ul> */}
                                            <span onClick={() =>onClickData('infrastrucure_7.jpg','Referral Lab')}  style={{cursor: "pointer",}}>

                                            <h3 className="text-muted" onClick={() =>onClickData('infrastrucure_7.jpg','Referral Lab')}  style={{cursor: "pointer",}}>
                                           Referral Lab</h3>
                                           </span>
                                           
                                            {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p> */}
                                        </div>
                                    </div>
                                </div><div className="col-md-4 col-sm-12">
                                <div className="blog grid-blog">
                                        <div className="blog-image">
                                        <span onClick={() =>onClickData('infrastrucure_8.jpg','Advance Imaging')}  style={{cursor: "pointer",}}><img className="img-fluid" src={require('../../../assets/images/doctors/infrastrucure_8.jpg')}  alt="Post" /></span>                                        </div>
                                        <div className="blog-content">
                                            {/* <ul className="entry-meta meta-item">
                                                <li>
                                                    <div className="post-author">
                                                        <Link to="/doctor-profile"><img src={IMG_th01} alt="Post Author" /> 
                                                        <span>Dr. Ruby Perrin</span></Link>
                                                    </div>
                                                </li>
                                                <li><i className="far fa-clock"></i> 4 Dec 2019</li>
                                            </ul> */}
                                             <span onClick={() =>onClickData('infrastrucure_8.jpg','Advance Imaging')}  style={{cursor: "pointer",}}>

                                            <h3 className="text-muted" onClick={() =>onClickData('infrastrucure_8.jpg','Advance Imaging')}  style={{cursor: "pointer",}}>
                                            Advance Imaging</h3>
                                            </span>
                                            {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p> */}
                                        </div>
                                    </div>
                                </div><div className="col-md-4 col-sm-12">
                                <div className="blog grid-blog">
                                        <div className="blog-image">
                                        <span onClick={() =>onClickData('infrastrucure_9.jpg','Imaging Services')}  style={{cursor: "pointer",}}><img className="img-fluid" src={require('../../../assets/images/doctors/infrastrucure_9.jpg')}  alt="Post" /></span>                                        </div>
                                        <div className="blog-content">
                                            {/* <ul className="entry-meta meta-item">
                                                <li>
                                                    <div className="post-author">
                                                        <Link to="/doctor-profile"><img src={IMG_th01} alt="Post Author" /> 
                                                        <span>Dr. Ruby Perrin</span></Link>
                                                    </div>
                                                </li>
                                                <li><i className="far fa-clock"></i> 4 Dec 2019</li>
                                            </ul> */}
                                             <span onClick={() =>onClickData('infrastrucure_9.jpg','Imaging Services')}  style={{cursor: "pointer",}}>

                                            <h3 className="text-muted" onClick={() =>onClickData('infrastrucure_9.jpg','Imaging Services')}  style={{cursor: "pointer",}}>
                                            Imaging Services</h3>
                                            </span>
                                            {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p> */}
                                        </div>
                                    </div>
                                </div><div className="col-md-4 col-sm-12">
                                <div className="blog grid-blog">
                                        <div className="blog-image">
                                        <span onClick={() =>onClickData('infrastrucure_10.jpg','Physiotherapy')}  style={{cursor: "pointer",}}><img className="img-fluid" src={require('../../../assets/images/doctors/infrastrucure_10.jpg')}  alt="Post" /></span>                                        </div>
                                        <div className="blog-content">
                                            {/* <ul className="entry-meta meta-item">
                                                <li>
                                                    <div className="post-author">
                                                        <Link to="/doctor-profile"><img src={IMG_th01} alt="Post Author" /> 
                                                        <span>Dr. Ruby Perrin</span></Link>
                                                    </div>
                                                </li>
                                                <li><i className="far fa-clock"></i> 4 Dec 2019</li>
                                            </ul> */}
                                        <span onClick={() =>onClickData('infrastrucure_10.jpg','Physiotherapy')}  style={{cursor: "pointer",}}>

                                            <h3 className="text-muted" onClick={() =>onClickData('infrastrucure_10.jpg','Physiotherapy')}  style={{cursor: "pointer",}}>
                                           Physiotherapy</h3>
                                           </span>
                                            {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p> */}
                                        </div>
                                    </div>
                                </div>
                                </div>
                          </div> 
                        {/* <div className="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
                            <BlogListSearch />
                            <LastestBlog />
                            <CategoryWidget />
                            <TagsWidget />
                        </div> */}
                    </div>
              </div>
         </div>
		</div>
        );
 
                    }
export default Infrastructure;
// Histopathology
// Referral Lab
// Advance Imaging
// Imaging Services
// Physiotherapy
