import firebase from "firebase/app";
import "firebase/messaging";
import axios from 'axios'
import {REACT_APP_VAPID_KEY} from "./client/redux-services/api/api.js";
import {API} from './client/utils/networkApi';
import { API_URL} from '../src/client/redux-services/api/api.js';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "FROM FIREBASE CONSOLE",
  apiKey: "AIzaSyCEJ8zl15N46oc7hfSr9JXd9Xln3LucfG4",
  // authDomain: "FROM FIREBASE CONSOLE",
  authDomain: "rnfirebasestorage-e67cf.firebaseapp.com",
  // databaseURL: "FROM FIREBASE CONSOLE",
  databaseURL: "https://rnfirebasestorage-e67cf-default-rtdb.firebaseio.com",
  // projectId: "FROM FIREBASE CONSOLE",
  projectId: "rnfirebasestorage-e67cf",
  // storageBucket: "FROM FIREBASE CONSOLE",
  storageBucket: "rnfirebasestorage-e67cf.appspot.com",
  // messagingSenderId: "FROM FIREBASE CONSOLE",
  messagingSenderId: "869106326154",
  // appId: "FROM FIREBASE CONSOLE",
  appId: "1:869106326154:web:3e54dba1e92243bcf02b73",
  // measurementId: "FROM FIREBASE CONSOLE",
  measurementId: "G-P0TQZJVXZC"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();
// import {REACT_APP_VAPID_KEY} from "../redux-services/api/api.js";

const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
      let data ={
        "token":`${currentToken}`
      }
      axios.post(API_URL.POST_FCM_TOKEN,data)
      .then(res => {
        console.log("res of fcm token axois",res);
        console.log("res of fcm token data axois",res.data);
        if(res.data?.status == 200){
          console.log("res of fcm token data success message :",res.data?.message);
          sessionStorage.setItem("fcm_token",currentToken);
        }
        if(res.data?.status != 200){
          console.log("res of fcm token data error message :",res.data?.message);
          sessionStorage.setItem("fcm_token","");
        }
        
      })
      .catch((error) => {
        console.log("error fcm token axios ======", error);
          console.log("error fcm token axios data ======", error.data);
      })

    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
