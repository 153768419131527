import * as constance from "../constance/action.constance";
import {  
   // get all test
    GET_ALL_TEST_REQ,
    GET_ALL_TEST_SUCC,
    GET_ALL_TEST_ERR,

    // get all popular test
    GET_ALL_POPULAR_TEST_REQ,
    GET_ALL_POPULAR_TEST_SUCC,
    GET_ALL_POPULAR_TEST_ERR,

     // get all CATEGORY test
     GET_ALL_CATEGORY_REQ,
     GET_ALL_CATEGORY_SUCC,
     GET_ALL_CATEGORY_ERR,
    
  } from "./action.type";

  // GET ALL  TEST
export function get_all_test_req(data,callback) {
    return {
      type: GET_ALL_TEST_REQ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_test_err(error) {
    return {
      type: GET_ALL_TEST_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_test_success(result) {
    return {
      type: GET_ALL_TEST_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }


   // GET ALL POPULAR TEST
export function get_all_popular_test_req() {
  return {
    type: GET_ALL_POPULAR_TEST_REQ,
    status: constance.REQUESTING,
  };
}

export function get_all_popular_test_err(error) {
  return {
    type: GET_ALL_POPULAR_TEST_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_all_popular_test_success(result) {
  return {
    type: GET_ALL_POPULAR_TEST_SUCC,
    result,
    status: constance.SUCCESS,
  };
}
  

// GET ALL TEST WITH CALL BACK
export function get_all_test_call_back_req(data,callback) {
  return {
    type: GET_ALL_TEST_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_all_test_call_back_err(error) {
  return {
    type: GET_ALL_TEST_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_all_test_call_back_success(result) {
  return {
    type: GET_ALL_TEST_SUCC,
    result,
    status: constance.SUCCESS,
  };
}


// GET ALL CATEGORY WITH CALL BACK
export function get_all_category_call_back_req(data,callback) {
  return {
    type: GET_ALL_CATEGORY_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_all_category_call_back_err(error) {
  return {
    type: GET_ALL_CATEGORY_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_all_category_call_back_success(result) {
  return {
    type: GET_ALL_CATEGORY_SUCC,
    result,
    status: constance.SUCCESS,
  };
}