import * as userActionType from "../../actions/action.users/action.type";
import { normalizeUser } from "../../../utils/selectors";
// default user status 
const defaultUser = {
  mobile: "",
  id: null
};

const defaultState = {
  token: '',
  status: null,
  error: null,
  requesting: null,
  all_users: [],
  isLoggedIn: false,
  checkedForToken: false,
  // user view
  user_by_id: [],
  user_by_id_loading: false,
  user_by_id_error: null,

  update_user_details: null,

  


  user: { ...defaultUser }
}
const users = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    
   // login status of the user  
    case userActionType.LOGIN_REQUEST:
      return Object.assign({}, state, {
        status: null,
        error: null,
        requesting: true,
        isLoggedIn: false
      });
    case userActionType.LOGIN_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
        requesting: false,
        isLoggedIn: false,
        user: { ...defaultUser },
        token:''
      });
    case userActionType.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        user: action.result,
        isLoggedIn: true,
        requesting: false,
        error: null
      });


      // view profile status of the user  
    case userActionType.GET_USER_BY_ID_REQUEST:
      return Object.assign({}, state, {
        user_by_id: [],
            user_by_id_loading: true,
            user_by_id_error: null,
      });
    case userActionType.GET_USER_BY_ID_FAILURE:
      return Object.assign({}, state, {
        user_by_id: [],
            user_by_id_loading: false,
            user_by_id_error: action.payload,
      });
    case userActionType.GET_USER_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        user_by_id: action.payload?.response[0],
        user_by_id_loading: false,
        user_by_id_error: null,
      });


      //logout status of the user
      case userActionType.LOGOUT_REQUEST:
        return Object.assign({}, state, {
          status: null,
          error: null,
          requesting: true,
          isLoggedIn: true
        });
      case userActionType.LOGOUT_FAILURE:
        return Object.assign({}, state, {
          status: action.status,
          error: "error occured",
          requesting: false,
          isLoggedIn: true
        });
      case userActionType.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
         status: action.status,
          user: { ...defaultUser },
          token:'',
          user_by_id:'',
          isLoggedIn: false,
          requesting: false,
          error: null
        });
  

      // addition of admin token to the redux state for accessing
    case userActionType.ADD_TOKEN_TO_STATE:
      return {
        ...state,
        token: action.payload
      }
      // verifying the admin by token
    case userActionType.QUERYING_USER_BY_TOKEN:
      return {
        ...state,
        pending: true,
        checkedForToken: true
      }
    case userActionType.QUERYING_USER_BY_TOKEN_SUCCESS:
      return {
        ...state,
        pending: false,
        isLoggedIn: true,
        user: normalizeUser(action.payload)
      }
    case userActionType.QUERYING_USER_BY_TOKEN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      }


      // UPDATE user DETAILS
      case userActionType.UPDATE_USER_DETAILS_REQUEST:
        return Object.assign({}, state, {
          status: action.status,
        });
      case userActionType.UPDATE_USER_DETAILS_SUCCESS:
        return Object.assign({}, state, {
          status: action.status,
          update_user_details: action.result,
        });
      case userActionType.UPDATE_USER_DETAILS_ERROR:
        return Object.assign({}, state, {
          status: action.status,
          error: action.error,
        });

      // updating the user credential in the store
    case userActionType.UPDATE_USER_IN_STORE:
      return {
        ...state,
        user: normalizeUser({ ...state.user, ...action.payload })
      }

    //case userActionType:
    default:
      return state;

  }
}
export default users;
