import React,{ useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import {useHistory} from "react-router";
import moment from "moment";
import './colorStyle.css';
function UserBookingSuccess (props){
    const nextPath = (path) => {
        props.history.push(path);
      };
    const history = useHistory();
    const dataValue = history.location.state;
    console.log("data passed form checkout to success",dataValue);
    const [order_id, setOrder_id] = useState("");
    
    const dateTimeValue = moment(dataValue.timestamp,"X").format("DD MMM YYYY hh:mm a");

    console.log("date TIme Value",dateTimeValue);
    const [dateTime, setDateTime] = useState(dateTimeValue);
    useEffect(() => {
        if(dataValue.order_id){
            setOrder_id(dataValue.order_id)
        }
    },[]);

    const onClickBack = () =>{
        nextPath(`/`); 
    }

        return(
              
<div className="content success-page-cont">
    <div className="container-fluid">
    
        <div className="row justify-content-center">
            <div className="col-lg-6">
            
              
                <div className="card success-card">
                    <div className="card-body center">
                        {/* <div className="success-cont"> */}
                            <i className="fas fa-check-circle fa-5x text-success" aria-hidden="true" ></i>
                            <h3>Ordered Successfully!</h3>
                            <p>Ordered with <strong>Order ID : {order_id}</strong><br /> on <strong>{dateTime}</strong></p>
                            {/* <Link to="/pages/invoice-view" className="btn btn-primary view-inv-btn">View Invoice</Link> */}
                            {/* <Link to="#" className="btn btn-primary view-inv-btn">View Invoice</Link> */}
                        {/* </div> */}
                    </div>
                    <div className="center my-1">
                    <button type="button" className="btn btn-primary submit-btn"   onClick={() =>onClickBack()}>
             Go to dashboard
            </button>
            </div>
                </div>
            
                
            </div>

        </div>
        
        
    </div>
</div>
        )
   
}

export default UserBookingSuccess;