import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/footer-logo.png';
const Footer = (props) => {
 	const exclusionArray = [
		// '/pages/doctor-grid',
		// '/pages/doctor-list',
		// '/pages/video-call',
		// '/pages/voice-call',
		// '/pages/chat-doctor',
		// '/patient/doctor-list',
		// '/patient/doctor-grid'
	]
	if (exclusionArray.indexOf(props.location.pathname) >= 0) {
		return '';
	}
	return (
			
			
		<footer className="footer">
		
				<div className="footer-top">
					<div className="container">
						<div className="row justify-content-center">
							{/* <div className="col-12 text-center">
								<Link to="/" className="footer-logo logo">
									<img src={logo} className="img-fluid" alt="Logo" />
								</Link>
							</div> */}
							{/* <div className="social-icon media-btn mt-3 mb-2">
								<ul>
									<li>
										<a href="#0" target="_blank"><i className="fab fa-facebook-f"></i> </a>
									</li>
									<li>
										<a href="#0" target="_blank"><i className="fab fa-google-plus-g"></i></a>
									</li>
									<li>
										<a href="#0" target="_blank"><i className="fab fa-instagram"></i></a>
									</li>
									<li>
										<a href="#0" target="_blank"><i className="fab fa-twitter"></i> </a>
									</li>
								</ul>
							</div> */}
							{/* <div className="col-12 justify-content-center d-flex mt-4 mb-2">
								<div className="search-holder row">
									<input type="text" name="search" placeholder="Your Email" />
									<button className="btn btn-yellow">Send</button>
								</div>
							</div> */}
							<div className="col-12 mt-4 text-center">
								<ul className="policy-menu text-center">
									<li><a href="/home/AboutContent">About Us</a></li>
									{/* <li><a href="/doctor/doctor-dashboard">Doctors</a></li> */}
									<li><a href="/home/articles/articleView">Blogs</a></li>
									{/* <li><a href="#0">Our Clinic</a></li> */}
									<li><a href="/home/faq">FAQ</a></li>
									<li><a href="#0">Contact Us</a></li>
									<li><a href="/pages/terms">Terms and Conditions</a></li>
									<li><a href="/pages/privacy-policy">Policy</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
		
                <div className="footer-bottom">
					<div className="container-fluid">
					
					
						<div className="copyright">
							<div className="row">
								<div className="col-md-12 col-lg-12 text-center">
									<div className="copyright-text">
										<p className="mb-0">&copy; 2021 <a href="#0" className="text-yellow"> Padmashree Diagnostics</a> All Rights Reserved.</p>
									</div>
								</div>
							</div>
						</div>
					
						
					</div>
				</div>
			</footer>
		
			
	);
};

export default Footer;