import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
     
    // all family_members 
    get_all_family_member_req,
    get_all_family_member_err,
    get_all_family_member_success,

    add_family_member_request,
    add_family_member_success,
    add_family_member_error,

    update_family_member_details_request,
    update_family_member_details_success,
    update_family_member_details_error,

    delete_family_member_request,
    delete_family_member_success,
    delete_family_member_error
  } from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import {isLoading} from '../action.loader/action.function.js';
    import { 
         GET_ALL_FAMILY_MEMBER_REQ,
         ADD_FAMILY_MEMBER_REQUEST,
         DELETE_FAMILY_MEMBER_REQUEST,
         UPDATE_FAMILY_MEMBER_DETAILS_REQUEST

    } from './action.type';
    import toaster from "../../../utils/ReactTostify";
    
    function* allFamily_memberSaga(payload) {
      const {data,callback} =payload;
        try {
            const store = yield select();
            // const userId =  store.user.user?.userId;
        console.log("store values ==========", store?.user?.user?.userId)
        console.log("api url concatication =============",(API_URL.ALL_FAMILY_MEMBERS + store?.user?.user?.userId))
        yield put(isLoading(true));
          let response = yield call(API.get,(API_URL.ALL_FAMILY_MEMBERS + store?.user?.user?.userId),API.getHeaders());
          yield put(isLoading(false));
          const result = response.data;
          console.log("all family_member saga result === ", result);
          yield put(get_all_family_member_success(result));
          callback(null,result);
          
         
        }
        catch (error) {
          yield put(get_all_family_member_err(error));
          yield put(isLoading(false));
          callback(error,null);
        }
      }

      function* addFamily_memberSaga(payload) {
        const {data,callback} =payload;
        console.log("data sent to add family member saga", data)
        try {
          // console.log("api url concatication =============",(API_URL.ADD_FAMILY_MEMBER + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.ADD_FAMILY_MEMBER, data,API.getHeadersFormData());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("add family member response saga",result);
          yield put(add_family_member_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(add_family_member_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
        }
      }


      function* updateFamily_memberSaga(payload) {
        const { data, callback } = payload;
    console.log("requesting update family member saga=======", data);
        try {
          yield put(isLoading(true));
            const response = yield call(API.post, API_URL.UPDATE_FAMILY_MEMBERS, data,API.getHeadersFormData());
            yield put(isLoading(false));
           const result  = response.data;
          console.log("update family members  response saga",result);
          yield put(update_family_member_details_success(result))
            callback(null, result);
           
    
    
        } catch (error) {
          yield put(update_family_member_details_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
    
        }
    }

      function* deleteFamily_memberSaga(payload) {
        const {data,callback} =payload;
        console.log("data sent to delete family members saga", data)
        try {
          // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.DELETE_FAMILY_MEMBERS, data,API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("delete family members response saga",result);
          yield put(delete_family_member_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(delete_family_member_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
        }
      }
      
    
      
export default function* family_memberSaga() { 
    yield takeEvery(GET_ALL_FAMILY_MEMBER_REQ, allFamily_memberSaga);
    yield takeEvery(ADD_FAMILY_MEMBER_REQUEST, addFamily_memberSaga);
    yield takeEvery(DELETE_FAMILY_MEMBER_REQUEST, deleteFamily_memberSaga);
    yield takeEvery(UPDATE_FAMILY_MEMBER_DETAILS_REQUEST, updateFamily_memberSaga);
} 