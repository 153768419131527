import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
    logout_error,
    logout_request,
    logout_success

} from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
   
    import toaster from "../../../utils/ReactTostify";
    import {isLoading} from '../action.loader/action.function.js';
import { POST_LOGOUT_REQUEST } from './action.type.js';


        function* logout_Saga(payload) {
          const {data,callback} =payload;
          console.log("data sent to check logout  saga", data)
          try {
            // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
            yield put(isLoading(true));
            let response = yield call(API.post,API_URL.VERIFY_LOGOUT,API.getHeaders());
            yield put(isLoading(false));
            const result = response.data;
            console.log(" check logout  saga",result);
            yield put(logout_success(result))
            callback(null,result);
          }
          catch(error) {
            yield put(isLoading(false));
            yield put(logout_error(error));
            callback(error,null);
           
            // toaster("error", error);
          }
        }

      export default function* check_logout_Saga() { 
       
        yield takeEvery( POST_LOGOUT_REQUEST, logout_Saga); 
    } 