import React,{ useState,useEffect } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import StickyBox from "react-sticky-box";
import DashboardSidebar from '../sidebar/sidebar.jsx';
import IMG01 from '../../../../assets/images/patients/bell.png';
import Collapse from '@material-ui/core/Collapse';
import { API_URL_ROOT } from '../../../../redux-services/actions/constance/action.constance.js';
// import Collapse from '@material-ui/core/Collapse';
function NotificationsView(props){
  let dataReceived = props.history.location.state?.data;
  console.log("data sent from header",dataReceived);
  const [checked, setChecked] = React.useState(false);
  const handleChangeReadMoreLess = () => {
    setChecked((prev) => !prev);
  };
  const showDownloadButton =  dataReceived.reportUrl ;
  console.log("showDownloadButton",showDownloadButton);
const ReportUrl = "210b6f58-5882-41fe-a7dc-df554a624e5b/R1952111_171858";
  const handle_report = () => {

    const downloadUrl =  dataReceived.reportUrl; 
    
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'report.pdf'); 
    document.body.appendChild(link);
    link.click();
  };
  const isReportUrlUndefined = dataReceived.title == "Reports generated!!";
  console.log("showDownloadButton@@@@",dataReceived.title);
  console.log("showDownloadButton!!!",isReportUrlUndefined);
  return (
    <div>
           {/* <div onClick={handleChange}>
        {(checked == true)?"Read Less": "Read More"}
      </div> */}

            <div className="content">
            <div className="container-fluid">
            <div className="row">
            <div className="col-md-5 col--lg-4 col-xl-3 theiaStickySidebar">
                        <StickyBox offsetTop={20} offsetBottom={20}>
                            <DashboardSidebar {...props}/>
                        </StickyBox>
                       </div>
                       <div className="col-md-7 col-lg-8 col-xl-9">
                   
        <div className="doc-review review-listing">
        <ul className="comments-list">
        <li>
                      <div className="comment">
                        <img
                          className="avatar rounded-circle"
                          alt="Notification"
                          src={(dataReceived.image_url != null)?`${API_URL_ROOT}/${dataReceived.image_url}`:`${IMG01}`}
                        />
                        <div className="comment-body">
                          <div className="meta-data">
                            <span className="comment-author">{((dataReceived.title != "undefined")||(dataReceived.title != undefined) || (dataReceived.title != null)) ? dataReceived.title : '--'}</span>
                            <span className="comment-date">
                            {((dataReceived.created_at != "undefined")||(dataReceived.created_at != undefined) || (dataReceived.created_at != null)) ? moment(dataReceived.created_at).format("DD-MM-YYYY") : null}
                            &nbsp;
                            &nbsp;{((dataReceived.created_at != "undefined")||(dataReceived.created_at != undefined) || (dataReceived.created_at != null)) ? moment(dataReceived.created_at).format('HH:mm:ss') : null}

                            </span>
                            
                          </div>
                          <Collapse in={checked} collapsedSize={10}>
                          <p className="comment-content">
                          {((dataReceived.message != "undefined")||(dataReceived.message != undefined) || (dataReceived.message != null)) ? dataReceived.message : '--'}
                          </p>
                          </Collapse>
                          <div onClick={handleChangeReadMoreLess} style={{
                                                    cursor: "pointer",
                                                }}>
                              {(checked == true)?"Read Less": "Read More"}
                          </div>

                          {/* <div className="comment-reply">
                            <a className="comment-btn" href="#0">
                              <i className="fas fa-reply"></i> Reply
                            </a>
                            <p className="recommend-btn">
                              <span>Recommend?</span>
                              <a href="#0" className="like-btn">
                                <i className="far fa-thumbs-up"></i> Yes
                              </a>
                              <a href="#0" className="dislike-btn">
                                <i className="far fa-thumbs-down"></i> No
                              </a>
                            </p>
                          </div> */}
                             <div>
      {/* Rest of your code */}

      <div>
      {/* Other content */}
      {/* {dataReceived && dataReceived.reportUrl !== null && (
        <button 
          type="button" 
          className="btn btn-primary btn-sm"
          onClick={handle_report}
        >
          View Report
        </button>
      )} */}
    { console.log("Value of showDownloadButton:", isReportUrlUndefined)}

    {isReportUrlUndefined ? (
      <button 
      type="button" 
      className="btn btn-primary btn-sm"
      onClick={handle_report}
    >
      View Report
    </button>
) : (
  // If reportUrl is not null or undefined, show the "View Report" button
 null
)}
      {/* Other content */}
    </div>

      {/* Rest of your code */}
    </div>
                        </div>
                      </div>
                    </li>

        </ul>
          
        </div>

       
                       </div>       

            </div>
                
            </div>

            </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    status: state.user.status,
    error: state.user.error,
    user_by_id:state.user.user_by_id,
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn,
    requesting: state.user.requesting,
  };
  
  };

const mapDispatchToProps = (dispatch) => {return{}}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsView);