import React, {useState,useEffect} from 'react';
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import {useHistory} from "react-router";
import {get_all_test_req, get_all_popular_test_req} from "../../../redux-services/actions/action.test/action.function.js"
import doctor1 from "../../../assets/images/doctors/doctor-01.jpg";
import testImg from "../../../assets/images/images/general-03.jpg"
import doctor2 from "../../../assets/images/doctors/doctor-02.jpg";
import doctor3 from "../../../assets/images/doctors/doctor-03.jpg";
import doctor4 from "../../../assets/images/doctors/doctor-04.jpg";
import doctor5 from "../../../assets/images/doctors/doctor-05.jpg";
import doctor6 from "../../../assets/images/doctors/doctor-06.jpg";
import doctor7 from "../../../assets/images/doctors/doctor-07.jpg";
import doctor8 from "../../../assets/images/doctors/doctor-08.jpg";

function OurTests(props) {
   
    useEffect(() => {
    //     const { get_all_test } =props;
    //     get_all_test();
    //      const all_test = props.all_test;
    //   console.log("gen_table =====",all_test);
    const { get_all_popular_test } =props;
        get_all_popular_test();
         const all_popular_test = props.all_popular_test;
      console.log("gen_table =====",all_popular_test);
      },[]);
      const history = useHistory();
      const nextPath = (path,data) => {
        history.push({
          pathname: path,
          state:data
        });
      };
      const handle_details = (data) =>{
        nextPath(`/home/alltestsview/testdetails`,data);
      }
        const settings = {
            dots:false,
            infinite: true,
            lazyLoad: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            centerPadding: '10px',
            arrows: true,
            autoplay: true,
      autoplaySpeed: 2000,
      adaptiveHeight: true,
      pauseOnHover: true,
      
    //   vertical: true,
    //   verticalSwiping: true,
         
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      infinite: true,
                    //   dots: true
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      initialSlide: 2,
                      infinite: true,
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                    }
                  }
            ]
          };
        return(
            <>
                {(props.all_popular_test?.response.length == 0)?null:(
                    <section className="doctors-col">
            <div className="container">					
                <div className="row justify-content-center">	
                    <div className="doctors-title text-center">					
                        <h6>Tests We Offer</h6>
                        <h2>Popular Tests</h2>
                    </div>
                </div>
                
               <div className="row">
                    <div className="col-lg-12">
                        <div className="doctor-slider slider slick-initialized slick-slider">
                            
                        <Slider {...settings}>
                        {(props.all_popular_test && (props.all_popular_test?.response.length > 0)) ? (props.all_popular_test?.response.map((test,idx) =>(
                            <div className="profile-widget" key={idx} onClick={() => handle_details(test)} style={{
                                                    cursor: "pointer",
                                                }}>
                                <div className="doc-img" onClick={() => handle_details(test)} style={{
                                                    cursor: "pointer",
                                                }}>
                                    
                                        <img className="img-fluid" alt="User Image" src={testImg} />
                                    
                                </div>
                                <div className="pro-content" onClick={() => handle_details(test)} style={{
                                                    cursor: "pointer",
                                                }}>
                                    <h3 className="title" onClick={() => handle_details(test)} style={{
                                                    cursor: "pointer",
                                                }}>
                                        {/* <a href="doctor-profile.html">Denby Cathey</a>  */}
                                        {test.name}
                                    </h3>
                                    <p className="speciality" onClick={() => handle_details(test)} style={{
                                                    cursor: "pointer",
                                                }}>{(test.service_short_description != null)? ((test.service_short_description.length > 30)?`${test.service_short_description.substring(0,30)}...`:test.service_short_description):"None"}</p>
                                    
                                    {/* <div className="rating">
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star"></i>
                                        <span className="d-inline-block average-rating">(66)</span>
                                    </div> */}
                                    <ul className="available-info">
                                        <li>
                                            <i className="fas fa-wallet"></i> {(test.cost != null)? `₹ ${test.cost}`:"None"}
                                        </li>
                                    </ul>
                                </div>									
                                <div className="pro-footer" onClick={() => handle_details(test)} style={{
                                                    cursor: "pointer",
                                                }}>
                                    <ul className="policy-menu text-center">
                                        <li onClick={() => handle_details(test)} style={{
                                                    cursor: "pointer",
                                                }}>Book Now</li>
                                    </ul>
                                </div>
                            </div>))):null} 
                           
                       
                            
                            </Slider>
                            
                        </div>
                        <div className="col-12 col-md-12 text-center pt-4">
                        <Link to="/home/allTestsView/allTests" className="view-all">View All </Link>
                        </div>
                    </div>
               </div>
            </div>
        </section>
                )}
            </>
           
        );
    
}

const mapDispatchToProps = (dispatch) => {
	return {
		get_all_test: () =>dispatch(get_all_test_req()),
        get_all_popular_test: () =>dispatch(get_all_popular_test_req()),
	};
  };
  const mapStateToProps = (state) => {
	return {
	  status: state.user.status,
	  error: state.user.error,
      all_test: state.test.all_test,
      all_popular_test: state.test.all_popular_test,
	  user: state.user.user,
	  isLoggedIn: state.user.isLoggedIn,
	  requesting: state.user.requesting,
  
  
	};
  };
  export default connect(mapStateToProps, mapDispatchToProps)(OurTests);
	
    