import React,{ useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import {useFormik} from "formik";
import Select from "react-select";
import * as Yup from 'yup';
import {connect} from "react-redux";
import {useHistory} from "react-router";
import DashboardSidebar from '../../sidebar/sidebar.jsx';
import {update_my_address_details_request,get_all_city_req, get_all_area_by_city_id_req} from "../../../../../redux-services/actions/action.myaddress/action.function.js"
import IMG01 from '../../../../../assets/images/patients/patient.jpg';
import toaster from "../../../../../utils/ReactTostify";
import StickyBox from "react-sticky-box";
import MapView from "../../../../../utils/MapView";
function MyAddressEdit(props) {
  const history = useHistory();
  const dataValue = history.location.state;
console.log("dataValues passed to view address",dataValue);
const [mapViewBtn, setMapViewBtn] = useState(false);
 let latitudeInitialValue = (dataValue.latitude != null) ? parseFloat(dataValue.latitude):12.972442;
 const [lat, setLat] = useState(latitudeInitialValue);
 let LongitudeInitialValue = (dataValue.longitude != null) ? parseFloat(dataValue.longitude):77.530376;
const [lng, setLng] = useState(LongitudeInitialValue);

const [allCityData, setAllCityData] = useState([]);
const [optionValueAllAreaByCityId, setOptionValueAllAreaByCityId] = useState([]);
const [allAreaByCityIdData, setAllAreaByCityIdData] = useState([]);
const [selectOptionsAllCity, setSelectOptionsAllCity] = useState([]);
const [selectOptionsAllAreaByCityId, setSelectOptionsAllAreaByCityId] = useState([]);
const [optionValueAllCity, setOptionValueAllCity] = useState([]); 

const [submitButton, setSubmitButton] = useState(false);


  //cet city and area
  const [cityName, setCityName] = useState("");
  const [cityid, setCityid] = useState(`${dataValue.city_id}`);
  const [areaName, setAreaName] = useState("");
  const [areaid, setAreaid] = useState(`${dataValue.area_id}`);

  const [formattedAddress, setFormattedAddress] = useState("");
  const [stateCnt, setStateCnt] = useState("");
  const [pinCode, setPinCode] = useState("");
  
    const [addressType, setAddressType] = useState("");
    const [memberName, setMemberName] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [streetName, setStreetName] = useState("");
    const [area, setArea] = useState("");
    const [landmark, setLandmark] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [mobile, setMobile] = useState("");
   // const [city, setCity] = useState("");

 
  //  city set values
  const [selectoptions, setSelectoptions] = useState([]);
  const [optionCityValue, setOptionCityValue] = useState([]);

  // area set Values
  const [optionCityAreaValue, setOptionCityAreaValue] = useState([]);


    const optionsRdBtn = ["Home","Office"];
    // const phoneVal = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const phoneVal = new RegExp(/^[6-9]{1}[0-9]{9}$/);
    // const history = useHistory();
    // const dataValue = history.location.state
    // console.log("data passed form index to edit",dataValue);

    const getAllCityApiCall = async () => {
      await props.get_all_city(null, (error, success) => {
        if (error) {
          console.log("error", error);
        }
        if (success.status == 200) {
          
          
          if (success && success?.response.length > 0) {
            setAllCityData(success?.response);
            const optionsAllCity =
              success?.response.length > 0
                ? success.response.map((AllCitySelect, idx) => ({
                    city_id: AllCitySelect.city_id,
                    city_name: `${AllCitySelect.cityname}`
                  }))
                : null;
            setSelectOptionsAllCity(optionsAllCity);
          }
          if (success && success?.response.length <= 0) {
            console.log("data length empty", success?.message);
            
          }
        }
      });
    }
    useEffect(() => {
      getAllCityApiCall();
        setMemberName(dataValue.member_name);
        setHouseNumber(dataValue.house_number);
        setStreetName(dataValue.street_name);
        setArea(dataValue.area);
        setLandmark(dataValue.landmark);
        setCity(dataValue.city);

        let cityOptionData = [{
          city_id: dataValue.city_id,
          city_name: dataValue.city
        }]

        setOptionCityValue(cityOptionData);

        let areaOptionData = [{
          areaid: dataValue.area_id,
          areaname:dataValue.area 
        }]
        setOptionCityAreaValue(areaOptionData);

        setState(dataValue.state);
        setPincode(dataValue.pincode);
        setMobile(dataValue.mobile);
        if((dataValue.address_type == "home") || dataValue.address_type == "Home"){
            setAddressType("Home");
        }
        else if((dataValue.address_type == "office") || (dataValue.address_type == "Office")){
            setAddressType("Office");
        }
        else if((dataValue.address_type == "others") || (dataValue.address_type == "Others")){
            setAddressType("Others");
        }


      }, []);
      const handleBack = () => {
        props.history.goBack();
      };
      //map declare
      const setLatitiudeFnc = (lat) => setLat(lat);
      const setLongitudeFnc = (lng) => setLng(lng);
      console.log("intialvalue1",latitudeInitialValue);
      console.log("intialvalue1",LongitudeInitialValue);
      console.log("lat1",lat);
      console.log("lng1",lng);

      const formattedAddressFnc = (formattedAddr) =>
        setFormattedAddress(formattedAddr);
    
        

        const areaFnc = (areaObtained) => setArea(areaObtained);
        const streetNameFnc = (streetNameObtained) =>setStreetName(streetNameObtained);
        const stateCntFnc = (stateCntObatined) =>setStateCnt(stateCntObatined);
        const pinCodeFnc = (pinCodeObtained) =>setPinCode(pinCodeObtained);
        const houseNumberFnc = (houseNumberObtained) => ((houseNumberObtained == null) || (houseNumberObtained =="") || (houseNumberObtained ==undefined))? setHouseNumber(dataValue.house_number):setHouseNumber(houseNumberObtained);
    

        const handleAllCity = async(e) => {
          console.log("values of select city ", e);
          formik.setFieldValue("city", e.city_name);
          var data ={
            cityid: e.city_id
          }
          await getAllAreaByCityIdApiCall(data);
          setOptionValueAllCity(e);
          setOptionCityValue(e);
          setCityName(e.city_name);
          setCityid(e.city_id);
    
        };

        const getAllAreaByCityIdApiCall = async (data) => {
          await props.get_all_area_by_city_id(data, (error, success) => {
            if (error) {
              console.log("error", error);
            }
            if (success.status == 200) {
              if (success && success?.areadata.length > 0) {
                setAllAreaByCityIdData(success?.areadata);
                const optionsAllAreaByCityId =
                  success?.areadata.length > 0
                    ? success.areadata.map((AllAreaByCityIdSelect, idx) => ({
                      areaid: AllAreaByCityIdSelect.areaid,
                      areaname: `${AllAreaByCityIdSelect.areaname}`
                      }))
                    : null;
                setSelectOptionsAllAreaByCityId(optionsAllAreaByCityId);
              }
              if (success && success?.areadata.length <= 0) {
                console.log("data length empty", success?.message);
                
              }
            }
          });
        };
    
        const handleAllAreaByCityId = async(e) => {
          console.log("values of select AllAreaByCityId", e);
          formik.setFieldValue("area", e.areaname);
          
          
          setOptionValueAllAreaByCityId(e);
          setOptionCityAreaValue(e);
          setAreaName(e.areaname);
          setAreaid(e.areaid);
        };

        

      const validate = Yup.object({
        member_name:Yup.string()
        .max(20,'Must be 20 character or less')
        .required('Name must not be empty'),
          house_number:Yup.string()
          .max(10,'Must be 10 character or less')
          .required('House Number must not be empty'),
          street_name:Yup.string()
          .max(100,'Must be 100 character or less')
          .required('Street Name must not be empty'),
          area:Yup.string()
          .max(100,'Must be 100 character or less')
          .required('Area must not be empty'),
          landmark:Yup.string()
          .max(100,'Must be 100 character or less')
          .required('Landmark must not be empty'),
          city:Yup.string()
          .max(20,'Must be 20 character or less')
          .required('City must not be empty'),
          state:Yup.string()
          .max(20,'Must be 20 character or less')
          .required('State must not be empty'),
          pincode:Yup.string()
          .min(6,'Pincode should include 6 character')
          .max(6,'Pincode allowed only 6 character')
          .required('Pincode must not be empty'),
          address_type:Yup.string(),
          // mobile:Yup.string().matches(phoneVal, 'phone number is not valid')
          // .min(10, 'Mobile number should include 10 character')
          // .max(10, 'Mobile number allowed only 10 character')
          // .required('mobile number required'),
      });
      const formik = useFormik({
        initialValues: {
          "member_name":dataValue.member_name,
          "house_number":dataValue.house_number,
          "street_name":dataValue.street_name,
          "area":dataValue.area,
          "area_id":dataValue.area_id,
          "landmark":dataValue.landmark,
          "city":dataValue.city,
          "city_id":dataValue.city_id,
          "state":dataValue.state,
          "pincode":dataValue.pincode,
          "address_type":dataValue.address_type,
          // "mobile":dataValue.mobile
        },
        enableReinitialize: true,
        validationSchema: validate,
        onSubmit: async (values) => {
          setSubmitButton(true);
            console.log("values submission", values);
            console.log("user id ==", props.user.userId);
            var dt = formik.values;
    
                   var data = {
    
                 userid:props.user.userId,
                 address_id:dataValue.address_id,
                member_name:dt.member_name,
                house_number:dt.house_number,
                street_name:dt.street_name,
                area:dt.area,
                area_id:areaid,
                landmark:dt.landmark,
                city:dt.city,
                city_id:cityid,
                state:dt.state,
                pincode:dt.pincode,
                address_type:dt.address_type,
                mobile:dt.mobile,
                latitude: lat, 
                longitude: lng
        
                   };
                    console.log("values submission jsonData", data);
    
    
           
    
               await props.update_my_address(data,(error,success)=>{
                setSubmitButton(false);
                if(error){
                  
                  console.log("error",error);
                }    
                if(success.status == 200 ){
                  toaster('success', success.message);
                    handleBack();
                 
               }
               });
           
          
        },
        onReset: (values) => {}
    
    })

    const handleRadioOptn = (event) =>{
        setAddressType(event.target.value);
        formik.setFieldValue("address_type", event.target.value);
      }

    const onChangeMemberName = (event) =>{
        setMemberName(event.target.value);
    }
  
    const onBlurMemberName= (event) =>{
      formik.setFieldValue("member_name",event.target.value);
  }

  const onChangeHouseNumber = (event) =>{
    setHouseNumber(event.target.value);
}

const onBlurHouseNumber = (event) =>{
  formik.setFieldValue("house_number",event.target.value);
}

  const onChangeStreetName = (event) =>{
    setStreetName(event.target.value);
 console.log("street_name value",event.target.value)
   
}

const onBlurStreetName= (event) =>{
  formik.setFieldValue("street_name",event.target.value);
}

const onChangeArea = (event) =>{
    setArea(event.target.value);
}

const onBlurArea= (event) =>{
  formik.setFieldValue("area",event.target.value);
}

const onChangeLandmark = (event) =>{
    setLandmark(event.target.value);
}

const onBlurLandmark= (event) =>{
  formik.setFieldValue("landmark",event.target.value);
}

const onChangeCity = (event) =>{
    setCity(event.target.value);
}

const onBlurCity= (event) =>{
  formik.setFieldValue("city",event.target.value);
}

const onChangeState = (event) =>{
    setState(event.target.value);
}

const onBlurState= (event) =>{
  formik.setFieldValue("state",event.target.value);
}

const onChangePincode = (event) =>{
    setPincode(event.target.value);
}

const onBlurPincode= (event) =>{
  formik.setFieldValue("pincode",event.target.value);
}

const onChangeMobile = (event) =>{
    setMobile(event.target.value);
}

const onBlurMobile= (event) =>{
  formik.setFieldValue("mobile",event.target.value);

  
}
    return (
        <div>
        <div className="breadcrumb-bar">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/home">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">My Address Edit</li>
                        </ol>
                    </nav>
                    <h2 className="breadcrumb-title">My Address Edit</h2>
                </div>
            </div>
        </div>
    </div>
    <div className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-5 col--lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                    < DashboardSidebar />
                </StickyBox>
               </div>

               <div className="col-md-7 col-lg-8 col-xl-9">
<div className="card">
<div className="card-body">
    
 
<form onSubmit={formik.handleSubmit}>
        <div className="row form-row">
            
        {/* <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={memberName}
                             placeholder="Enter address holder name"
                             onChange={(event) => onChangeMemberName(event)}
                 onBlur={(event) =>onBlurMemberName(event)}
                           
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                        {
            formik.touched.member_name && formik.errors.member_name ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.member_name}
              </div>
            ) : null}
                      </div> */}
 
                      {/* <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>House Number</label>
                          <input
                            type="text"
                            // max={6}
                            className="form-control"
                            placeholder="Enter house number"
                            value={houseNumber}
                            
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            onChange={(event) => onChangeHouseNumber(event)}
                 onBlur={(event) =>onBlurHouseNumber(event)}
                            
                          />
                        </div>
                        {
            formik.touched.house_number && formik.errors.house_number ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.house_number}
              </div>
            ) : null}
                        
                      </div> */}
                      <div className="offset-1 col-md-10 map mb-2">
              <div id="map" className="map-listing">
                <div style={{height: "100px", width: "100%"}}>
                  <MapView
                    setLatitude={setLatitiudeFnc}
                    setLongitude={setLongitudeFnc}
                    setFormattedAddress={formattedAddressFnc}
                    setPinCode={pinCodeFnc}
                    setStateValue={stateCntFnc}
                    setHouseNumber={houseNumberFnc}
                    setStreetName={streetNameFnc}
                    priority={"0"}
                     lat={lat}
                     lng={lng}
                    center={{lat:12.9666, lng:77.530376}}
                    // center={{lat:13.941127421707419, lng:75.57465668236651}}
                      gestureHandling="cooperative"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>City <span className="errorcolor ml-0">*</span></label>
                 
                    <Select
                            options={selectOptionsAllCity}
                            // value={city}
                            onChange={(event) => {
                              handleAllCity(event);
                            }}
                            id="exampleFormControlInput2"
                            name="select_city"
                            value={optionCityValue || []}
                            getOptionLabel={(selectoptionsAllCity) =>
                              selectoptionsAllCity["city_name"]
                            }
                            getOptionValue={(selectoptionsAllCity) =>
                              selectoptionsAllCity["city_id"]
                            }
                          />
                  </div>
                  {formik.touched.city && formik.errors.city ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.city}
                    </div>
                  ) : null}
                  
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Area <span className="errorcolor ml-0">*</span></label>
                   
                    <Select
                            options={selectOptionsAllAreaByCityId}
                            
                            onChange={(event) => {
                              handleAllAreaByCityId(event);
                            }}
                            id="exampleFormControlInput2"
                            name="select_area"
                            value={optionCityAreaValue || []}
                            
                            getOptionLabel={(selectoptionsAllAreaByCityId) =>
                              selectoptionsAllAreaByCityId["areaname"]
                            }
                            getOptionValue={(selectoptionsAllAreaByCityId) =>
                              selectoptionsAllAreaByCityId["areaid"]
                            }
                          />
                  </div>
                  {formik.touched.area && formik.errors.area ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.area}
                    </div>
                  ) : null}
                </div>
            <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Name <span className="errorcolor ml-0">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            value={memberName}
                             placeholder="Enter address holder name"
                             onChange={(event) => onChangeMemberName(event)}
                 onBlur={(event) =>onBlurMemberName(event)}
                           
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                        {
            formik.touched.member_name && formik.errors.member_name ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.member_name}
              </div>
            ) : null}
                      </div> 
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>House Number <span className="errorcolor ml-0">*</span></label>
                          <input
                            type="text"
                            // max={6}
                            className="form-control"
                            placeholder="Enter house number"
                            value={houseNumber}
                            
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            onChange={(event) => onChangeHouseNumber(event)}
                 onBlur={(event) =>onBlurHouseNumber(event)}
                            
                          />
                        </div>
                        {
            formik.touched.house_number && formik.errors.house_number ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.house_number}
              </div>
            ) : null}
                        
                      </div> 
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Street Name <span className="errorcolor ml-0">*</span></label>
                          <textarea
                            type="text"
                             rows={4}
                            className="form-control"
                             placeholder="Enter street name"
                            value={streetName}
                            onChange={(event) => onChangeStreetName(event)}
                 onBlur={(event) =>onBlurStreetName(event)}
                            
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                           
                          />
                        </div>
                        {
            formik.touched.street_name && formik.errors.street_name ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.street_name}
              </div>
            ) : null}
                      </div>

                      {/* <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Area</label>
                          <input
                            type="text"
                            // max={6}
                            className="form-control"
                             placeholder="Enter area"
                            value={area}
                            onChange={(event) => onChangeArea(event)}
                 onBlur={(event) =>onBlurArea(event)}
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                        {
            formik.touched.area && formik.errors.area ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.area}
              </div>
            ) : null}
                      </div> */}
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Landmark <span className="errorcolor ml-0">*</span></label>
                          <input
                            type="text"
                            // max={6}
                            className="form-control"
                             placeholder="Enter landmark"
                            value={landmark}
                            onChange={(event) => onChangeLandmark(event)}
                 onBlur={(event) =>onBlurLandmark(event)}
                            
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>

                        {
            formik.touched.landmark && formik.errors.landmark ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.landmark}
              </div>
            ) : null}
                      
                      </div>
{/* 
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            // max={6}
                            className="form-control"
                            placeholder="Enter city"
                            value={city}
                            onChange={(event) => onChangeCity(event)}
                 onBlur={(event) =>onBlurCity(event)}
                            
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                        {
            formik.touched.city && formik.errors.city ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.city}
              </div>
            ) : null}
                        
                      </div> */}
                     
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>State <span className="errorcolor ml-0">*</span></label>
                          <input
                            type="text"
                            // max={6}
                            className="form-control"
                             placeholder="Enter state"
                            value={state}
                            onChange={(event) => onChangeState(event)}
                 onBlur={(event) =>onBlurState(event)}
                           
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                        {
            formik.touched.state && formik.errors.state ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.state}
              </div>
            ) : null}
                        
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Pincode <span className="errorcolor ml-0">*</span></label>
                          <input
                            type="text"
                            maxLength={6}
                            className="form-control"
                             placeholder="Enter pincode"
                            value={pincode}
                            onChange={(event) => onChangePincode(event)}
                 onBlur={(event) =>onBlurPincode(event)}
                          
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            
                          />
                        </div>
                        {
            formik.touched.pincode && formik.errors.pincode ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.pincode}
              </div>
            ) : null}
                        
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Address Type</label>
                          <div className="row ml-2">
                            {optionsRdBtn.map((options, idxo) => (
                              <div
                                key={idxo}
                                className="text-primary col-md-3"
                                id={idxo}
                              >
                                <input
                                  className="form-check-input mr-2"
                                  type="radio"
                                  id={`optnRd[${idxo}]`}
                                  value={options}
                                  name={`optnameVal`}
                                  checked={addressType === options}
                                  // checked={
                                  //   radioBtn == idxo
                                  //     ? true
                                  //     : false
                                  // }
                                  onChange={(event) => {
                                    handleRadioOptn(event);
                                  }}
                                />
                                <label>{options}</label>
                              </div>
                            ))}
                          </div>
                          {
            formik.touched.address_type && formik.errors.address_type ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.address_type}
              </div>
            ) : null}
                          
                      </div>
                      
                      </div>
                     
                     
                      <div className="col-12 col-md-6">
                        {/* <div className="form-group">
                          <label>Mobile <span className="errorcolor ml-0">*</span></label>
                          <input
                            type="text"
                            maxLength={10}
                            // defaultValue="+91 9876543210"
                             placeholder="Enter mobile number..."
                            value={mobile}
                            className="form-control"
                            onChange={(event) => onChangeMobile(event)}
                 onBlur={(event) =>onBlurMobile(event)}
                           
                          />
                        </div>
                        {
            formik.touched.mobile && formik.errors.mobile ? (
              <div
                style={{
                  color: "red"
                }}
                className="mb-2"
              >
                {formik.errors.mobile}
              </div>
            ) : null} */}
                      </div>

            <div className="mx-1 row">
                        <div className="submit-section mr-3">
                          <button
                            type="submit"
                            className="btn btn-primary submit-btn"
                            disabled={submitButton}
                          >
                            Save Changes
                          </button>
                        </div>
                        <div className="submit-section">
                          <button
                            type="button"
                            className="btn btn-primary submit-btn"
                            onClick={handleBack}
                          >
                            Back
                          </button>
                        </div>
                      </div>

            {/* <div className="submit-section">
                    <button type="submit" className="btn btn-primary submit-btn">Save Changes</button>
                </div> */}
            
        </div>
    </form>
</div>

</div>
</div>
            </div>
        </div>
    </div>      
    </div>
);
}

const mapDispatchToProps = (dispatch) => {
    return {
      update_my_address: (data,callback) =>dispatch(update_my_address_details_request(data,callback)),
      get_all_city: (data, callback) =>dispatch(get_all_city_req(data, callback)),
      get_all_area_by_city_id: (data, callback) =>dispatch(get_all_area_by_city_id_req(data, callback))
    };
  };
  const mapStateToProps = (state) => {
    return {
      status: state.user.status,
      error: state.user.error,
      all_my_address: state.myaddress.all_my_address,
      user_by_id: state.user.user_by_id,
      user: state.user.user,
      isLoggedIn: state.user.isLoggedIn,
      requesting: state.user.requesting
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(MyAddressEdit);
