import React, {Component} from "react";
import {
  Map,
  Marker,
  GoogleApiWrapper,
  InfoWindow,
  Circle
} from "google-maps-react";
import {GOOGLE_MAP_API_KEY} from "../redux-services/api/api.js";
import Iconmap from "../assets/images/marker.png";
import {circleRadius,circleRadiusInMeters} from "../utils/constants.js"

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};
// function success(pos){
//   var crd = pos.coords;

//   console.log("Your current position is:");
//   console.log(`Latitude : ${crd.latitude}`);
//   console.log(`Longitude: ${crd.longitude}`);
//   console.log(`More or less ${crd.accuracy} meters.`);
// }

// function errors(err){
//   console.warn(`ERROR(${err.code}): ${err.message}`);
// }

class MapView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      lat: this.props.lat,
      lng: this.props.lng,
      loc_name: null,
      area_name: null,
      activeAddress: "",

      premise: null,
      neighborhood: null,
      sublocality_level_3: null,
      sublocality_level_2: null,
      sublocality_level_1: null,
      localityCity: null,
      administrative_area_level_2: null,
      administrative_area_level_1_state: null,
      country: null,
      postal_code: null
    };
    //  this.success = this.success.bind(this);
    // this.errors = this.errors.bind(this);
    this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this);
    this.geoLocationApiCall = this.geoLocationApiCall.bind(this);
    this.getGeoCoderAddressComponentTypeValue = this.getGeoCoderAddressComponentTypeValue.bind(this);
    this.arePointsNear = this.arePointsNear.bind(this);
  }
  
  componentDidMount() {

    //  if (navigator.geolocation) {
    //   navigator.geolocation.watchPosition(function(position) {
    //     console.log("Latitude is :", position.coords.latitude);
    //     console.log("Longitude is :", position.coords.longitude);
    //   });
    //   navigator.geolocation.getCurrentPosition(function(position) {
    //   console.log("Latitude is :", position.coords.latitude);
    //   console.log("Longitude is :", position.coords.longitude);
    // });
    // }
    console.log("navigator values == ", navigator);
    console.log("latitude passed from datavalues ===",this.props.lat);
    console.log("longitude passed from datavalues ===",this.props.lng);
  
    
    let success = (pos) => {
      var crd = pos.coords;

      console.log("Your current position is:");
      console.log(`Latitude : ${crd.latitude}`);
      console.log(`Longitude: ${crd.longitude}`);
      console.log(`More or less ${crd.accuracy} meters.`);

      if(this.props.priority === "1"){
        this.setState({
          lat: parseFloat(crd.latitude),
          lng: parseFloat(crd.longitude)
         
         // console.log("Your current position is:");
         // console.log(`Latitude : ${crd.latitude}`);
         // console.log(`Longitude: ${crd.longitude}`);
         // console.log(`More or less ${crd.accuracy} meters.`);
   
         // this.setState({
         //   lat: lattiudeInitialValue,
         //   lng: LongitudeInitialValue
         });
      } 
      if(this.props.priority === "0"){
        this.setState({
          lat: parseFloat(this.props.lat),
          lng: parseFloat(this.props.lng)
        });
      }
      
     
      this.geoLocationApiCall();
    };

    let errors = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    };

    if (navigator.geolocation) {
      navigator.permissions
      .query({name: "geolocation"})
        .then(function (result) {
        console.log("permission state", result.state);
          if (result.state === "granted") {
            console.log("permission granted state", result.state);
            //If granted then you can directly call your function here 
             navigator.geolocation.getCurrentPosition(success);
            // console.log("this.props.priority ====",this.props.priority);
          //   if(this.props.priority === 1){
          //     navigator.geolocation.getCurrentPosition(success);
          //  }
           
            
          } else if (result.state === "prompt") {
            console.log("permission prompt state", result.state);
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            console.log("permission denied state", result.state);
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {
      alert("Sorry Not available!");
    }
    // { (this.props.Latitude == 1) ? dataValue.Latitude:12.972442}
    // { (this.props.longitude == 1) ? dataValue.Longitude:77.530376}
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  };

  getGeoCoderAddressComponentTypeValue = (addr_comp,type_val) => {
    let long_name ="";

    for (const address of addr_comp){
      address.types.forEach((typeVal,idx) =>{
          if(type_val.includes(typeVal))
          return long_name = address.long_name;
      })
      // if (address.types.includes(type_val)){
      //   return long_name = address.long_name;
        
      // }
    }
    return long_name;
  }

  geoLocationApiCall = () => {
    let geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      {
        latLng: {
          lat: this.state.lat,
          lng: this.state.lng
        }
      },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            let address = results[0].formatted_address;
            this.props.setFormattedAddress(address);
            this.setState({
              activeAddress: address
            });
            console.log("entire address from map", results);
            let adrs_comp = results[0].address_components;
            console.log("address =", address);
            console.log("address comp", adrs_comp);
            let premiseValue = this.getGeoCoderAddressComponentTypeValue(adrs_comp,["premise","street_number"]);
            console.log("premiseValue", premiseValue);

            let neighborhoodValue = this.getGeoCoderAddressComponentTypeValue(adrs_comp,["neighborhood"]);
            console.log("neighborhoodValue", neighborhoodValue);

            let routeValue = this.getGeoCoderAddressComponentTypeValue(adrs_comp,["route"]);
            console.log("routeValue", routeValue);


            let sublocality_level_3Value = this.getGeoCoderAddressComponentTypeValue(adrs_comp,["sublocality_level_3"]);
            console.log("sublocality_level_3Value", sublocality_level_3Value);

            let sublocality_level_2Value = this.getGeoCoderAddressComponentTypeValue(adrs_comp,["sublocality_level_2"]);
            console.log("sublocality_level_2Value", sublocality_level_2Value);
            
            let sublocality_level_1Value = this.getGeoCoderAddressComponentTypeValue(adrs_comp,["sublocality_level_1"]);
            console.log("sublocality_level_1Value", sublocality_level_1Value);
            let localityValue = this.getGeoCoderAddressComponentTypeValue(adrs_comp,["locality"]);
            console.log("localityValue", localityValue);

            let administrative_area_level_2Value = this.getGeoCoderAddressComponentTypeValue(adrs_comp,["administrative_area_level_2"]);
            console.log("administrative_area_level_2Value", administrative_area_level_2Value);


            let administrative_area_level_1Value = this.getGeoCoderAddressComponentTypeValue(adrs_comp,["administrative_area_level_1"]);
            console.log("administrative_area_level_1Value", administrative_area_level_1Value);

            let countryValue = this.getGeoCoderAddressComponentTypeValue(adrs_comp,["country"]);
            console.log("countryValue", countryValue);

            let postal_codeValue = this.getGeoCoderAddressComponentTypeValue(adrs_comp,["postal_code"]);
            console.log("postal_codeValue", postal_codeValue);


            // this.setState({
            //   neighborhood:neighborhoodValue,
            //   sublocality_level_3:
            // })

            // for (let i = 0; i < adrs_comp.length; i++) {
            //   if (adrs_comp[i].types[0] === "premise") {
            //     let premise_long_name = adrs_comp[i].long_name;
            //     this.setState({
            //       premise: premise_long_name
            //     });
            //   }
            //   if (adrs_comp[i].types[0] === "neighborhood") {
            //     let neighborhood_long_name = adrs_comp[i].long_name;
            //     this.setState({
            //       neighborhood: neighborhood_long_name
            //     });
            //   }
             
              
            //   // if (adrs_comp[i].types[2] !== "sublocality_level_3") {
                
            //   //   this.setState({
            //   //     sublocality_level_3: ""
            //   //   });
            //   // } 
              
              

            //   if (adrs_comp[i].types[2] === "sublocality_level_2") {
            //     let sublocality_level_2_long_name = adrs_comp[i].long_name;
            //     this.setState({
            //       sublocality_level_2: sublocality_level_2_long_name
            //     });
            //   }
            //   if (adrs_comp[i].types[2] === "sublocality_level_1") {
            //     let sublocality_level_1_long_name = adrs_comp[i].long_name;
            //     this.setState({
            //       sublocality_level_1: sublocality_level_1_long_name
            //     });
            //   }
            //   if (adrs_comp[i].types[0] === "locality") {
            //     let localityCity_long_name = adrs_comp[i].long_name;
            //     this.setState({
            //       localityCity: localityCity_long_name
            //     });
            //   }
            //   if (adrs_comp[i].types[0] === "administrative_area_level_2") {
            //     let administrative_area_level_2_long_name = adrs_comp[i].long_name;
            //     this.setState({
            //       administrative_area_level_2:administrative_area_level_2_long_name
            //     });
            //   }
            //   if (adrs_comp[i].types[0] === "administrative_area_level_1") {
            //     let administrative_area_level_1 = adrs_comp[i].long_name;
            //     this.setState({
            //       administrative_area_level_1_state: administrative_area_level_1
            //     });
            //   }

            //   if (adrs_comp[i].types[0] === "country") {
            //     let country_long_name = adrs_comp[i].long_name;
            //     this.setState({
            //       country: country_long_name
            //     });
            //   }

            //   if (adrs_comp[i].types[0] === "postal_code") {
            //     let postal_code_long_name = adrs_comp[i].long_name;
            //     this.setState({
            //       postal_code: postal_code_long_name
            //     });
            //   }
            //   if (adrs_comp[i].types[2] === "sublocality_level_3") {
            //     let sublocality_level_3_long_name = adrs_comp[i].long_name;
            //     this.setState({
            //       sublocality_level_3: sublocality_level_3_long_name
            //     });
            //   }
            //   // else{
            //   //   this.setState({
            //   //     sublocality_level_3:""
            //   //   });
            //   // }
            // }

            this.props.setPinCode(postal_codeValue);
            this.props.setStateValue(administrative_area_level_1Value);
            this.props.setHouseNumber(premiseValue);
            this.props.setStreetName(`${neighborhoodValue} ${routeValue} ${sublocality_level_3Value} ${sublocality_level_2Value}`);

          }
        }
      }
    );

    // console.log("address after additon to address value", address);
  };
 
  // to check if the location co-ordinates are with in circle radius
   arePointsNear = (checkPoint, centerPoint, km) =>{ 
    var ky = 40000 / 360;
    var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
    var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    console.log("centerPoint lng ===",centerPoint.lng);
    console.log("centerPoint lat ==",centerPoint.lat);
    console.log("checkPoint lng",checkPoint.lng);
    console.log("checkPoint lat",checkPoint.lat);
    console.log("maths values ====", Math.sqrt(dx * dx + dy * dy));
    console.log("km values ====", km);
     console.log("lat value", this.state.lat)
    
    

    return Math.sqrt(dx * dx + dy * dy) <= km;
 }
 

  onMarkerDragEnd = (coord) => {
    const {latLng} = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    console.log("selected latitude values", lat);
    console.log("selected longitude values", lng);
    this.setState({
      lat: lat,
      lng: lng
    });
    
    let checkpoint = {
      lat: lat,
      lng: lng
    }

    var obtainedValue = this.arePointsNear(checkpoint,this.props.center,circleRadius);
    console.log("obtainedValue ===",obtainedValue);
    if(obtainedValue){
      this.props.setLatitude(lat);
    this.props.setLongitude(lng);

    this.geoLocationApiCall();
    }
    else{
      alert(`you are exceeding the servicable zone,kindly provide with the relavant address to avail the services...`);
      this.props.setPinCode("");
            this.props.setStateValue("");
            this.props.setHouseNumber("");
            this.props.setStreetName(``);
    }

  };
  render() {
    console.log("selected place state values", this.state.selectedPlace);
    console.log("address state", this.state.activeAddress);
    console.log("address area_name", this.state.area_name);
  //  console.log("lat and lng position is",position)

    // console.log("latitude values of state",this.state.lat);
    // console.log("longitude values of state", this.state.lng);
    // console.log("this values in render", this);
    return (
      <div className="map-container p-0">
   
        <Map
          google={this.props.google}
          className={"map"}
          zoom={13}
          initialCenter={this.props.center}
          fullscreenControl={false}
          streetViewControl={false}
          gestureHandling={this.props.gestureHandling}
        >
          <Circle
            radius={circleRadiusInMeters}
            center={this.props.center}
            onMouseover={() => console.log("mouseover")}
            onClick={() => console.log("click")}
            onMouseout={() => console.log("mouseout")}
            strokeColor="transparent"
            strokeOpacity={0}
            strokeWeight={5}
            fillColor="#FF0000"
            fillOpacity={0.2}
          />

          <Marker
            icon={{url: Iconmap}}
            onClick={this.onMarkerClick}
            // key={place.id}
            // place_={place}
            position={{lat:this.state.lat, lng:this.state.lng}}
           
          //position={{ lat: 12.925453, lng:77.546761}}
         // lat={this.state.lat}
        // lng={this.state.lng}
            draggable={true}
            onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
            // onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
        
        ></Marker>

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            
            <div>
              <p>{this.state.activeAddress}</p>
              
            </div>
            
          </InfoWindow>
          
        </Map>
        
      </div>
    );
  }
}
// export default Marker
// {console.log("lat and lng position is",lat)}
export default GoogleApiWrapper({
  apiKey: `${GOOGLE_MAP_API_KEY}`
})(MapView);

