import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
     
    // all my_addresss 
    get_all_my_address_req,
    get_all_my_address_err,
    get_all_my_address_success,

    add_my_address_request,
    add_my_address_success,
    add_my_address_error,

    update_my_address_details_request,
    update_my_address_details_success,
    update_my_address_details_error,

    delete_my_address_request,
    delete_my_address_success,
    delete_my_address_error,

    // city
    get_all_city_req,
    get_all_city_err,
    get_all_city_success,

    // all_area_by_city_id
    get_all_area_by_city_id_req,
    get_all_area_by_city_id_err,
    get_all_area_by_city_id_success,



    
  } from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import { 

         GET_ALL_MY_ADDRESS_REQ,
         ADD_MY_ADDRESS_REQUEST,
         DELETE_MY_ADDRESS_REQUEST,
         UPDATE_MY_ADDRESS_DETAILS_REQUEST,

        //  city
        GET_ALL_CITY_REQ,
        GET_ALL_AREA_BY_CITY_ID_REQ,

    } from './action.type';
    import toaster from "../../../utils/ReactTostify";
    import {isLoading} from '../action.loader/action.function.js';

    function* allMy_addressSaga(payload) {
      const {data,callback} =payload;
        try {
          const store = yield select();
          console.log("store values ==========", store?.user?.user?.userId)
          yield put(isLoading(true));
          let response = yield call(API.get,(API_URL.ALL_ADDRESS + store?.user?.user?.userId),API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("all my_address saga result === ", result);
          yield put(get_all_my_address_success(result));
          callback(null,result);
         
        }
        catch (error) {
          yield put(get_all_my_address_err(error));
          callback(error,null);
          yield put(isLoading(false));
        }
      }


      function* addMy_addressSaga(payload) {
        const {data,callback} =payload;
        console.log("data sent to add address  saga", data)
        try {
          // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.ADD_ADDRESS, data,API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("add address  response saga",result);
          yield put(add_my_address_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(add_my_address_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
        }
      }

      function* updateMy_addressSaga(payload) {
        const { data, callback } = payload;
    console.log("requesting update address saga=======", data);
        try {
          yield put(isLoading(true));
            const response = yield call(API.post, API_URL.UPDATE_ADDRESS, data,API.getHeaders());
            yield put(isLoading(false));
           const result  = response.data;
          console.log("update address  response saga",result);
          yield put(update_my_address_details_success(result))
            callback(null, result);
           
    
    
        } catch (error) {
          yield put(update_my_address_details_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
    
        }
    }

      function* deleteMy_addressSaga(payload) {
        const {data,callback} =payload;
        console.log("data sent to delete address saga", data)
        try {
          // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.DELETE_ADDRESS, data,API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("delete address response saga",result);
          yield put(delete_my_address_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(delete_my_address_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
        }
      }


      // city 

      function* allCitySaga(payload) {
        const {data,callback} =payload;
          try {
            // const store = yield select();
            // console.log("store values ==========", store?.user?.user?.userId)
            yield put(isLoading(true));
            let response = yield call(API.get,(API_URL.GET_ALL_CITY),API.getHeaders());
            yield put(isLoading(false));
            const result  = response.data;
            console.log("all city saga result === ", result);
            yield put(get_all_city_success(result));
            callback(null,result);
           
          }
          catch (error) {
            yield put(get_all_city_err(error));
            callback(error,null);
            yield put(isLoading(false));
          }
        }
      

    // area by city id

    function* getAllAreaByCityIdSaga(payload) {
      const { data, callback } = payload;
  console.log("requesting all areaby city id saga=======", data);
      try {
        yield put(isLoading(true));
          const response = yield call(API.post, API_URL.GET_ALL_AREA_BY_CITY_ID, data,API.getHeaders());
          yield put(isLoading(false));
         const result  = response.data;
        console.log("all areaby city id  response saga",result);
        yield put(get_all_area_by_city_id_success(result.areadata))
          callback(null, result);
         
  
  
      } catch (error) {
        yield put(get_all_area_by_city_id_err(error));
        callback(error,null);
        yield put(isLoading(false));
        toaster("error", error);
  
      }
  }
      
export default function* my_addressSaga() { 
    yield takeEvery(GET_ALL_MY_ADDRESS_REQ, allMy_addressSaga);
    yield takeEvery(ADD_MY_ADDRESS_REQUEST, addMy_addressSaga);
    yield takeEvery(DELETE_MY_ADDRESS_REQUEST, deleteMy_addressSaga);
    yield takeEvery(UPDATE_MY_ADDRESS_DETAILS_REQUEST, updateMy_addressSaga);

    yield takeEvery(GET_ALL_CITY_REQ, allCitySaga);
    yield takeEvery(GET_ALL_AREA_BY_CITY_ID_REQ, getAllAreaByCityIdSaga);
} 