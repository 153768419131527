import React, {useState,useEffect}  from 'react';
import './marginStyle.css'
import DatePicker from "react-datepicker";
function SearchFilter(props) {
  // state = {
  //   startDate: new Date()
  // };

  // handleChange = (date) => {
  //   this.setState({
  //     startDate: date
  //   });
  // };
  const [search, setSearch] = useState(null);
  // const [searchCategory, setSearchCategory] = useState(new Array(props.searchCategoryData).fill(false));
  const [checkedState, setCheckedState] = useState([]);

  useEffect(() => {
    // if(props.searchCategoryData && (props.searchCategoryData.length > 0)){
    //   setCheckedState(new Array(props.searchCategoryData).fill(false));
    // }
    //  setCheckedState(new Array(props.searchCategoryData.length).fill(false));
   
  },[]);
  
  const searchSpace = (event) => {
    let keyword = event.target.value;
    setSearch(keyword);
    props.searchFilter(keyword);
  };

  const searchCategorySpace = (filterData) => {
    // let keyword = event.target.value;

  //   const updatedCheckedState = checkedState.map((item, index) =>
  //   index === position ? !item : item
  // );
  // console.log("updated checked state== ",updatedCheckedState);
  // setCheckedState(updatedCheckedState);
    // setSearchCategory(keyword);

    // let updatedCategorySearchArr = props.searchCategoryData.map((data,idx))

    // props.searchCategoryFilter(keyword);

    if (checkedState.includes(filterData)) {
      const filterIndex = checkedState.indexOf(filterData);
      const newFilter = [...checkedState];
      newFilter.splice(filterIndex, 1);
      setCheckedState(newFilter);
      props.searchCategoryFilter(newFilter);
    } else {
      setCheckedState([...checkedState, filterData]);
      props.searchCategoryFilter([...checkedState, filterData]);
    }

  };
  console.log("updated state  checked state== ",checkedState);
  console.log("props =====",props.searchCategoryData.length);
    return (
      <div className="card search-filter">
        <div className="card-header">
          <h4 className="card-title mb-0">Search Filter</h4>
        </div>
        <div className="card-body">
          {/* <div className="filter-widget">
                                <div className="cal-icon">
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleChange}
                                    className="form-control datetimepicker"
                                />
                                </div>			
                            </div> */}

          <div className="card search-widget">
            <div className="card-body">
              <form className="search-form">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Search Test Name..."
                    className="form-control"
                    onChange={(e) => searchSpace(e)}
                  />
                  <div className="input-group-append">
                    <button type="submit" className="btn btn-primary">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="filter-widget">
            <h4>Gender</h4>
            <div>
              <label className="custom_check">
                <input type="checkbox" name="gender_type" defaultChecked />
                <span className="checkmark"></span> Male Doctor
              </label>
            </div>
            <div>
              <label className="custom_check">
                <input type="checkbox" name="gender_type" />
                <span className="checkmark"></span> Female Doctor
              </label>
            </div>
          </div> */}
          <div className="filter-widget">
            <h4>Select Category</h4>
           {(props.searchCategoryData.length > 0)? (props.searchCategoryData.map((category,idx) =>(
            <div key={idx}>
            <label className="custom_check "> 
            
                <input
                  type="checkbox"
                  name={category.cat_name}
                  id={`category-checkbox-${idx}`}
                  className="style"
                  // checked={checkedState[idx]}
                  checked={checkedState.includes(category.cat_name)}
                  onChange={() => searchCategorySpace(category.cat_name)}
                  // defaultChecked
                />
                
              <span className="checkmark"></span>  
              
               <div className="style"> 
                {(category.cat_name != null)? category.cat_name:null}  
              </div> 
                 
              </label>
            </div>
           ))):null

           }
          </div>
          {/* <div className="btn-search">
            <button type="button" className="btn btn-block">
              Search
            </button>
          </div> */}
        </div>
      </div>
    );
  
}

export default SearchFilter;
