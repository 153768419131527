import React,{ useState,useEffect } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import StickyBox from "react-sticky-box";
import DashboardSidebar from '../sidebar/sidebar.jsx';
import IMG01 from '../../../../assets/images/patients/patient.jpg';
import { API_URL_ROOT } from '../../../../redux-services/actions/constance/action.constance.js';
import { get_all_custom_notification_req,post_custom_notification_read_status_by_id_request } from "../../../../redux-services/actions/action.customnotification/action.function";
function NotificationsViewAll(props){
  // const [not_checked, setNot_checked] = React.useState(false);
  // const handleChangeReadMoreLess = () => {
  //   setChecked((prev) => !prev);
  // };

  // console.log("data sent from notification",all_customnotification);
      // notification data state
  const [customNotificationData, setCustomNotificationData] = useState([]);

  const getAllCustomNotificationApiCall = async() =>{

    await props.all_custom_notification_req(null,(error,success)=>{
      if(error){
        
        console.log("error",error);
      }    
      if(success.status == 200 ){
       { console.log("notification_value",success.customNotificationData.all_custom_notification_req.response)}
        if (success && success?.response.length > 0) {
          var filterData = [];

          success.response.map((cstm_notification, idx) =>{
            if((cstm_notification.read_status == 0)){
              filterData.push(cstm_notification);
             
            }
            console.log("notification_filter_value", filterData)
        })

          setCustomNotificationData(filterData);

        }
        if (success && success?.response.length <= 0) {
          console.log("data custom notification length empty ",success?.message);
          
        }
       }
       });

  }

  useEffect(() => {
  
    getAllCustomNotificationApiCall();
  },[])

   
  return (
    <div>
          

            <div className="content">
            <div className="container-fluid">
            <div className="row">
            <div className="col-md-5 col--lg-4 col-xl-3 theiaStickySidebar">
                        <StickyBox offsetTop={20} offsetBottom={20}>
                            <DashboardSidebar {...props}/>
                        </StickyBox>
                       </div>
                       <div className="col-md-7 col-lg-8 col-xl-9">
                       {/* <div className="card">
        <div className="card-body"> */}
        <div className="doc-review review-listing">
        <ul className="comments-list">
        {(customNotificationData.length > 0)?(
                  customNotificationData.map((cstm_notification, idx) =>(
                    <li>
                      {console.log("custum_data",cstm_notification.titless)}
                      <div className="comment">
                        <img
                          className="avatar rounded-circle"
                          alt="Notification"
                          src={(cstm_notification.image_url != null)?`${API_URL_ROOT}/${cstm_notification.image_url}`:`${IMG01}`}
                        />
                        <div className="comment-body">
                          <div className="meta-data">
                            <span className="comment-author">{((cstm_notification.title != "undefined")||(cstm_notification.title != undefined) || (cstm_notification.title != null)) ? cstm_notification.title : '--'}</span>
                            <span className="comment-date">
                            {((cstm_notification.created_at != "undefined")||(cstm_notification.created_at != undefined) || (cstm_notification.created_at != null)) ? moment(cstm_notification.created_at).fromNow() : null}
                            </span>
                            {console.log("cstm_notification.message",cstm_notification.message)}
                          </div>
                          <p className="comment-content">
                          {((cstm_notification.message != "undefined")||(cstm_notification.message != undefined) || (cstm_notification.message != null)) ? "": '--'}
                          </p>
                          {/* <Collapse in={checked} collapsedSize={10}>
                          <p className="comment-content">
                          {((dataReceived.message != "undefined")||(dataReceived.message != undefined) || (dataReceived.message != null)) ? dataReceived.message : '--'}
                          </p>
                          </Collapse>
                          <div onClick={handleChangeReadMoreLess} style={{
                                                    cursor: "pointer",
                                                }}>
                              {(checked == true)?"Read Less": "Read More"}
                          </div> */}
                          {/* <div className="comment-reply">
                            <a className="comment-btn" href="#0">
                              <i className="fas fa-reply"></i> Reply
                            </a>
                            <p className="recommend-btn">
                              <span>Recommend?</span>
                              <a href="#0" className="like-btn">
                                <i className="far fa-thumbs-up"></i> Yes
                              </a>
                              <a href="#0" className="dislike-btn">
                                <i className="far fa-thumbs-down"></i> No
                              </a>
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </li>
                   

                  ))):null}
     

      
        {/* <li>
                      <div className="comment">
                        <img
                          className="avatar rounded-circle"
                          alt="User"
                          src={IMG01}
                        />
                        <div className="comment-body">
                          <div className="meta-data">
                            <span className="comment-author">Notification Title</span>
                            <span className="comment-date">
                              Notification Date
                            </span>
                            
                          </div>
                          <p className="comment-content">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation. Curabitur non nulla sit amet
                            nisl tempus
                            Notification content
                          </p>
                          <div className="comment-reply">
                            <a className="comment-btn" href="#0">
                              <i className="fas fa-reply"></i> Reply
                            </a>
                            <p className="recommend-btn">
                              <span>Recommend?</span>
                              <a href="#0" className="like-btn">
                                <i className="far fa-thumbs-up"></i> Yes
                              </a>
                              <a href="#0" className="dislike-btn">
                                <i className="far fa-thumbs-down"></i> No
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li> */}

        </ul>
          
        </div>

        {/* </div>
        </div> */}
                       </div>       

            </div>
                
            </div>

            </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    status: state.user.status,
    error: state.user.error,
  user_by_id:state.user.user_by_id,
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn,
    requesting: state.user.requesting,
  };

  };

const mapDispatchToProps = (dispatch) => {return{
    all_custom_notification_req: (data,callback) =>dispatch(get_all_custom_notification_req(data,callback)),
    custom_notification_read_status_by_id_request: (data,callback) =>dispatch(post_custom_notification_read_status_by_id_request(data,callback)),
}}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsViewAll);