//ADD TO_CART
export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_ERROR = "ADD_TO_CART_ERROR";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";

//DELETE FROM_CART
export const DELETE_FROM_CART_REQUEST = "DELETE_FROM_CART_REQUEST";
export const DELETE_FROM_CART_ERROR = "DELETE_FROM_CART_ERROR";
export const DELETE_FROM_CART_SUCCESS = "DELETE_FROM_CART_SUCCESS";

// REFRESH CART

export const REFRESH_CART_REQUEST = "REFRESH_CART_REQUEST";
export const REFRESH_CART_ERROR = "REFRESH_CART_ERROR";
export const REFRESH_CART_SUCCESS = "REFRESH_CART_SUCCESS";
