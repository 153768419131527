import * as constance from "../constance/action.constance";
import { 
 // CREATE SUPPORT
 CREATE_SUPPORT_SUCCESS,
 CREATE_SUPPORT_REQUEST,
 CREATE_SUPPORT_ERROR,

//  GET ALL SUPPORT
  GET_ALL_SUPPORT_REQ ,
  GET_ALL_SUPPORT_ERR,
  GET_ALL_SUPPORT_SUCC,

    
// get support conversation by ticket id 
 GET_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ, 
 GET_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR, 
 GET_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS, 


// CREATE support conversation by ticket id 
 CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ,
 CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR,
 CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS,

} from "./action.type";


// GET ALL SUPPORT

export function get_all_support_req(data,callback) {
    return {
      type:GET_ALL_SUPPORT_REQ  ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_support_err(error) {
    return {
      type: GET_ALL_SUPPORT_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_support_success(result) {
    return {
      type: GET_ALL_SUPPORT_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }
  
  
  //CREATE SUPPORT
  export function create_support_success(result) {
      return {
        type: CREATE_SUPPORT_SUCCESS,
        result,
        status: constance.SUCCESS,
      };
    }
    export function create_support_request(data,callback) {
      return {
        type: CREATE_SUPPORT_REQUEST,
        data,
        callback,
        status: constance.REQUESTING,
      };
    }
    export function create_support_error(error) {
      return {
        type:CREATE_SUPPORT_ERROR,
        error: error,
        status: constance.ERROR,
      };
    }
    

    
// CREATE support conversation by ticket id  --- 

export function create_support_conversation_by_ticket_id_success(result) {
  return {
    type: CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS,
     result,
    status: constance.SUCCESS,
  };
}
export function create_support_conversation_by_ticket_id_request(data,callback) {
  return {
    type: CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function create_support_conversation_by_ticket_id_error(error) {
  return {
    type: CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR,
    error: error,
    status: constance.ERROR,
  };
}



// get support conversation by ticket id  -- start ---
export const get_support_conversation_by_ticket_id_req = (data,callback) => ({
  type: GET_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ,
  data,
  callback,
  status: constance.REQUESTING,
});

export const get_support_conversation_by_ticket_id_fail = (error) => ({
  type: GET_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR,
  error: error,
  status: constance.ERROR,
});

export const get_support_conversation_by_ticket_id_success = (result) => ({
  type: GET_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS,
  result,
  status: constance.SUCCESS,
});
