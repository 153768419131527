import React, {useEffect,useState } from "react";
import { Rating } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useFormik,} from "formik";
import * as Yup from "yup";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DashboardSidebar from '../../sidebar/sidebar.jsx';
import { Row, Col } from "reactstrap";
import { Tab, Tabs } from "react-bootstrap";
import StickyBox from "react-sticky-box";
import feedbackImg from "../../../../../assets/images/feedback.png";
import {create_feedback_request,get_all_feedback_question_req} from '../../../../../redux-services/actions/action.feedback/action.function';
import toaster from "../../../../../utils/ReactTostify";
function FeedbackView(props) {
  const [ratingVal, setRatingVal] = React.useState([]);
  const [feedbackQuestion, setFeedbackQuestion] = React.useState([]);
  const [noFeedbackQuestion, setNoFeedbackQuestion] = useState("");
    console.log("feedback list", props.history.location.state?.data.orderdata);
    const orderData =  props.history.location.state?.data.orderdata;


    const handleBack = () => {
      props.history.goBack();
    };
    const getFeedbackQuestionApiCall = async() =>{
    
      await props.get_all_feedback_question(null,(error,success)=>{
       if(error){
         
         console.log("error",error);
       }    
       if(success.status == 200 ){
         if (success && success?.response.length > 0) {
          setFeedbackQuestion(success?.response);
         }
         if (success && success?.response.length <= 0) {
           console.log("data length empty",success?.message);
           setNoFeedbackQuestion(success?.message);
         }
        }
        });
       
   
     }
    useEffect(() => {
       getFeedbackQuestionApiCall();
  }, []);

  const formik = useFormik({
    initialValues: {
      user_rating: []
    },
    validationSchema: Yup.object({
      user_rating: Yup.array().of(
        Yup.object().shape({
            feedbackid:Yup.string(),
            rating: Yup.string()
        })
      )
    }),
    onSubmit: async (values) => {
      var dt = formik.values;

      var data = {

        "orderid":((orderData != null) || (orderData != undefined))?`${orderData.orderid}`:undefined,
        "user_rating":ratingVal  
      };
      // api calls for create_feedback
      await props.create_feedback(data, (error, success) => {
        if (error) {
          console.log("error", error);
          
        }
        if (success.status == 200) {

          toaster('success',success.message);
          handleBack();
          }

        
      });
    },
    onReset: (values) => {}
  });
    return (
        <div className="content">
          <div className="container-fluid">
         <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <DashboardSidebar {...props}/>
                </StickyBox>
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9">
              <Row className="mb-3">
                    <Col xs="6" sm="12" lg="4">
          <div className="bg-white shadow rounded pb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Order ID </strong>
              </div>
            </div>
            <div className="titleName p-2">
            {((orderData != null) || (orderData != undefined))?`${orderData.orderid}`:'--'}
            </div>
            </div>

          </Col>
                    </Row>

                <div className="card">
                <div className="card-body user-tabs">
                  <div className="row">
                    
                    <div className="mr-auto p-1">
                  <strong> Feedback </strong>
                    </div>
                    </div>

                    <form onSubmit={formik.handleSubmit}>  
                    {(feedbackQuestion.length > 0)
                    ? feedbackQuestion.map(
                        (
                          {feedbackid, question},
                          idx
                        ) => (
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <div
                              key={idx}
                              className=""
                              id={feedbackid != null ? feedbackid : null}
                            >
                              {/* <div className="titleName p-1">
                                <strong>
                                  {" "}
                                  <h4>
                                    {Question != null ? Question : "null"}
                                  </h4>
                                </strong>
                              </div> */}

                              <Typography component="legend">{question != null ? question : null}</Typography>

                              <Rating
                                name={
                                  feedbackid != null ? `simple-controlled ${feedbackid}` : null
                                }
                                id={feedbackid != null ? feedbackid : null}
                                defaultValue={0}
                                precision={1}
                                max={5}
                                onBlur={(event) => {
                                  setRatingVal([
                                    ...ratingVal,
                                    {
                                      feedbackid: feedbackid.toString(),
                                      rating: event.target.value
                                    }
                                  ]);
                                }}
                                
                              />
                            </div>
                            </Box>
                        )
                      )
                    : (
                      <div className ="text-center">
                      <strong> {noFeedbackQuestion} </strong>
                        
                      </div>
                    )}
                    <div className="submit-section">
                      <button type="submit" className="btn btn-primary submit-btn">Submit</button>
                  </div>
                    </form>
                  {/* <form>  


                    <Box component="fieldset" mb={3} borderColor="transparent">
         
          <Rating
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            />
            </Box>
  
            <div className="submit-section">
                      <button type="submit" className="btn btn-primary submit-btn">Submit</button>
                  </div>
  
              </form> */}
                  </div> 
                </div>
              </div>
            </div> 
          </div>
        </div>
      );
    }
    const mapDispatchToProps = (dispatch) => {
      return {
        
          create_feedback: (data,callback) =>dispatch(create_feedback_request(data,callback)),
          get_all_feedback_question: (data,callback) =>dispatch(get_all_feedback_question_req(data,callback)),
      };
      };
      const mapStateToProps = (state) => {
      return {
        status: state.user.status,
        error: state.user.error,
        user_by_id:state.user.user_by_id,
        user: state.user.user,
        isLoggedIn: state.user.isLoggedIn,
        requesting: state.user.requesting,
      
      
      };
      };
      export default connect(mapStateToProps, mapDispatchToProps)(FeedbackView);
