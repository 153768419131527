import React,{ useState,useEffect } from 'react'
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import DashboardSidebar from '../../sidebar/sidebar.jsx';
import packagesImg from '../../../../../assets/images/packages.png';
import testsImg from '../../../../../assets/images/tests.png';
import StickyBox from "react-sticky-box";
import {get_order_details_by_order_id_req, delete_order_by_order_id_request} from "../../../../../redux-services/actions/action.order/action.function.js";
import {statusConstants} from "../../../../../utils/constants";
import toaster from "../../../../../utils/ReactTostify";
import {API_URL_ROOT} from '../../../../../redux-services/actions/constance/action.constance.js'
function OrderView(props) {
  const body ='Please provide the valuable inputs';
  const title = "Are you sure you want to delete this order?"
    const orderData =  props.history.location.state?.data.orderdata;
    
    const [orderDetails, setOrderDetails] = useState([]);
    const [packageDetails, setPackageDetails] = useState([]);
    const [testDetails, setTestDetails] = useState([]);
    const [uploadPrescriptionDetails, setUploadPrescriptionDetails] = useState([]);

    const [orderId, setOrderId] = useState(null);
    const [patientName, setPatientName] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [status, setStatus] = useState(null);
    const [totalAmount, setTotalAmount] = useState(null);
    const [address, setAddress] = useState(null);
    const [inAppOtp, setInAppOtp] = useState(null);
    const [reportLink, setReport] = useState(null);

    const [alert, setAlert] = useState(false);
    const handleBack = () => {
        props.history.goBack();
      };


      const getOrderDtailsApiCall = async() =>{

        var data = {
            "orderid":`${orderData.orderid}`
        }
       await props.get_order_details_by_order_id(data,(error,success)=>{
        if(error){
          
          console.log("error",error);
        }    
        if(success.status == 200 ){
            console.log("success reponse of the order by order id",success?.order_details[0].readyReports);
          if (success?.order_details.length > 0) {
            setReport(success?.order_details[0].reportUrl)
             setOrderDetails(success?.order_details);
             setOrderId(success?.order_details[0].orderid);
             setPatientName(`${success?.order_details[0].patient_first_name} ${success?.order_details[0].patient_last_name}`);
             setPaymentMethod(success?.order_details[0].payment_method);  
             setStatus(success?.order_details[0].status);
             setTotalAmount(success?.order_details[0].totalcost);
             setAddress(success?.order_details[0].address);
             setInAppOtp(success?.order_details[0].inAppOtp);
          }
          if (success?.pakage_details.length > 0) {
            setPackageDetails(success?.pakage_details);  
         }
         if (success?.test_details.length > 0) {
            setTestDetails(success?.test_details);  
         }
         if (success?.upload_prescription_details.length > 0) {
            setUploadPrescriptionDetails(success?.upload_prescription_details);  
         }
          
         }
         });
        
    }

      useEffect(() => {
        getOrderDtailsApiCall();
      }, []);


      const downloadReport = () => {

        const downloadUrl = reportLink; 
        
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'report.pdf'); 
        document.body.appendChild(link);
        link.click();
      };
    

      const handle_delete =  () => {
       
     
        const getAlert = () => (
          <SweetAlert
        
            showCancel
         
            title={title}
            onConfirm={() => hideAlert(orderData.orderid)}
            onCancel={() =>
            setAlert(null)
            }
            focusCancelBtn
            customButtons={
          <React.Fragment>
            <button  type="button"
                      className="btn btn-primary mr-3" 
                      onClick={() => hideAlert(orderData.orderid)}
                      >Yes</button>
            <button  type="button"
                      className="btn btn-danger"
                      onClick={() => setAlert(null)}
                      >No</button>
          </React.Fragment>
        }
          >
           
          </SweetAlert>
        );
        setAlert(getAlert());
    
         
        
        
      };

      const hideAlert = async (id) => {

        if (id) {
          console.log(`orderid: ${id} `);
          let data = {
            "orderid":id
          }
          console.log(`orderid : ${data.orderid} `);
           await props.delete_order_by_order_id(data,(error,success)=>{
            if(error){
              
              console.log("error",error);
            }    
            if(success.status == 200 ){
              toaster('success', success.message);
              handleBack();
             }
             });
       
          
          
        }
        console.log("Hiding alert...");
        // this.setState({
        //   alert: null,
        // });
        setAlert(null);
      };
      console.log("status value of the order",status);
    return (
        <div>
        {alert ? alert : null}
        <div className="breadcrumb-bar">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/home">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Order View</li>
                        </ol>
                    </nav>
                    <h2 className="breadcrumb-title">Order</h2>
                </div>
            </div>
        </div>
    </div>
    <div className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-5 col--lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                    <DashboardSidebar {...props}/>
                </StickyBox>
               </div>

               <div className="col-md-7 col-lg-8 col-xl-9">
<div className="card">
<div className="card-body">
<Row className="mb-2">

<Col xs="6" sm="12" lg="3">
          <div className="bg-white shadow rounded pb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Order ID </strong>
              </div>
            </div>
            <div className="titleName p-2">
            

            {((orderId != null) || (orderId != undefined))?`${orderId}`:'--'}
            </div>
            </div>

          </Col>

          <Col xs="6" sm="12" lg="3" >
            <div className="bg-white shadow rounded pb-3">            
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Patient Name </strong>
              </div>
            </div>
            <div className="titleName p-2">
            {((patientName != null) || (patientName != undefined))?`${patientName}`:'--'}
            </div>

            </div>
          </Col>

          <Col xs="6" sm="12" lg="3">
          <div className="bg-white shadow rounded pb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Status </strong>
              </div>
            </div>
           

            {console.log("status value",statusConstants)}
            <div className={(status == statusConstants.raised) ? "text-primary p-2" : (status == statusConstants.assigned) ? "text-secondary p-2":(status == statusConstants.processed) ? "text-secondary p-2": (status == statusConstants.closed) ? "text-success p-2": (status == statusConstants.declined)? "text-warning p-2": "text-danger p-2"}>
            <strong>
              {status != null
                ? status == statusConstants.raised
                  ? "Raised"
                  : status ==statusConstants.assigned
                  ? "Technician Assigned"
                  : status == statusConstants.processed
                  ? "Processed"
                  : status == statusConstants.closed
                  ? "Completed"
                  : status == statusConstants.declined
                  ? "Declined"
                  : "Cancelled"
                : "--"}
            </strong>
          </div>
            </div>

          </Col>
          <Col xs="6" sm="12" lg="3">
          <div className="bg-white shadow rounded pb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Report </strong>
              </div>
            </div>
           

            {console.log("status value",statusConstants)}
   

{status === statusConstants.closed ? (
   <div className="d-flex justify-content-center">
   <button
    type="button" 
    className="btn btn-primary btn-sm"
    onClick={downloadReport}
    >download Report</button>
</div>
) : (
    <p>No Report</p>
)}
            </div>

          </Col>

      
          </Row>
  

          <Row className="mb-2">
          <Col xs="6" sm="12" lg="12">
          <div className="bg-white shadow rounded pb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Order Details </strong>
              </div>
            </div>
            {
                (packageDetails.length > 0)?packageDetails.map((packageDetail,idx) =>(
                    <Row className="ml-2 mb-2" key={idx}>
                    <Col xs="4" sm="4" lg="2">
                    <div className="">
                    <img src={packagesImg} alt="package" className="rounded-circle" />
                    </div>  
                    </Col>
                    <Col xs="10" sm="10" lg="6">
                    <div className='my-auto'>
                        <strong>
                        {((packageDetail.package_name != null) || (packageDetail.package_name != undefined))?`${packageDetail.package_name}`:'--'}
                            
                        </strong>
                        <p>
                        {((packageDetail.package_cost != null) || (packageDetail.package_cost != undefined))?`${packageDetail.package_cost}`:'--'}
                        
                        </p>
                    </div>
                    </Col>
                    </Row>
                )):null
            }

            {
                (testDetails.length > 0)?testDetails.map((testDetail,idx) =>(
                    <Row className="ml-2 mb-2" key={idx}>
                    <Col xs="4" sm="4" lg="2">
                    <div className="">
                    <img src={testsImg} alt="test" className="rounded-circle" />
                    </div>  
                    </Col>
                    <Col xs="10" sm="10" lg="6">
                    <div className='my-auto'>
                        <strong>
                        {((testDetail.servicename != null) || (testDetail.servicename != undefined))?`${testDetail.servicename}`:'--'}  
                        </strong>
                        <p>
                        {((testDetail.service_cost != null) || (testDetail.service_cost != undefined))?`${testDetail.service_cost}`:'--'}  
                        </p>
                    </div>
                    </Col>
                    </Row>
                )):null
            }
   
            </div>

          </Col>

          </Row>

          <Row className="mb-2">
          <Col xs="6" sm="12" lg="12">
          <div className="bg-white shadow rounded pb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Address </strong>
              </div>
            </div>
            <div className="titleName p-2">
            

            {((address != null) || (address != undefined))?`${address}`:'--'}
            </div>
            </div>

          </Col>
          </Row>

          <Row className="mb-4">
         
          <Col xs="6" sm="12" lg="4" >
            <div className="bg-white shadow rounded pb-3">            
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Total Amount </strong>
              </div>
            </div>
            <div className="titleName p-2">
            {((totalAmount != null) || (totalAmount != undefined))?`${totalAmount}`:'--'}
            </div>

            </div>
          </Col>

          <Col xs="6" sm="12" lg="4">
          <div className="bg-white shadow rounded pb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> Payment Method </strong>
              </div>
            </div>
            <div className="titleName p-2">
            {((paymentMethod != null) || (paymentMethod != undefined))?`${paymentMethod}`:'--'}
            </div>
            </div>

          </Col>

          {(status != "closed")? (
            <Col xs="6" sm="12" lg="4">
          <div className="bg-white shadow rounded pb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="titleNameSecondary p-2">
                <strong> OTP for Technician </strong>
              </div>
            </div>
            <div className="titleName p-2">
            {((inAppOtp != null) || (inAppOtp != undefined))?`${inAppOtp}`:'--'}
            </div>
            </div>

          </Col>
):null}
          </Row>
          
         

          

         
  
 
  
        <div className="row">
            
{((status == "closed") || (status == "cancelled")|| (status == "processed"))? null:(
  <div className="submit-section mr-3">
                    <button type="button" className="btn btn-primary submit-btn"  
                    onClick={() =>handle_delete()}
                    >Cancel Order</button>
                </div>
)}
 

            <div className="submit-section">
                    <button type="button" className="btn btn-primary submit-btn"  onClick={handleBack}>Back</button>
                </div>
        </div>
      
 
   
    
</div>
</div>
</div>
            </div>
        </div>
    </div>      
    </div>
);
        }


        const mapDispatchToProps = (dispatch) => {
            return {
 
                get_order_details_by_order_id: (data,callback) =>dispatch(get_order_details_by_order_id_req(data,callback)),
                delete_order_by_order_id: (data,callback) =>dispatch(delete_order_by_order_id_request(data,callback))
            };
            };
            const mapStateToProps = (state) => {
            return {
              status: state.user.status,
              error: state.user.error,
              user_by_id:state.user.user_by_id,
              user: state.user.user,
              isLoggedIn: state.user.isLoggedIn,
              requesting: state.user.requesting,
            
            
            };
            };
export default connect(mapStateToProps, mapDispatchToProps)(OrderView);
