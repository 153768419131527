//create Book on call 
export const CREATE_BOOK_ON_CALL_REQUEST = "CREATE_BOOK_ON_CALL_REQUEST";
export const CREATE_BOOK_ON_CALL_ERROR = "CREATE_BOOK_ON_CALL_ERROR";
export const CREATE_BOOK_ON_CALL_SUCCESS = "CREATE_BOOK_ON_CALL_SUCCESS";


//Get  all prescriptions
export const GET_ALL_PRESCRIPTIONS_REQ = "GET_ALL_PRESCRIPTIONS_REQ";
export const GET_ALL_PRESCRIPTIONS_ERR = "GET_ALL_PRESCRIPTIONS_ERR";
export const GET_ALL_PRESCRIPTIONS_SUCC = "GET_ALL_PRESCRIPTIONS_SUCC";

//upload prescription
export const UPLOAD_PRESCRIPTION_REQ = "UPLOAD_PRESCRIPTION_REQ";
export const UPLOAD_PRESCRIPTION_ERR = "UPLOAD_PRESCRIPTION_ERR";
export const UPLOAD_PRESCRIPTION_SUCC = "UPLOAD_PRESCRIPTION_SUCC";