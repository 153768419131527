import React, {useState,useEffect} from 'react';
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import {useHistory} from "react-router";
import doctor1 from "../../../assets/images/doctors/doctor-01.jpg";
import packageImg from "../../../assets/images/images/img-01.jpg"
import doctor2 from "../../../assets/images/doctors/doctor-02.jpg";
import doctor3 from "../../../assets/images/doctors/doctor-03.jpg";
import doctor4 from "../../../assets/images/doctors/doctor-04.jpg";
import doctor5 from "../../../assets/images/doctors/doctor-05.jpg";
import doctor6 from "../../../assets/images/doctors/doctor-06.jpg";
import doctor7 from "../../../assets/images/doctors/doctor-07.jpg";
import doctor8 from "../../../assets/images/doctors/doctor-08.jpg";
import {get_all_package_req, get_all_popular_package_req} from "../../../redux-services/actions/action.packages/action.function.js"
function OurPackages(props){
    useEffect(() => {
    //     const { get_all_packages } =props;
    //     get_all_packages();
    //      const all_packages = props.all_packages;
    //   console.log("gen_table packages =====",all_packages);
    const { get_all_popular_packages } =props;
        get_all_popular_packages();
         const all_popular_packages = props.all_popular_packages;
      console.log("gen_table packages =====",all_popular_packages);
      },[]);
      const history = useHistory();
      const nextPath = (path,data) => {
        history.push({
          pathname: path,
          state:data
        });
      };
      const handle_details = (data) =>{
        nextPath(`/home/allPackagesView/packageDetails`,data);
      }
    
        const settings = {
            dots:false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            centerPadding: '10px',
            arrows: true,
            autoplay: true,
      autoplaySpeed: 500,
      adaptiveHeight: true,
      pauseOnHover: true,
      
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      infinite: true,
                    //   dots: true
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      initialSlide: 2,
                      infinite: true,
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                    }
                  }
            ]
          };
        return(
            <>
                {(props.all_popular_packages?.response.length == 0)?null:(
                    <section className="doctors-col">
            <div className="container">					
                <div className="row justify-content-center">	
                    <div className="doctors-title text-center">					
                        <h6>Packages We Offer</h6>
                        <h2>Popular Packages</h2>
                    </div>
                </div>
               <div className="row">
                    <div className="col-lg-12">
                        <div className="doctor-slider slider slick-initialized slick-slider">
                            
                        <Slider {...settings}>
                        {(props.all_popular_packages && (props.all_popular_packages?.response.length > 0)) ? (props.all_popular_packages?.response.map((packages,idx) =>(
                            <div className="profile-widget" key={idx}>
                                <div className="doc-img">
                                    <div onClick={() => handle_details(packages)} style={{
                                                    cursor: "pointer",
                                                }}>
                                        <img className="img-fluid" alt="User Image" src={packageImg} />
                                    </div>
                                </div>
                                <div className="pro-content">
                                    <h3 className="title" onClick={() => handle_details(packages)} style={{
                                                    cursor: "pointer",
                                                }}>
                                        {/* <a href="doctor-profile.html">Denby Cathey</a>  */}
                                        {packages.package_name}
                                    </h3>
                                    <p className="speciality" onClick={() => handle_details(packages)} style={{
                                                    cursor: "pointer",
                                                }}>{(packages.short_description != null)? ((packages.short_description.length > 30)?`${packages.short_description.substring(0,30)}...`:packages.short_description):"None"}</p>
                                    
                                    {/* <div className="rating">
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star"></i>
                                        <span className="d-inline-block average-rating">(66)</span>
                                    </div> */}
                                    <ul className="available-info">
                                        <li>
                                            <i className="fas fa-wallet"></i> {(packages.package_cost != null)? `₹ ${packages.package_cost}`:"None"}
                                        </li>
                                    </ul>
                                </div>									
                                <div className="pro-footer" onClick={() => handle_details(packages)} style={{
                                                    cursor: "pointer",
                                                }}>
                                    <ul className="policy-menu text-center">
                                        <li onClick={() => handle_details(packages)} style={{
                                                    cursor: "pointer",
                                                }}>Book Now</li>
                                    </ul>
                                </div>
                            </div>))):null} 
                            
                            </Slider>
                            
                        </div>
                        <div className="col-12 col-md-12 text-center">
                          <Link to="/home/allPackagesView/allPackages" className="view-all">View All </Link>
                            
                        </div>
                    </div>
               </div>
            </div>
        </section>
                )}
            </>
           
        );
 
}

const mapDispatchToProps = (dispatch) => {
	return {
		// get_all_packages: () =>dispatch(get_all_package_req()),
        get_all_popular_packages: () =>dispatch(get_all_popular_package_req()),
	};
  };
  const mapStateToProps = (state) => {
	return {
	  status: state.user.status,
	  error: state.user.error,
      all_popular_packages: state.packages.all_popular_packages,
      all_packages: state.packages.all_packages,
	  user: state.user.user,
	  isLoggedIn: state.user.isLoggedIn,
	  requesting: state.user.requesting,
  
  
	};
  };
  export default connect(mapStateToProps, mapDispatchToProps)(OurPackages);
	
    