import React, { Component } from 'react'
import { Link } from 'react-router-dom';
class AboutContent extends Component {
    render() {
        return (
    <div>
        <div className="breadcrumb-bar">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-12 col-12">
                        <nav aria-label="breadcrumb" className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/home">Home/About us</Link></li>
                                {/* <li className="breadcrumb-item active" aria-current="page">Blog</li> */}
                            </ol>
                        </nav>
                        <h2 className="breadcrumb-title">About us</h2>
                    </div>
                </div>
            </div>
        </div>
        <section className="aboutus-col">
            <div className="container">
                <div className="row">
                    {/* <div className="col-6 col-lg-12">
                        <div className="rounded float-right">
                            <img src={require('../../../assets/images/doctors/homedirector.jpg')}  alt="" />
                            <h1>From The Managing Director’s Desk</h1>
                                <p>Mrs. Shruthi Ashwath</p>
                                
                        </div>
                    </div> */}
                    <div className="col-12 col-lg-12">
                        <div className="about-right">

                            {/* <h6>Caring for diagnostics</h6> */}
                            <h2 className='text-primary'>Company Profile</h2>
                           <p>Offering complete set of laboratory and imaging services, Padmashree Diagnostics is one of the leading diagnostic centers in Bengaluru. Launched in the year 2000, we are a trusted diagnostics partner to all leading hospitals and independent doctors in Bengaluru.
                          Our founder, Dr. C. N Ashwathnarayan, believed in providing preventive healthcare and state of the art Diagnostic facilities for Diagnosis and treatment at affordable price is the key to help millions of people to lead better and healthier lives. Named after his mother Smt. A.L. Padmamma, an inspiration for him to study medicine. Padmashree Diagnostics brings the best of the services at prices that a common man can afford to.</p>
                        </div>
                        <div className="col-6 col-lg-12">
                        <div className="about-right">

                             <div className="col-6 col-lg-12">
                        <div className="rounded float-right">
                            <img className='photoproperty' src={require('../../../assets/images/doctors/homedirector.jpg')}  alt="" />
                            <h6>Mrs. Shruthi Ashwath</h6>
                
                                
                        </div>
                    </div> 
                            <h1 className='text-primary'>From The Managing Director’s Desk</h1>
                            <ul>
                         <li><p>
                         At Padmashree, our passion to the well-being of the community we live in, fuels our day-to-day work. Each one of us are driven to help community members, our largest stakeholders to be their personal best and lead a healthier life.
                         </p>
                         </li>
                        <li>
                         <p>
                         In almost two decades of our existence, we have set a new benchmark for excellence in preventive healthcare. We understand the importance of a diagnostic center in timely detection and diagnosis of health ailments, thus have partnered with leading hospitals and consultants in the city to offer our people superior service.
                         </p>
                         </li>
                         <li>
                         <p>
                         We believe that access to best healthcare services is a universal right and no citizen be denied of it. It has been our constant endeavor to offer a diagnostic center with state-of-the-art infrastructure to meet the needs of the poor and lower income group of the society. Our patient-centric approach to healthcare has won us constant support, appreciation of our customers and that of the medical fraternity in the country.
                         </p>
                         </li>
                         </ul>
                        </div>
                    </div>
                      
                    </div>
                    <div className="col-6 col-lg-12">
                        <div className="about-right">

                             <div className="col-6 col-lg-12">
                        <div className="rounded float-right">
                            <img  className='photoproperty_1' src={require('../../../assets/images/doctors/Rajesh-Shenoy (1).jpg')}  alt="" />
                         
                            <h6>Prof. R. RAJESH SHENOY</h6>
                
                                
                        </div>
                    </div> 
                            <h1 className='text-primary'>From The Lab Director</h1>
                            <ul>
                         <li><p>
                         “Padmashree Diagnostics” a modern, state of the art facility in heart of Vijayanagar.
                         </p>
                         </li>
                        <li>
                         <p>
                         We have been serving the people from 19 years with a vision to help and serve its community, in a better way.
                         </p>
                         </li>
                         <li>
                         <p>
                         While Padmashree has retained its mainstay of unbiased service to all, it has also made a concentrated effort to better its services by adding on new facilities and upgrading the existing ones.
                         </p>
                         </li>
                         </ul>
                        </div>
                    </div>

                    
                    <div className="col-12 col-lg-12">
                        <div className="about-right">
                            <h1 className='text-primary'>Why us</h1>
                            <ul>
                         <li><p>
                         We bring you the best of modern technologies and well trained staff to cater to all your needs. Superior technology enables test results be available quicker and reliable.
                         </p>
                         </li>
                        <li>
                         <p>
                         Since 2009, we are accredited by the National Accreditation Board for Testing and Calibration Laboratories (NABL), meaning, our results meet all the quality standards set by the national board.
                         </p>
                         </li>
                         <li>
                         <p>
                         With each passing day, our commitment to the health and well-being of the local community has grown only stronger. Over the last 19 years, we have been early adopters of technology and pioneering high quality service to the community in and around Vijayanagar, Bengaluru.
                         </p>
                         </li>
                         <li>
                         <p>
                         Your one stop solution to all your testing needs right from regular blood test to any radiology and imaging services.
                         </p>
                         </li>
                         <li>
                         <p>
                         Our Clinical Laboratory has over 40 trained staff such as Pathologists, Clinical Biochemists, Microbiologists, Physicians, medical technologists and technicians, Histotechnologists, Cytotechnologists, Phlebotomists, lab and computer assistants that collaborate to live our vision of providing affordable healthcare to one and all
                         </p>
                         </li>
                         </ul>
                        </div>
                    </div>
                    {/* <section className="top-service-col">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="service-box">
                  <div className="card">
                    <div className="card-body">
                      <div className="service-icon">
                        <img src={require('../../../assets/images/service1.jpg')} alt="" />
                      </div>
                      <h3>Service 1</h3>
                      <p> Service 1 </p>
                      <div className="service-btn">
                        <a href="#"><i className="fas fa-chevron-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="service-box">
                  <div className="card">
                    <div className="card-body">
                      <div className="service-icon">
                        <img src={require('../../../assets/images/service.jpg')} alt="" />
                      </div>
                      <h3>Service 2</h3>
                      <p>Service 2 </p>
                      <div className="service-btn">
                        <a href="#"><i className="fas fa-chevron-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="service-box">
                  <div className="card">
                    <div className="card-body">
                      <div className="service-icon">
                        <img src={service3} alt="" />
                      </div>
                      <h3>Service 3</h3>
                      <p>Service 3</p>
                      <div className="service-btn">
                        <a href="#"><i className="fas fa-chevron-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
       
                    <div className="col-6 col-lg-12">
                        <div className="about-right">

                             <div className="col-6 col-lg-12">
                        <div className="rounded float-right">
                            <img  className='photoproperty_2' src={require('../../../assets/images/doctors/home-certificate.jpg')}  alt="" />
                      
                
                                
                        </div>
                    </div> 
                            <h1 className='text-primary'>Certificate of Accreditation</h1>
                            <ul>
                         <li><p>
                         NABL stands for National Accreditation Board for Testing And Calibration Laboratories. NABL has agreements with ILAC (International Laboratory Accreditation Conference) and APLAC (Asia Pacific Laboratory Accreditation Cooperation). These are especially valuable for International recognition and mutual acceptance of test results. In short accreditation has worldwide acceptance.                        
                          </p>
                         </li>
                        <li>
                         <p>
                         NABL follows ISO 15189:2012, which is specific ISO followed worldwide for medical laboratories. This standard is used by APLAC (Asia Pacific Laboratory Accreditation Cooperation) and ILAC (International Laboratory Accreditation Co-operation) etc.                        
                          </p>
                         </li>
                         <li>
                         <p>
                         This means that an NABL accredited laboratory in India follows the same guidelines as any other accredited laboratory in the world.                        
                          </p>
                         </li>
                         <li>
                         <p>
                         NABL accreditation is based on evaluation of technical competence whereas ISO certification is based upon verification of proper documentation. Though documentation forms an essential and important part of NABL, it lays down great emphasis on quality and the lab can be denied accreditation if one does not comply with the quality standards.                       
                          </p>
                         </li>
                         <li>
                         <p>
                         It means that our lab is continuously striving for quality and this will further progress our cause of providing quality services. Laboratory Accreditation provides formal recognition of competent laboratories like ours, thus providing a ready means for customers to find reliable testing services in order to meet the demands.                         
                          </p>
                         </li>
                         </ul>
                        </div>
                    </div>

                    
                </div>
            </div>
        </section>
    </div>
        )
    }
}

export default AboutContent
