import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
     
    // all packages 
    get_all_package_req,
    get_all_package_err,
    get_all_package_success,
    get_all_popular_package_err,
    get_all_popular_package_success,

    
  } from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import { 
         GET_ALL_PACKAGE_REQ,
 GET_ALL_PACKAGE_ERR,
 GET_ALL_PACKAGE_SUCC,
 GET_ALL_POPULAR_PACKAGE_REQ
    } from './action.type';
    import {isLoading} from '../action.loader/action.function.js';

    // function* allPackageSaga() {
    //     try {
           
    //       yield put(isLoading(true));
    //       // let Login_Api_Call = API.post(API_URL.LOGIN)
    //       let response = yield call(API.get,API_URL.ALL_PACKAGE);
    //       //console.log("resposne saga --------------",response);
    //       yield put(isLoading(false));
    //       const result  = response.data;
    //       console.log("all packages === ", result);
    //       yield put(get_all_package_success(result));
    //       // yield put(addTokenToState());
         
    //     }
    //     catch (error) {
    //       yield put(isLoading(false));
    //       yield put(get_all_package_err(error));
        
    //     }
    //   }


    function* allPackageSaga(payload) {
      const {data,callback} = payload;
      try {
         
        yield put(isLoading(true));
        // let Login_Api_Call = API.post(API_URL.LOGIN)
        let response = yield call(API.get,API_URL.ALL_PACKAGE);
        //console.log("resposne saga --------------",response);
        yield put(isLoading(false));
        const result  = response.data;
        console.log("all packages === ", result);
        yield put(get_all_package_success(result));
        // yield put(addTokenToState());
        callback(null,result);
      }
      catch (error) {
        yield put(isLoading(false));
        callback(error,null);
        yield put(get_all_package_err(error));
      }
    }


      function* allPopularPackageSaga() {
        try {
           
          yield put(isLoading(true));
          // let Login_Api_Call = API.post(API_URL.LOGIN)
          let response = yield call(API.get,API_URL.ALL_POPULAR_PACKAGE);
          //console.log("resposne saga --------------",response);
          yield put(isLoading(false));
          const result  = response.data;
          console.log("all popular packages === ", result);
          yield put(get_all_popular_package_success(result));
          // yield put(addTokenToState());
         
        }
        catch (error) {
          yield put(isLoading(false));
          yield put(get_all_popular_package_err(error));
        }
      }

      
export default function* packageSaga() {
  
    yield takeEvery(GET_ALL_PACKAGE_REQ, allPackageSaga);
    yield takeEvery(GET_ALL_POPULAR_PACKAGE_REQ, allPopularPackageSaga);
    
   


}