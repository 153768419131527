import React from 'react';
import ReactDOM from 'react-dom';

import AppRouter from './approuter';
import Loader from "../src/client/utils/loader.js"
import { Provider } from "react-redux";
import { store, persistor } from "./client/redux-services/store/create.store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
 // boostrap
 import 'bootstrap/dist/css/bootstrap.min.css';
 //fontawesome
 import '../node_modules/font-awesome/css/font-awesome.min.css'; 
 import 'react-image-lightbox/style.css';
 import "react-datepicker/dist/react-datepicker.css";
 //carousel
 import "slick-carousel/slick/slick.css"; 
 import "slick-carousel/slick/slick-theme.css";
 import 'react-toastify/dist/ReactToastify.css';
 

// import * as serviceWorker from './client/serviceWorker';


// if(!window.location.pathname.includes("admin") || !window.location.pathname.includes("pharmacyadmin")) {
    
//     require('./client/assets/css/all.css')
//     require('./client/assets/css/all.min.css')
//     require('./client/assets/css/fontawesome.min.css')
//     require('./client/assets/css/style.css')
//     require('./client/assets/js/script.js')

// }


    
  require('./client/assets/css/all.css')
  require('./client/assets/css/all.min.css')
  require('./client/assets/css/fontawesome.min.css')
  require('./client/assets/css/style.css')
  require('./client/assets/js/script.js')



   

ReactDOM.render(
<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
  <BrowserRouter>
  <ToastContainer />
<AppRouter/>
<Loader/>
</BrowserRouter>
</PersistGate>
</Provider>, document.getElementById('root'));

if (module.hot) { // enables hot module replacement if plugin is installed
 module.hot.accept();
}