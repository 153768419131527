import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {DashboardSidebar} from "../sidebar/sidebar.jsx";
import {Tab, Tabs} from "react-bootstrap";
import StickyBox from "react-sticky-box";
import moment from "moment";
import {useHistory} from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
// import Modal from "@material-ui/core/Modal";
// import { Modal } from '@material-ui/core';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import toaster from "../../../../utils/ReactTostify";
import * as ReactBootstrap from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";

import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import AddFamilyMember from "./addFamilyMember/index.js";

import IMG01 from "../../../../assets/images/patients/avatar.png";
import {get_all_family_member_req, delete_family_member_request} from "../../../../redux-services/actions/action.familymember/action.function.js";
import {API_URL_ROOT} from "../../../../redux-services/api/api.js";

function FamilyMembers(props) {
  const title = "Are you sure you want to delete this family member?"
  const [openModal, setOpenModal] = useState(false);
  const {SearchBar, ClearSearchButton} = Search;
  const [familyMemberData, setFamilyMemberData] = useState([]);
  const [nofamilyMemberData, setNoFamilyMemberData] = useState("");
  const [alert, setAlert] = useState(false);
  const getFamilyMembersApiCall = async() =>{
    
   await props.get_all_family_member(null,(error,success)=>{
    if(error){
      
      console.log("error",error);
    }    
    if(success.status == 200 ){
      if (success && success?.response.length > 0) {
        setFamilyMemberData(success?.response);
      }
      if (success && success?.response.length <= 0) {
        console.log("data length empty",success?.message);
        setNoFamilyMemberData(success?.message);
      }
     }
     });
    

  }
  useEffect(() => {
    getFamilyMembersApiCall();
  }, []);
  // console.log("all family member values=====",props.all_family_member);
  const history = useHistory();
  const nextPath = (path,data) => {
    history.push({
      pathname: path,
      state:data
    });
  };

  const handle_edit = (data) => {
    console.log("edit passed ====",data);

     nextPath(`/familyMembers/familyMembersEdit`,data);
    // return(
    //   <FamilyMemberEdit dataValue={data}/>
    // )

  };
  const handle_view = (data) => {
    console.log("view passed ====", data);
    // return(
    //   <FamilyMemberView dataValue={data}/>
    // )

     nextPath(`/familyMembers/familyMemberView`,data);
  };

  const handle_delete =  (id) => {
    console.log("delete id passed ====",id);
 
    const getAlert = () => (
      <SweetAlert
        showCancel
        title={title}
        onConfirm={() => hideAlert(id)}
        onCancel={() =>
        setAlert(null)
        }
        focusCancelBtn
        customButtons={
      <React.Fragment>
        <button  type="button"
                  className="btn btn-primary mr-3" 
                  onClick={() => hideAlert(id)}
                  >Yes</button>
        <button  type="button"
                  className="btn btn-danger"
                  onClick={() => setAlert(null)}
                  >No</button>
      </React.Fragment>
    }
      >
       
      </SweetAlert>
    );
    setAlert(getAlert());

     
    
    
  };

  const hideAlert = async (id) => {

    if (id) {
      console.log(`patient_id : ${id} `);
      let data = {
        "patient_id":id
      }
      console.log(`patient_id : ${data.patient_id} `);
       await props.delete_family_member(data,(error,success)=>{
        if(error){
          
          console.log("error",error);
        }    
        if(success.status == 200 ){
          toaster('success', success.message);
          getFamilyMembersApiCall();
         }
         });
   
      // getMyAddressApiCall();
      
    }
    console.log("Hiding alert...");
    // this.setState({
    //   alert: null,
    // });
    setAlert(null);
  };

  const reloadDataModal = async() => {
     await getFamilyMembersApiCall();
    // await props.get_all_family_member();
    await setOpenModal(!openModal);
  }

  const handleModalClick = () => setOpenModal(!openModal);
  const columnsData = [
    {
      dataField: "patient_firstname",
      text: "Name",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        return (
          <div>
            <h2 className="table-avatar">
              <img
                className="avatar-img w-25 h-25 rounded-circle mr-1"
                src={((row.patient_image == null) || (row.patient_image == "null"))? IMG01 :`${API_URL_ROOT}/${row.patient_image}`
                }
                alt=""
              />
              {`${row.patient_firstname} ${row.patient_lastname}`}
            </h2>
          </div>
        );
      }
    },
    {dataField: "patient_gender", text: "Gender"},
    {
      dataField: "patient_date_of_birth",
      text: "Age",
      formatter: (cell, row) => {
        // console.log("age",row);
        const DateObtained = moment(row.patient_date_of_birth).format("DD-MM-YYYY");
        console.log("age", DateObtained);
        const AgeValue = moment().diff(
          moment(`${DateObtained}`, "DD-MM-YYYY"),
          "years",
          false
        );
        console.log("age value after diiference", AgeValue);
        return <div>{`${AgeValue}`}</div>;
      }
    },
    // {dataField: "patient_uhid", text: "Member ID"},
    {
      dataField: "patient_uhid",
      text: "UH_ID",
      formatter: (cell, row) => {
        // console.log("age",row);
        
        console.log("age value after diiference",row.patient_uhid);
        return <div>{`${row.patient_uhid}`}</div>;
      }
    },

    {
      dataField: "link",
      text: "",
      formatter: (rowContent, row) => {
        return (
          <div className="table-action">
            <button
              type="button"
              className="btn btn-sm bg-info-light"
              onClick={() => handle_view(row)}
            >
              <i className="far fa-eye"></i> View
            </button>

            <button
              type="button"
              className="btn btn-sm bg-warning-light"
              onClick={() => handle_edit(row)}
            >
              <i className="fas fa-edit"></i> Edit
            </button>
            <button type="button" className="btn btn-sm bg-primary-light" onClick={() =>handle_delete(row.patient_id)}>
                                        <i className="fas fa-trash-alt"></i> Delete
                                      </button>
          </div>
        );
      }
    }
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    }
  });

  return (
    <div>
    {alert ? alert : null}
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <StickyBox offsetTop={20} offsetBottom={20}>
              <DashboardSidebar {...props} />
            </StickyBox>
          </div>
          <div className="col-md-7 col-lg-8 col-xl-9">
            <div className="card">
              <div className="card-body user-tabs">
                <div className="row">
                  <div className="mr-auto p-1">
                    <strong> Family Members </strong>
                  </div>
                  <div className="">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleModalClick}
                        // onClick={() =>
                        //   nextPath("/Package/AddPackage")
                        // }
                        // onClick={handleModalClick}
                      >
                        <span>
                          <i
                            className="fa fa-plus-circle"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span>Add Family Member</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card card-table mb-0 mt-2">
                  <div className="card-body">
                    <div className="table-responsive">
                      <ToolkitProvider
                        bootstrap4
                        keyField="patient_id"
                        data={familyMemberData}
                        columns={columnsData}
                        search
                      >
                        {(props) => (
                          <div>
                            {/* <h6>Input something at below input field:</h6> */}
                            <SearchBar {...props.searchProps} />
                            {/* <ClearSearchButton {...props.searchProps} /> */}
                            <hr />
                            <BootstrapTable
                              striped
                              bordered
                              hover
                              // defaultSorted={defaultSorted}
                              pagination={pagination}
                              noDataIndication={() =>(<div className="text-alert"><i>{nofamilyMemberData}</i></div>)}
                              {...props.baseProps}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="mx-auto"
        >
          
            {<AddFamilyMember
              {...props}
              open={openModal}
              onClose={handleClose}
            />}
          
        </Modal> */}

        <Modal size="lg" isOpen={openModal} toggle={handleModalClick}  className="addFamilyMember">
              <ModalHeader toggle={handleModalClick} className="titleName">
              <strong> Add Family Members </strong>
                </ModalHeader>
                <ModalBody>
                <AddFamilyMember
              {...props}
              // open={openModal}
              // onClose={handleClose}
              toggle={handleModalClick}
              reloadFamilyMembers={reloadDataModal}
            />
                </ModalBody>
              </Modal>
      </div>
    </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    get_all_family_member: (data,callback) => dispatch(get_all_family_member_req(data,callback)),
    delete_family_member: (data,callback) =>dispatch(delete_family_member_request(data,callback))
    
  };
};
const mapStateToProps = (state) => {
  return {
    status: state.user.status,
    error: state.user.error,
    all_family_member: state.familymember.all_family_member,
    user_by_id: state.user.user_by_id,
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn,
    requesting: state.user.requesting
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FamilyMembers);
