//GET ALL DELETE_UPLOADPRESCRIPTION
export const GET_ALL_DELETE_UPLOADPRESCRIPTION_REQ  = "GET_ALL_DELETE_UPLOADPRESCRIPTION_REQ";
export const GET_ALL_DELETE_UPLOADPRESCRIPTION_ERR  = "GET_ALL_DELETE_UPLOADPRESCRIPTION_ERR";
export const GET_ALL_DELETE_UPLOADPRESCRIPTION_SUCC = "GET_ALL_DELETE_UPLOADPRESCRIPTION_SUCC";

//delete Book on call 
export const DELETE_BOOK_ON_CALL_REQUEST = "DELETE_BOOK_ON_CALL_REQUEST";
export const DELETE_BOOK_ON_CALL_ERROR   = "DELETE_BOOK_ON_CALL_ERROR";
export const DELETE_BOOK_ON_CALL_SUCCESS = "DELETE_BOOK_ON_CALL_SUCCESS";

//DELETE DELETE_UPLOADPRESCRIPTION_
export const DELETE_UPLOADPRESCRIPTION_REQUEST = "DELETE_UPLOADPRESCRIPTION_REQUEST";
export const DELETE_UPLOADPRESCRIPTION_ERROR   = "DELETE_UPLOADPRESCRIPTION_ERROR";
export const DELETE_UPLOADPRESCRIPTION_SUCCESS = "DELETE_UPLOADPRESCRIPTION_SUCCESS";

