import * as my_addressActionType from "../../actions/action.myaddress/action.type";

const myaddress = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallmy_address_error: null,
    getallmy_address_status: null,
    // getallpopularmy_address_error: null,
    // getallpopularmy_address_status: null,

    // my_address

     add_my_address: null,
    get_all_my_address: [],
     all_my_address: null,

    //  my_address_by_id: [],
    //  my_address_by_id_loading: false,
    //  my_address_by_id_error: null,
    //  my_address_enable_disable_status: null,
      update_my_address_details: null,

    delete_my_address: null,


    //city
    getallcity_error: null,
    getallcity_status: null,
    get_all_city: [],
     all_city: null,

     //all_area_by_city_id
    getallareabycityid_error: null,
    getallareabycityid_status: null,
    get_all_area_by_city_id: [],
    all_area_by_city_id: null,


    },
    action
) =>{

    switch (action.type) {
    
        //my_address reducers type
        
    
        //add new my_address
        case my_addressActionType.ADD_MY_ADDRESS_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case my_addressActionType.ADD_MY_ADDRESS_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            add_my_address: action.result,
          });
        case my_addressActionType.ADD_MY_ADDRESS_ERROR:
          return Object.assign({}, state, {
            getallmy_address_status: action.status,
            getallmy_address_error: action.error,
          });
         
          // all my_addresss get
          case my_addressActionType.GET_ALL_MY_ADDRESS_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case my_addressActionType.GET_ALL_MY_ADDRESS_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_my_address: action.result,
            });
          case my_addressActionType.GET_ALL_MY_ADDRESS_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });


            // delete address
    case my_addressActionType.DELETE_MY_ADDRESS_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
      });
    case my_addressActionType.DELETE_MY_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        delete_my_address: action.result,
      });
    case my_addressActionType.DELETE_MY_ADDRESS_ERROR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });


         
    
          // get my_addresss by id 
        //   case my_addressActionType.GET_MY_ADDRESS_BY_ID_REQ:
        //   return Object.assign({}, state, {
        //     my_address_by_id: [],
        //     my_address_by_id_loading: true,
        //     my_address_by_id_error: null,
        //   });
        // case my_addressActionType.GET_MY_ADDRESS_BY_ID_SUCCESS:
        //   return Object.assign({}, state, {
        //     my_address_by_id: action.payload,
        //     my_address_by_id_loading: false,
        //     my_address_by_id_error: null,
        //   });
        // case my_addressActionType.GET_MY_ADDRESS_BY_ID_ERR:
        //   return Object.assign({}, state, {
        //     my_address_by_id: [],
        //     my_address_by_id_loading: false,
        //     my_address_by_id_error: action.payload,
        //   });
        
          // update enable disable my_address status 
        //   case my_addressActionType.PUT_MY_ADDRESS_STATUS_BY_ID_REQ:
        //     return Object.assign({}, state, {
        //       status: action.status,
              
        //     });
        //   case my_addressActionType.PUT_MY_ADDRESS_STATUS_BY_ID_SUCC:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       my_address_enable_disable_status:action.payload,
        //     });
        //   case my_addressActionType.PUT_MY_ADDRESS_STATUS_BY_ID_ERR:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       error: action.error,
        //     });
    
              // UPDATE my_address DETAILS
        case my_addressActionType.UPDATE_MY_ADDRESS_DETAILS_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case my_addressActionType.UPDATE_MY_ADDRESS_DETAILS_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            update_my_address_details: action.result,
          });
        case my_addressActionType.UPDATE_MY_ADDRESS_DETAILS_ERROR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    


          // all city get
          case my_addressActionType.GET_ALL_CITY_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case my_addressActionType.GET_ALL_CITY_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_city: action.result,
            });
          case my_addressActionType.GET_ALL_CITY_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

             // ALL_AREA_BY_CITY_ID get
          case my_addressActionType.GET_ALL_AREA_BY_CITY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case my_addressActionType.GET_ALL_AREA_BY_CITY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_area_by_city_id: action.result,
            });
          case my_addressActionType.GET_ALL_AREA_BY_CITY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default myaddress;
    
    
    