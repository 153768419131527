import * as constance from "../constance/action.constance";
import { 
    //Get  all Custom_notification
GET_ALL_CUSTOM_NOTIFICATION_REQ,
GET_ALL_CUSTOM_NOTIFICATION_ERR,
GET_ALL_CUSTOM_NOTIFICATION_SUCC,

// POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID
POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID_REQ,
POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID_ERR,
POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID_SUCC,

} from "./action.type";


// GET Custom_notification

export function get_all_custom_notification_req(data,callback) {
    return {
      type:GET_ALL_CUSTOM_NOTIFICATION_REQ  ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_custom_notification_err(error) {
    return {
      type: GET_ALL_CUSTOM_NOTIFICATION_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_custom_notification_success(result) {
    return {
      type: GET_ALL_CUSTOM_NOTIFICATION_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }


  //POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID
export function post_custom_notification_read_status_by_id_success(result) {
    return {
      type: POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }
  export function post_custom_notification_read_status_by_id_request(data,callback) {
    return {
      type: POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID_REQ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  export function post_custom_notification_read_status_by_id_error(error) {
    return {
      type:POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID_ERR,
      error: error,
      status: constance.ERROR,
    };
  }