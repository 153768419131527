import * as orderActionType from "../../actions/action.order/action.type";


const order = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
     getalltime_slot_error: null,
     getalltime_slot_status: null,
     getallcoupons_error: null,
     getallcoupons_status: null,

     verify_coupon_error:null,
     verify_coupon_status:null,

     getallorders_error: null,
    getallorders_status: null,

    getdeliverycharges_error: null,
    getdeliverycharges_status: null,

    // order,time_slot,coupons
    create_order: null,
    get_all_orders:[],
    all_orders:null,

     get_all_time_slot: [],
     all_time_slot : null,
     get_all_coupons: [],
     all_coupons : null,
     verify_coupon : null,

     delete_order_by_order_id: null,

     get_deliverycharges:[],
     deliverycharges:null,

    },
    action
) =>{

    switch (action.type) {
    
   
        
    
        //add new order
        case orderActionType.CREATE_ORDER_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case orderActionType.CREATE_ORDER_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_order: action.result,
          });
        case orderActionType.CREATE_ORDER_ERROR:
          return Object.assign({}, state, {
            getall_order_status: action.status,
            getall_order_error: action.error,
          });


           //add new order
        case orderActionType.POST_VERIFY_COUPON_BY_ID_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case orderActionType.POST_VERIFY_COUPON_BY_ID_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            verify_coupon: action.result,
          });
        case orderActionType.POST_VERIFY_COUPON_BY_ID_ERR:
          return Object.assign({}, state, {
            verify_coupon_status: action.status,
            verify_coupon_error: action.error,
          });

   
           // get all orders
           case orderActionType.GET_ALL_ORDERS_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case orderActionType.GET_ALL_ORDERS_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_orders: action.result,
            });
          case orderActionType.GET_ALL_ORDERS_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });







        //get all time slot

          case orderActionType.GET_ALL_TIME_SLOT_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case orderActionType.GET_ALL_TIME_SLOT_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_time_slot: action.result,
            });
          case orderActionType.GET_ALL_COUPONS_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
         
          //get all coupons
            case orderActionType.GET_ALL_COUPONS_REQ:
              return Object.assign({}, state, {
                status: action.status,
              });
            case orderActionType.GET_ALL_COUPONS_SUCC:
              return Object.assign({}, state, {
                status: action.status,
                all_coupons: action.result,
              });
            case orderActionType. GET_ALL_COUPONS_ERR:
              return Object.assign({}, state, {
                status: action.status,
                error: action.error,
              });


            // delete ORDER
    case orderActionType.DELETE_ORDER_BY_ORDER_ID_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
      });
    case orderActionType.DELETE_ORDER_BY_ORDER_ID_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        delete_order_by_order_id: action.result,
      });
    case orderActionType.DELETE_ORDER_BY_ORDER_ID_ERROR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });


         
    // get deliverycharges
    case orderActionType.GET_DELIVERY_CHARGES_REQ:
      return Object.assign({}, state, {
        status: action.status,
      });
    case orderActionType.GET_DELIVERY_CHARGES_SUCC:
      return Object.assign({}, state, {
        status: action.status,
        deliverycharges: action.result,
      });
    case orderActionType.GET_DELIVERY_CHARGES_ERR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });


          // get my_addresss by id 
        //   case my_addressActionType.GET_MY_ADDRESS_BY_ID_REQ:
        //   return Object.assign({}, state, {
        //     my_address_by_id: [],
        //     my_address_by_id_loading: true,
        //     my_address_by_id_error: null,
        //   });
        // case my_addressActionType.GET_MY_ADDRESS_BY_ID_SUCCESS:
        //   return Object.assign({}, state, {
        //     my_address_by_id: action.payload,
        //     my_address_by_id_loading: false,
        //     my_address_by_id_error: null,
        //   });
        // case my_addressActionType.GET_MY_ADDRESS_BY_ID_ERR:
        //   return Object.assign({}, state, {
        //     my_address_by_id: [],
        //     my_address_by_id_loading: false,
        //     my_address_by_id_error: action.payload,
        //   });
        
          // update enable disable my_address status 
        //   case my_addressActionType.PUT_MY_ADDRESS_STATUS_BY_ID_REQ:
        //     return Object.assign({}, state, {
        //       status: action.status,
              
        //     });
        //   case my_addressActionType.PUT_MY_ADDRESS_STATUS_BY_ID_SUCC:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       my_address_enable_disable_status:action.payload,
        //     });
        //   case my_addressActionType.PUT_MY_ADDRESS_STATUS_BY_ID_ERR:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       error: action.error,
        //     });
    
              // UPDATE my_address DETAILS
        // case my_addressActionType.UPDATE_MY_ADDRESS_DETAILS_REQUEST:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //   });
        // case my_addressActionType.UPDATE_MY_ADDRESS_DETAILS_SUCCESS:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //     update_my_address_details: action.result,
        //   });
        // case my_addressActionType.UPDATE_MY_ADDRESS_DETAILS_ERROR:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //     error: action.error,
        //   });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default order;
    
    
    