import * as constance from "../constance/action.constance";
import { 
    
  //  GET ALL ARTICLES
    GET_ALL_ARTICLES_REQ ,
    GET_ALL_ARTICLES_ERR,
    GET_ALL_ARTICLES_SUCC,
  
  //GET ARTICLE BY ARTICLE ID
GET_ARTICLE_BY_ARTICLE_ID_REQ,
GET_ARTICLE_BY_ARTICLE_ID_ERR,
GET_ARTICLE_BY_ARTICLE_ID_SUCC,
  
  
  } from "./action.type";


  // GET ALL ARTICLES

export function get_all_articles_req(data,callback) {
    return {
      type:GET_ALL_ARTICLES_REQ  ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_articles_err(error) {
    return {
      type: GET_ALL_ARTICLES_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_articles_success(result) {
    return {
      type: GET_ALL_ARTICLES_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }


  //GET ARTICLE BY ARTICLE ID

export function get_article_by_article_id_req(data,callback) {
  return {
    type:GET_ARTICLE_BY_ARTICLE_ID_REQ  ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_article_by_article_id_err(error) {
  return {
    type: GET_ARTICLE_BY_ARTICLE_ID_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_article_by_article_id_success(result) {
  return {
    type: GET_ARTICLE_BY_ARTICLE_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };
}

  