import React, {  useState, useEffect } from 'react';
import { Row, Col,Modal,ModalBody } from "reactstrap";
// import { CSpinner} from '@coreui/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {  useSelector, useDispatch } from 'react-redux';
import { isLoading } from "../redux-services/actions/action.loader/action.function.js";
export function loadingData(data){
  return {
    loadingValue:data
  }
}
function Loader(props) {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
    const loaderValue = useSelector((state) => state.loaderReducer);

    useEffect(() => {
    if(loadingData.loadingValue == false){
      setLoader(false);
        dispatch(isLoading(false));
    }
    if(loadingData.loadingValue == true){
      setLoader(true);
       
    }
    if(loaderValue.isLoading){
      setLoader(loaderValue.isLoading);
    }
  },[]);

  // console.log("data from loader Data ====", loadingData);
  console.log("data from loader value====", loader);
    // const {isLoading = false} =loader;
    return (
        <div className="animated fadeIn">
          {(loader == true) && 
        <Row>
          <Col>
          <Modal 
        //   backdrop={false}
          isOpen={loader} 
            // toggle={isLoading}
            centered
            size="sm" 
            className="customBg" 
            >
                <ModalBody>
                <CircularProgress color="primary"/>
               
                </ModalBody>
              </Modal>

          </Col>
          </Row> 
          } 
        </div>
    )
}

export default Loader
