import * as constance from "../constance/action.constance";
import {  
   // get all family_member
    GET_ALL_FAMILY_MEMBER_REQ,
    GET_ALL_FAMILY_MEMBER_SUCC,
    GET_ALL_FAMILY_MEMBER_ERR,
    // Add family member
    ADD_FAMILY_MEMBER_REQUEST,
    ADD_FAMILY_MEMBER_SUCCESS,
    ADD_FAMILY_MEMBER_ERROR,

    // DELETE FAMILY MEMBER
    DELETE_FAMILY_MEMBER_REQUEST,
    DELETE_FAMILY_MEMBER_SUCCESS,
    DELETE_FAMILY_MEMBER_ERROR,

   //  UPDATE_FAMILY_MEMBER_DETAILS
   UPDATE_FAMILY_MEMBER_DETAILS_REQUEST,
   UPDATE_FAMILY_MEMBER_DETAILS_SUCCESS,
   UPDATE_FAMILY_MEMBER_DETAILS_ERROR,


  } from "./action.type";

  // GET ALL  FAMILY_MEMBER
export function get_all_family_member_req(data,callback) {
    return {
      type: GET_ALL_FAMILY_MEMBER_REQ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_family_member_err(error) {
    return {
      type: GET_ALL_FAMILY_MEMBER_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_family_member_success(result) {
    return {
      type: GET_ALL_FAMILY_MEMBER_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }

  // ADD FAMILY MEMBER


export function add_family_member_success(result) {
  return {
    type: ADD_FAMILY_MEMBER_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function add_family_member_request(data,callback) {
  return {
    type: ADD_FAMILY_MEMBER_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function add_family_member_error(error) {
  return {
    type: ADD_FAMILY_MEMBER_ERROR,
    error: error,
    status: constance.ERROR,
  };
}

// UPDATE_FAMILY_MEMBER_DETAILS


export function update_family_member_details_success(result) {
  return {
    type: UPDATE_FAMILY_MEMBER_DETAILS_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function update_family_member_details_request(data,callback) {
  return {
    type: UPDATE_FAMILY_MEMBER_DETAILS_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function update_family_member_details_error(error) {
  return {
    type: UPDATE_FAMILY_MEMBER_DETAILS_ERROR,
    error: error,
    status: constance.ERROR,
  };
}


// DELETE FAMILY MEMBER


export function delete_family_member_success(result) {
  return {
    type: DELETE_FAMILY_MEMBER_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function delete_family_member_request(data,callback) {
  return {
    type: DELETE_FAMILY_MEMBER_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function delete_family_member_error(error) {
  return {
    type: DELETE_FAMILY_MEMBER_ERROR,
    error: error,
    status: constance.ERROR,
  };
}