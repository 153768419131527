import * as feedbackActionType from "../../actions/action.feedback/action.type";

const feedback = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    

     getallfeedback_error: null,
    getallfeedback_status: null,

    // feedback
    create_feedback: null,
    get_all_feedback:[],
    all_feedback_question:null,

    

    },
    action
) =>{

    switch (action.type) {
    
   
        
    
        //add new feedback
        case feedbackActionType.CREATE_FEEDBACK_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case feedbackActionType.CREATE_FEEDBACK_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_feedback: action.result,
          });
        case feedbackActionType.CREATE_FEEDBACK_ERROR:
          return Object.assign({}, state, {
            getall_feedback_status: action.status,
            getall_feedback_error: action.error,
          });


          

   
           // GET_ALL_FEEDBACK_QUESTION
           case feedbackActionType.GET_ALL_FEEDBACK_QUESTION_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case feedbackActionType.GET_ALL_FEEDBACK_QUESTION_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_feedback_question: action.result,
            });
          case feedbackActionType.GET_ALL_FEEDBACK_QUESTION_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });


           


        
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default feedback;
    