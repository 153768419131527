import * as packagesActionType from "../../actions/action.packages/action.type";
const packages = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallpackages_error: null,
    getallpackages_status: null,
    getallpopularpackages_error: null,
    getallpopularpackages_status: null,

    // packages

    // create_packages: null,
    get_all_packages: [],
     all_packages: null,
     get_all_popular_packages: [],
     all_popular_packages: null,

    //  packages_by_id: [],
    //  packages_by_id_loading: false,
    //  packages_by_id_error: null,
    //  packages_enable_disable_status: null,
    //  update_packages_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //packages reducers type
        
    
        //create new packages
        // case packagesActionType.CREATE_PACKAGE_REQUEST:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //   });
        // case packagesActionType.CREATE_PACKAGE_SUCCESS:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //     create_packages: action.result,
        //   });
        // case packagesActionType.CREATE_PACKAGE_ERROR:
        //   return Object.assign({}, state, {
        //     getallpackages_status: action.status,
        //     getallpackages_error: action.error,
        //   });
         
          // all packages get
          case packagesActionType.GET_ALL_PACKAGE_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case packagesActionType.GET_ALL_PACKAGE_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_packages: action.result,
            });
          case packagesActionType.GET_ALL_PACKAGE_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

             // all popular packages get
          case packagesActionType.GET_ALL_POPULAR_PACKAGE_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case packagesActionType.GET_ALL_POPULAR_PACKAGE_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_popular_packages: action.result,
            });
          case packagesActionType.GET_ALL_POPULAR_PACKAGE_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
    
          // get packages by id 
        //   case packagesActionType.GET_PACKAGE_BY_ID_REQ:
        //   return Object.assign({}, state, {
        //     packages_by_id: [],
        //     packages_by_id_loading: true,
        //     packages_by_id_error: null,
        //   });
        // case packagesActionType.GET_PACKAGE_BY_ID_SUCCESS:
        //   return Object.assign({}, state, {
        //     packages_by_id: action.payload,
        //     packages_by_id_loading: false,
        //     packages_by_id_error: null,
        //   });
        // case packagesActionType.GET_PACKAGE_BY_ID_ERR:
        //   return Object.assign({}, state, {
        //     packages_by_id: [],
        //     packages_by_id_loading: false,
        //     packages_by_id_error: action.payload,
        //   });
          // update enable disable packages status 
        //   case packagesActionType.PUT_PACKAGE_STATUS_BY_ID_REQ:
        //     return Object.assign({}, state, {
        //       status: action.status,
              
        //     });
        //   case packagesActionType.PUT_PACKAGE_STATUS_BY_ID_SUCC:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       packages_enable_disable_status:action.payload,
        //     });
        //   case packagesActionType.PUT_PACKAGE_STATUS_BY_ID_ERR:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       error: action.error,
        //     });
    
              // UPDATE packages DETAILS
        // case packagesActionType.PUT_PACKAGE_DETAILS_REQ:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //   });
        // case packagesActionType.PUT_PACKAGE_DETAILS_SUCC:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //     update_packages_details: action.result,
        //   });
        // case packagesActionType.PUT_PACKAGE_DETAILS_ERR:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //     error: action.error,
        //   });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default packages;
    
    
    