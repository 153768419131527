import React, {useEffect,useState } from "react";
import { Rating } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { DashboardSidebar } from "../sidebar/sidebar.jsx";
import { Tab, Tabs } from "react-bootstrap";
import StickyBox from "react-sticky-box";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';

import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {get_all_orders_req} from "../../../../redux-services/actions/action.order/action.function.js";
import { statusConstants } from "../../../../utils/constants.js";


function Feedback(props){
  const { SearchBar, ClearSearchButton } = Search;
  const [feedbackData, setFeedbackData] = useState([]);
  const [noFeedbackData, setNoFeedbackData] = useState("");
 

  const nextPathDataPush = (path,data) =>{
    props.history.push({pathname: path,state: {
    data: data,
    }
  })
  }

  const getOrdersApiCall = async() =>{
    
    await props.get_all_orders(null,(error,success)=>{
     if(error){
       
       console.log("error",error);
     }    
     if(success.status == 200 ){
       if (success && success?.response.length > 0) {
         let filterOrders = success?.response.filter((orderData) =>{
        
          if((orderData.status == statusConstants.closed) && (orderData.feedback_id == null))
            return orderData;
         
         })
        //  console.log("filtered values ", FilteredOrderArray);
        //  setOrdersData(FilteredOrderArray);
       
        setFeedbackData(filterOrders);
        if(filterOrders.length == 0){
          setNoFeedbackData("No closed orders to provide feedback, come back later!!!")
        }

        

       }
       if (success && success?.response.length <= 0) {
         console.log("data length empty",success?.message);
         setNoFeedbackData(success?.message);
       }
      }
      });
     
 
   }

   useEffect(() => {
    getOrdersApiCall();
  }, []);


  const handle_view =  (id,row) => {
    console.log("view id passed ====",id);
    var data = {
      "orderid":`${id}`,
       "orderdata":row
    }
 
      nextPathDataPush(`/Feedback/FeedbackView`,data);
    
    
  };
  const columnsData = [
    { dataField: 'created_at', 
    text: 'Date',
    formatter: (cell, row) => {
      // console.log("age",row);
      const DateObtained = moment(row.created_at).format("DD-MM-YYYY");
      console.log("age", DateObtained);
     
      return <div>{`${DateObtained}`}</div>;
    }
   },
    { dataField: 'orderid', text: 'Order ID' },
   
    {
  dataField: 'link',
  text: 'Action',
  formatter: (rowContent, row) => {
    return (    
      <div className="table-action">
                                  <button type="button" className="btn btn-sm bg-info-light"   
                                   onClick={() =>handle_view(row.orderid,row)}
                                  >
                                      <i className="far fa-eye"></i> View
                                    </button>

                                    </div>                           
    )
  }
}
    
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });
    return (
      <div className="content">
        <div className="container-fluid">
       <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DashboardSidebar {...props}/>
              </StickyBox>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="card">
              <div className="card-body user-tabs">
                <div className="row">
                <div className="mr-auto p-1">
                <strong> Feedback </strong>
                  </div>
                 
                  </div>
                  <div className="card card-table mb-0 mt-2">
                        <div className="card-body">
                          <div className="table-responsive">

                          <ToolkitProvider
                             bootstrap4
                             keyField='feedback_id'
                             data={feedbackData}
                             columns={columnsData}
                             search
                           > 
       
      {
          props => (
            <div>
              {/* <h6>Input something at below input field:</h6> */}
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable striped bordered hover
                // defaultSorted={defaultSorted}
                pagination={pagination}
                noDataIndication={() => (
                                          <div className="text-alert">
                                             <i>{noFeedbackData}</i>
                                          </div>
                                    )}
                {...props.baseProps}
              />
              </div>
          )
        }
      </ToolkitProvider>
                            
                          </div>
                        </div>
                      </div>

                
                </div> 
              </div>
            </div>
          </div> 
        </div>
      </div>
    );
  }
  const mapDispatchToProps = (dispatch) => {
    return {
      get_all_orders: (data,callback) =>dispatch(get_all_orders_req(data,callback)),
      //  logout_req: () =>dispatch(logout_req()),
      //  user_by_id_req: (data,callback) =>dispatch(get_user_by_id_req(data,callback)),
    };
    };
    const mapStateToProps = (state) => {
    return {
      status: state.user.status,
      error: state.user.error,
      user_by_id:state.user.user_by_id,
      user: state.user.user,
      isLoggedIn: state.user.isLoggedIn,
      requesting: state.user.requesting,
    
    
    };
    };
    export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
