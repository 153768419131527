import React from 'react'
import { connect } from 'react-redux'
import UserBooking from "../bookings/booking/index";
  import UserCheckout from '../bookings/checkout/index';
  import SelectAddress from "../bookings/selectAddress/index"
function AddToCart(props){
    return (
        <>
            
            {/* <UserBooking {...props}/> */}
            <UserCheckout {...props}/> 
        </>
    )
}

// const mapStateToProps = (state) =>{
//     // return {
//     //     status: state.user.status,
//     //     error: state.user.error,
//     //     all_family_member: state.familymember.all_family_member,
//     //     user_by_id: state.user.user_by_id,
//     //     user: state.user.user,
//     //     isLoggedIn: state.user.isLoggedIn,
//     //     requesting: state.user.requesting
//     //     }
    
//     }

// const mapDispatchToProps  = (dispatch) =>{
//     return {
        // get_all_family_member: (data,callback) => dispatch(get_all_family_member_req(data,callback)),
        // delete_family_member: (data,callback) =>dispatch(delete_family_member_request(data,callback))
//       };
   
    
// }

export default AddToCart;
// export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);