import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import { saveAs } from 'file-saver';
import {DashboardSidebar} from "./sidebar/sidebar.jsx";
import {Tab, Tabs} from "react-bootstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as ReactBootstrap from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import SweetAlert from "react-bootstrap-sweetalert";
import {API_URL_ROOT} from "../../../redux-services/api/api.js";
import UploadPrescription from "./uploadPrescription/index.jsx"
import {get_all_orders_req} from "../../../redux-services/actions/action.order/action.function.js";
import {create_book_on_call_request, get_all_prescriptions_req, upload_prescription_req} from "../../../redux-services/actions/action.dashboard/action.function.js";
import { get_all_delete_uploadprescription_req, delete_uploadprescription_request} from "../../../redux-services/actions/action. uploaddiscription/action.function";

import toaster from "../../../utils/ReactTostify.js";

// import IMG01 from '../../../../assets/images/patients/patient.jpg';
import {statusConstants} from "../../../utils/constants";

import StickyBox from "react-sticky-box";
import IMG01 from "../../../assets/images/placeholderImg300x300.png";
import call from ".././../../assets/images/call.png";
import { API_URL } from '../../../redux-services/api/api.js';
import { API } from '../../../utils/networkApi.js';
import {isLoading} from '../../../redux-services/actions/action.loader/action.function.js';



function UserDashboard(props) {
  const title = "Are you sure you want to delete Prescription?"
  const [alert, setAlert] = useState(false);
  const [key, setKey] = useState(1);
  const {SearchBar, ClearSearchButton} = Search;
  const [idPassed, setIdPassed] = useState(0);
  const [prescriptionId, setPrescriptionId] = useState(null);
  const [bookOnCallModal, setBookOnCallModal] = useState(false);
  // prescription Modal call
  const [uploadPrescriptionModal, setUploadPrescriptionModal] = useState(false);



  const [ordersData, setOrdersData] = useState([]);
  const [noOrdersData, setNoOrdersData] = useState("");

  const [prescriptionsData, setPrescriptionsData] = useState([]);
  const [noPrescriptionsData, setNoPrescriptionsData] = useState("");

  const handleSelect = (key) => {
    setKey(key);
  };

  const nextPathDataPush = (path,data) =>{
    props.history.push({pathname: path,state: {
    data: data,
    }
  })
  }
  

  const getOrdersApiCall = async() =>{
    
    await props.get_all_orders(null,(error,success)=>{
     if(error){
       
       console.log("error",error);
     }    
  
     if (success.status === 200) {
      if (success.response && success.response.length > 0) {
        const sortedOrders = success.response.sort((a, b) => {
          // First, sort by status ('closed' comes last)
          if (a.status === 'closed' && b.status !== 'closed') {
            return 1; // 'closed' comes last
          } else if (a.status !== 'closed' && b.status === 'closed') {
            return -1; // 'closed' comes last
          }
    
          // If status is the same, then sort by time slot
          const timeSlotA = new Date(a.timeslot).getTime();
          const timeSlotB = new Date(b.timeslot).getTime();
    
          return timeSlotA - timeSlotB; // Sorting by time slot
        });
    
        // Update state with sorted orders
        setOrdersData(sortedOrders);
      }
    
    
  
        if (success && success?.response.length <= 0) {
          console.log("data orders length empty",success?.message);
          setNoOrdersData(success?.message);
        }
      }
      });
     
 
   }

   const getPrescriptionsApiCall = async() =>{
    
    await props.get_all_prescriptions(null,(error,success)=>{
     if(error){
       
       console.log("error",error);
     }    
     if(success.status == 200 ){
    
       if (success && success?.response.length > 0) {
        setPrescriptionsData(success?.response);
       }
       if (success && success?.response.length <= 0) {
         console.log("data Prescriptions length empty ",success?.message);
         setNoPrescriptionsData(success?.message);
       }
      }
      });
     
 
   }
   useEffect(() => {

     getOrdersApiCall();
     getPrescriptionsApiCall();

   },[]);

   console.log("orders data ==", ordersData);
   console.log("prescriptions data ==", prescriptionsData);

   const handle_view =  (id,row) => {
    console.log("view id passed ====",id);
      var data = {
        "orderid":`${id}`,
         "orderdata":row
      }
   
        nextPathDataPush(`/Orders/OrderView`,data);
    
    
  };


  const handle_delete_prescription =  (id) => {
    console.log("delete prescription id passed ====",id);
 
    const getAlert = () => (
      <SweetAlert
    
        showCancel
     
        title={title}
        onConfirm={() => hideAlert(id)}
        onCancel={() =>
        setAlert(null)
        }
        focusCancelBtn
        customButtons={
      <React.Fragment>
        <button  type="button"
                  className="btn btn-primary mr-3" 
                  onClick={() => hideAlert(id)}
                  >Yes</button>
        <button  type="button"
                  className="btn btn-danger"
                  onClick={() => setAlert(null)}
                  >No</button>
      </React.Fragment>
    }
      >
       
      </SweetAlert>
    );
    setAlert(getAlert());

     
    
    
  };

  const hideAlert = async (id) => {

    if (id) {
      console.log(` "prescriptionid" : ${id} `);
      let data = {
        "prescriptionid":`${id}`
      }
      console.log(` prescriptionid: ${data.prescriptionid} `);
       await props.delete_upload_prescription(data,(error,success)=>{
        if(error){
          
          console.log("error",error);
        }    
        if(success.status == 200 ){
          toaster('success', success.message);
          getOrdersApiCall();
        getPrescriptionsApiCall();
         }
         });
   
      
      
    }
    console.log("Hiding alert...");
    
    setAlert(null);
  };

  const handleInvoice = async(orderid) =>{
    // let data ={
    //   "orderid":`${orderid}`
    // }
    // isLoading(true);
    // const result = await API.post(API_URL.ORDER_INVOICE_BY_ORDER_ID,data,API.getHeadersFormData());
    // isLoading(false);
    // let response = await result.blob();
    // console.log("response data====",response);
    let token = localStorage.getItem("token");
    // axios direct implementation
   let urlAxios = axios.get(`${API_URL.ORDER_INVOICE_BY_ORDER_ID}/${orderid}`,{
      headers:{
        Authorization: "Bearer " + token,
         'Content-Type': 'multipart/form-data'
      },
      responseType: 'arraybuffer', // important
    });

    
      // const url = window.URL.createObjectURL(new Blob([response.data]));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'file.pdf');
      // document.body.appendChild(link);
      // link.click();
      // console.log("response data from the api ==",response);
      // const responseData = response.data;
      const { data } = await new urlAxios();
      const blob = new Blob([data], { type: 'application/pdf' });
      saveAs(blob, "invoice.pdf")

    
   

  }






  const orderColumns = [ {
    dataField: "orderid",
    text: "Order ID",
    formatter: (cell, row) => {
      console.log("row values ==", row);
      return <div> {row.orderid ? row.orderid : null}</div>;
    }
  },
  
  {
    dataField: "patient_first_name",
    text: "Patient Name",
    formatter: (cell, row) => {
      console.log("row values ==", row);
      return <div> {row.patient_first_name ? `${row.patient_first_name} ${row.patient_last_name}` : null}</div>;
    }
  },

  {
    dataField: "coupon_name",
    text: "Coupon",
    formatter: (cell, row) => {
      console.log("row values ==", row);
      return <div> {row.coupon_name ? row.coupon_name : "NONE"}</div>;
    }
  },
  {
    dataField: "totalcost",
    text: "Amount (₹)",
    formatter: (cell, row) => {
      console.log("row values ==", row);
      return <div> {row.totalcost ? row.totalcost : null}</div>;
    }
  },
  {
    dataField: "payment_method",
    text: "Payment Method",
    formatter: (cell, row) => {
      console.log("row values ==", row);
      return <div> {row.payment_method ? row.payment_method : null}</div>;
    }
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cell, row) => {
      console.log("row values ==", row);
      return (
        <div className={(row.status == statusConstants.raised) ? "text-primary" : (row.status == statusConstants.assigned) ? "text-secondary":(row.status == statusConstants.processed) ? "text-secondary": (row.status == statusConstants.closed) ? "text-success": (row.status == statusConstants.declined)? "text-warning": "text-danger"}>
          <strong>
            {row.status != null
              ? row.status == statusConstants.raised
                ? "Raised"
                : row.status == statusConstants.processed
                ? "Processed"
                : row.status == statusConstants.assigned
                ? "Technician assigned"
                : row.status == statusConstants.closed
                ? "Complete"
                : row.status == statusConstants.declined
                ? "Declined"
                : "Cancelled"
              : "Nill"}
          </strong>
        </div>
      );
    }
  },

  {
    dataField: "link",
    text: "Action",
    formatter: (cell, row) => {
      console.log("row values ==", row);
      return (
       
        <div className="table-action">
        <button type="button" className="btn btn-sm bg-info-light" 
         onClick={() =>handle_view(row.orderid,row)}
        >
            <i className="far fa-eye"></i> View
          </button>

       
          {/* <button type="button" className="btn btn-sm bg-primary-light" 
             onClick={() =>handleInvoice(row.orderid)}
            >
                                        <i className="fas fa-print"></i> Invoice
                                      </button> */}
        </div>  
      );
    }
  }
];

// const handleModalClick = () => setOpenModal(!openModal);
// const columnsData = [
//   {
//     dataField: "patient_firstname",
//     text: "Name",
//     formatter: (cell, row) => {
//       console.log("row values ==", row);
//       return (
//         <div>
//           <h2 className="table-avatar">
//             <img
//               className="avatar-img w-25 h-25 rounded-circle mr-1"
//               src={(row.patient_image == null)? IMG08 :`${API_URL_ROOT}/${row.patient_image}`
//               }
//               alt=""
//             />
//             {`${row.patient_firstname} ${row.patient_lastname}`}
//           </h2>
//         </div>
//       );
//     }
//   },
//   {dataField: "patient_gender", text: "Gender"},
//   {
//     dataField: "patient_date_of_birth",
//     text: "Age",
//     formatter: (cell, row) => {
//       // console.log("age",row);
//       const DateObtained = moment(row.patient_date_of_birth).format("DD-MM-YYYY");
//       console.log("age", DateObtained);
//       const AgeValue = moment().diff(
//         moment(`${DateObtained}`, "DD-MM-YYYY"),
//         "years",
//         false
//       );
//       console.log("age value after diiference", AgeValue);
//       return <div>{`${AgeValue}`}</div>;
//     }
//   },
//   {dataField: "patient_id", text: "Member ID"},

//   {
//     dataField: "link",
//     text: "",
//     formatter: (rowContent, row) => {
//       return (
//         <div className="table-action">
//           <button
//             type="button"
//             className="btn btn-sm bg-info-light"
//             onClick={() => handle_view(row)}
//           >
//             <i className="far fa-eye"></i> View
//           </button>

//           <button
//             type="button"
//             className="btn btn-sm bg-warning-light"
//             onClick={() => handle_edit(row)}
//           >
//             <i className="fas fa-edit"></i> Edit
//           </button>
//           <button type="button" className="btn btn-sm bg-primary-light" onClick={() =>handle_delete(row.patient_id)}>
//                                       <i className="fas fa-trash-alt"></i> Delete
//                                     </button>
//         </div>
//       );
//     }
//   }
// ];



  const bookOnCallHandleClick = (id_passed, prescriptionid) =>{
    setBookOnCallModal(true);
    setIdPassed(id_passed);
    setPrescriptionId(prescriptionid);

  }

  const uploadPrescriptionHandleClick = () =>{
    setUploadPrescriptionModal(!uploadPrescriptionModal)
    
    
    // handleUploadPrescriptionModal
  }
  const handleUploadPrescriptionModal = () => setUploadPrescriptionModal(true);
  const reloadPrecriptionDataModal = async() => {
    await getOrdersApiCall();
     await getPrescriptionsApiCall();
   await setUploadPrescriptionModal(!uploadPrescriptionModal);
  //  await setUploadPrescriptionModal(!uploadPrescriptionModal);
 }


  const handleBookOnCallModal = () => setBookOnCallModal(!bookOnCallModal);

  const bookOnCallApihandle = async (id_passed, prescritionid) =>{
    if(id_passed == 0){
      await props.create_book_on_call(null,(error,success)=>{

        if(success && success.status === 200 )
        {
          toaster("success", success.message);
          handleBookOnCallModal();
        }
    
        if(error.response.data.status === 409)
        {
          toaster("info", error.response.data.message);
        }    
      
       
         });

    }
    // if (id_passed === 0) {
    //   await props.create_book_on_call(null, (error, success) => {
    //     if (success && success.status === 200) {
    //       toaster("success", success.message);
    //       handleBookOnCallModal();
    //     }
    
    //     if (error.response.data.status === 409) {
    //       toaster("info", error.response.data.message);
    //     }
    //   });
    // }

    if(id_passed == 1){
      let data = {
        prescriptionid:`${prescritionid}`
      }
      await props.create_book_on_call(data,(error,success)=>{
        if(error){
          
          console.log("error",error);
        }    
        if(success.status == 200 ){
          toaster("success", success.message);
          handleBookOnCallModal();
        }
        if(error.response.data.status === 409)
        {
          toaster("info", error.response.data.message);
        } 
         });
    }

    
  }

  

  const precriptionUploadApiCall = async(formData) =>{
    await props.upload_prescription(formData,(error,success)=>{
      if(error){
        
        console.log("error",error);
      }    
      if(success.status == 200 ){
        toaster("success", success.message);
        getOrdersApiCall();
        getPrescriptionsApiCall();
        
      }
       });
  }
  const handlePrescriptionUpload = (event) =>{
    
    if(event.target.files.length !== 0){
      
      const formData = new FormData();
      formData.append('prescription_photo', event.currentTarget.files[0]);
      precriptionUploadApiCall(formData);
    }
  }


  const prescriptionColumns = [
    {
      dataField: "prescription_photo",
      text: "Prescription Image",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        return (
          <div className="row">
            <img
              className="avatar-img w-25 h-25 rounded-circle mr-1"
               src={(row.prescription_photo == null)? IMG01:`${API_URL_ROOT}/${row.prescription_photo}`}
              // src={IMG01}
              alt=""
            />
          </div>
        );
      }
    },
    {
      dataField: "prescription_name",
      text: "Name",
      formatter: (cell, row, rowIndex) => {
        console.log("row values ==", row);
        return <div> {row.prescription_name ? `${row.prescription_name}` : null}</div>;
      }
    },
    {
      dataField: "link",
      text: "Book on call",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        return (
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => bookOnCallHandleClick(1,row.prescriptionid)}
              // disabled={ this.state.disabled }
            >
              <i className="fa fa-phone-alt"></i>
            </button>
          </div>
        );
      }
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        return (
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-md bg-primary-light"
               onClick={() => handle_delete_prescription(row.prescriptionid)}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
           
          </div>
        );
      }
    }
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    }
  });
  return (
    <div>
      {alert ? alert : null}
    
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <StickyBox offsetTop={20} offsetBottom={20}>
              <DashboardSidebar {...props} />
            </StickyBox>
          </div>
          <div className="col-md-7 col-lg-8 col-xl-9">
            <div className="card">
              <div className="card-body user-tabs pt-0">
                <Tabs
                  className="tab-view"
                  activeKey={key}
                  onSelect={handleSelect}
                  id="controlled-tab-example"
                >
                  <Tab className="nav-item" eventKey={1} title="My Orders">
                  <div className="row mb-2">
                  <div className="col-6 col-md-6">
                  </div>
                     <div className="col-6 col-md-6">
                      <div className="float-right">
                        <button
                          type="button"
                          className="change-photo-btn bg-success text-white border-light"
                            onClick={() => bookOnCallHandleClick(0,null)}
                        >
                          <i className="fa fa-phone-alt"></i>
                          {" "}Book on call
                        </button>
                      </div>
                      </div>
                      </div>
                    <div className="card card-table mb-0">
                      <div className="card-body">
                        <div className="table-responsive">
                          {/* <table className="table table-hover table-center mb-0"> */}

                          <ToolkitProvider
                            bootstrap4
                            keyField="orderid"
                            data={ordersData}
                            columns={orderColumns}
                            search
                          >
                            {(props) => (
                              <div>
                                {/* <h6>Input something at below input field:</h6> */}
                                <SearchBar {...props.searchProps} />
                                <ClearSearchButton {...props.searchProps} />
                                
                                <BootstrapTable
                                  striped
                                  bordered
                                  hover
                                  // defaultSorted={defaultSorted}
                                  pagination={pagination}
                                  noDataIndication={() => (
                                          <div className="text-alert">
                                            <i>{noOrdersData}</i>
                                          </div>
                                    )}
                                  {...props.baseProps}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    className="nav-item"
                    eventKey={2}
                    title="Uploaded Prescription"
                  >
                    <div className="row mb-2">
                     
                      {/* <div className="col-6 col-md-6">
                      <div className="upload-img float-left">
                        <div className="change-photo-btn">
                          <span>
                            <i className="fa fa-upload"></i> Upload Prescription
                          </span>
                          <input type="file" className="upload" onChange={(event) => handlePrescriptionUpload(event)}/>
                        </div>
                        <small className="form-text text-muted">
                          Allowed JPG or PNG. Max size of 2MB
                        </small>
                      </div>
                      </div> */}
                      <div className="col-6 col-md-6">
                      <div className="float-left">
                        <button
                          type="button"
                          className="change-photo-btn text-white border-light"
                            onClick={() => handleUploadPrescriptionModal()}
                          // onClick={() => bookOnCallHandleClick(0,null)}
                        >
                          <i className="fa fa-upload"></i>
                          {" "}Upload Prescription
                        </button>
                      </div>
                      </div>
                                 

                      <div className="col-6 col-md-6">
                      <div className="float-right">
                        <button
                          type="button"
                          className="change-photo-btn bg-success text-white border-light"
                          //  onClick={() => handleViewClick(row.orderid,row)}
                          onClick={() => bookOnCallHandleClick(0,null)}
                        >
                          <i className="fa fa-phone-alt"></i>
                          {" "}Book on call
                        </button>
                      </div>
                      </div>
                    </div>
                    <div className="card card-table mb-0">
                      <div className="card-body">
                        <div className="table-responsive">
                          {/* <table className="table table-hover table-center mb-0"> */}

                          <ToolkitProvider
                            bootstrap4
                            keyField="prescriptionid"
                            data={prescriptionsData}
                            columns={prescriptionColumns}
                            search
                          >
                            {(props) => (
                              <div>
                                <SearchBar {...props.searchProps} />
                                <ClearSearchButton {...props.searchProps} />
                               <hr/>
                                <BootstrapTable
                                  striped
                                  bordered
                                  hover
                                  // defaultSorted={defaultSorted}
                                  pagination={pagination}
                                  noDataIndication={() => (
                                          <div className="text-alert">
                                            <i>{noPrescriptionsData}</i>
                                          </div>
                                    )}
                                  {...props.baseProps}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>

    

<Modal size="md" isOpen={bookOnCallModal} toggle={handleBookOnCallModal}  className="bookOnCall">
      <ModalHeader toggle={handleBookOnCallModal} className="titleName">
      <strong> Book on Call </strong>
        </ModalHeader>
        <ModalBody>
        <div>
          <div className="container">
              <div className="row">
              <div className="col-4 col-md-4">
              </div>
                <div className="col-4 col-md-4">
                <div className="text-center">
                  <img className="mx-auto d-block img-fluid" 
                  src={call}
                  alt=""
                 
                  /> 
                  </div>
                 
                </div>
                <div className="col-4 col-md-4">
              </div>
              </div>
              <strong className="text-success float-center"> click on confirm to get call back from our executive!</strong>
              <div className="row">
              <div className="col-6 col-md-6">
              <div className="float-left">
                        <button
                          type="button"
                          className="btn btn-warning text-white"
                           onClick={() => handleBookOnCallModal()}
                        >
                          {" "}Cancel
                        </button>
                      </div>
              </div>
              <div className="col-6 col-md-6">
              <div className="float-right">
                        <button
                          type="button"
                          className="btn btn-success text-white"
                           onClick={() => bookOnCallApihandle(idPassed,prescriptionId)}
                        >
                          {" "}Confirm
                        </button>
                      </div>
              </div>
              </div>
          </div>
        </div>
       
        </ModalBody>
      </Modal>

      <Modal size="md" isOpen={uploadPrescriptionModal} toggle={handleUploadPrescriptionModal}  className="uploadPrescription">
              <ModalHeader toggle={handleUploadPrescriptionModal} className="titleName">
              <strong> Upload Prescription </strong>
                </ModalHeader>
                <ModalBody>
                <UploadPrescription
              {...props}
              // open={openModal}
              // onClose={handleClose}
              toggle={handleUploadPrescriptionModal}
              reloadDashboard={reloadPrecriptionDataModal}
            />
                </ModalBody>
              </Modal>
      </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    //  logout_req: () =>dispatch(logout_req()),
    create_book_on_call: (data,callback) =>dispatch(create_book_on_call_request(data,callback)),
    get_all_orders: (data,callback) =>dispatch(get_all_orders_req(data,callback)),
    get_all_prescriptions: (data,callback) =>dispatch(get_all_prescriptions_req(data,callback)),
    upload_prescription: (data,callback) =>dispatch(upload_prescription_req(data,callback)),
    delete_upload_prescription: (data,callback) =>dispatch(delete_uploadprescription_request(data,callback)),


  };
};
const mapStateToProps = (state) => {
  return {
    status: state.user.status,
    error: state.user.error,
    user_by_id: state.user.user_by_id,
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn,
    requesting: state.user.requesting
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
