import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
    create_feedback_request,
    create_feedback_success,
    create_feedback_error,
    get_all_feedback_question_req,
    get_all_feedback_question_err,
    get_all_feedback_question_success,

   

} from './action.function.js';
import {
   
    CREATE_FEEDBACK_REQUEST,
      GET_ALL_FEEDBACK_QUESTION_REQ,
 

  } from './action.type';
import { 
    API_URL,
    } from '../../api/api.js';
import {API} from '../../../utils/networkApi';
import toaster from "../../../utils/ReactTostify";
import {isLoading} from '../action.loader/action.function.js'; 



function* allFeedbackQuestionSaga(payload) {
    const {data,callback} =payload;
      try {
          const store = yield select();
          // const userId =  store.user.user?.userId;
      console.log("store values ==========", store?.user?.user?.userId)
      yield put(isLoading(true));
        let response = yield call(API.get,API_URL.GET_ALL_FEEDBACK_QUESTION,API.getHeaders());
        yield put(isLoading(false));
        const result  = response.data;
        console.log("all feedback question result === ", result);
        yield put(get_all_feedback_question_success(result));
        callback(null,result);
        
       
      }
      catch (error) {
        yield put(get_all_feedback_question_err(error));
        callback(error,null);
        yield put(isLoading(false));
      }
    }





function* createFeedbackSaga(payload) {
  const {data,callback} =payload;
  console.log("data sent to create order  saga", data)
  try {
    // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
    yield put(isLoading(true));
    let response = yield call(API.post,API_URL.CREATE_FEEDBACK, data,API.getHeaders());
    yield put(isLoading(false));
    const result  = response.data;
    console.log("create feedback response saga",result);
    yield put(create_feedback_success(result))
    callback(null,result);
  }
  catch(error) {
    yield put(create_feedback_error(error));
    callback(error,null);
    yield put(isLoading(false));
    toaster("error", error);
  }
}


export default function* feedbackSaga() { 
  
    yield takeEvery( CREATE_FEEDBACK_REQUEST, createFeedbackSaga);
    yield takeEvery( GET_ALL_FEEDBACK_QUESTION_REQ, allFeedbackQuestionSaga);

    
    
    
} 