//GET ALL FAMILY_MEMBER
export const GET_ALL_FAMILY_MEMBER_REQ = "GET_ALL_FAMILY_MEMBER_REQ";
export const GET_ALL_FAMILY_MEMBER_ERR = "GET_ALL_FAMILY_MEMBER_ERR";
export const GET_ALL_FAMILY_MEMBER_SUCC = "GET_ALL_FAMILY_MEMBER_SUCC";

//ADD FAMILY_MEMBER
export const ADD_FAMILY_MEMBER_REQUEST = "ADD_FAMILY_MEMBER_REQUEST";
export const ADD_FAMILY_MEMBER_ERROR = "ADD_FAMILY_MEMBER_ERROR";
export const ADD_FAMILY_MEMBER_SUCCESS = "ADD_FAMILY_MEMBER_SUCCESS";

//DELETE FAMILY_MEMBER
export const DELETE_FAMILY_MEMBER_REQUEST = "DELETE_FAMILY_MEMBER_REQUEST";
export const DELETE_FAMILY_MEMBER_ERROR = "DELETE_FAMILY_MEMBER_ERROR";
export const DELETE_FAMILY_MEMBER_SUCCESS = "DELETE_FAMILY_MEMBER_SUCCESS";

// UPDATE FAMILY_MEMBER DETAILS

export const UPDATE_FAMILY_MEMBER_DETAILS_REQUEST = "UPDATE_FAMILY_MEMBER_DETAILS_REQUEST";
export const UPDATE_FAMILY_MEMBER_DETAILS_ERROR = "UPDATE_FAMILY_MEMBER_DETAILS_ERROR";
export const UPDATE_FAMILY_MEMBER_DETAILS_SUCCESS = "UPDATE_FAMILY_MEMBER_DETAILS_SUCCESS";