import React, {useState,useEffect} from 'react';
import loginBanner from '../../assets/images/login-banner.png';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import './style.css'
import { API_URL } from '../../redux-services/api/api.js';
import { API } from '../../utils/networkApi.js';
import toaster from "../../utils/ReactTostify";

function ForgotMpin(props){  
	const  state = {
        divcontainer:false,
    }
const [otp,setOtp]=useState(state);
const [seconds, setSeconds] = useState(60);
const [sendOTPhideShow, setSendOTPhideShow] = useState(false);
const [sendOTPDisable, setSendOTPDisable] = useState(false);
const [forgetMpinDisable, setforgetMpinDisable] = useState(true);
const [phoneNumberError, setPhoneNumberError] = useState(null);
const [phoneNumber, setPhoneNumber] = useState("");

const [resendOTPhideShow, setResendOTPhideShow] = useState(true);
// const mobileTest = new RegExp(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/);
// const mobileTest = new RegExp(/^[6-9]\d{9}$/gi);
const mobileTest = new RegExp(/^[6-9]{1}[0-9]{9}$/);
useEffect(() => {
	setSendOTPhideShow(false);
	if (seconds > 0) {
	  setTimeout(() => setSeconds(seconds - 1), 1000);
	  // setSendOTPhideShow(true);
	  setResendOTPhideShow(true);
	//   setforgetMpinDisable(true);
	} else {
	  setSeconds(0); 
	  setSendOTPhideShow(true);
	  setResendOTPhideShow(false);
	  setforgetMpinDisable(false);
  
	}
  });

   	// history path
	const nextPath = (path) =>{
		props.history.push(path);
	  }

	  const handleForgetMpin = async(data) =>{
		try{
		  const result = await API.post(API_URL.VERIFY_FORGET_MPIN,data);
		  let response = await result.data;
		  console.log("response values of the signup",response);
		  if(response.status === 200){
			toaster('success', response.message);
			nextPath('/home/login')
		  }
		  
		}
		catch( error){
		  toaster('error', error);
		}
	
	  }
  
	  const handleSendOTP = async() =>{
		try{
		  if(phoneNumberError === null){
			if(phoneNumber === ""){
				setPhoneNumberError('Phone number must not be empty');
			  }
			  else{
				setSendOTPDisable(true);
			setforgetMpinDisable(false);
			const data ={
			  "phone": `${phoneNumber}`
			}
			const result = await API.post(API_URL.FORGET_MPIN_SEND_OTP,data);
			let response = await result.data;
			console.log("response values of the send forget otp",response);
			if(response.status === 200){
			  toaster('success', response.message);
			  setOtp({divcontainer:!otp.divcontainer});
			}
			  }
			
		  }
		  
		  
		}
		catch( error){
		  toaster('error', error.message);
		}
	
	  }

	  const validate = values => {
		const errors = {}
		// console.log("mobileTest.test(values.phone) ==",mobileTest.test(values.phone));
		if (values.phone === "") {
			errors.phone = "Phone number is required";
		  } else if (!mobileTest.test(values.phone)) {
			// errors.phone = "Invalid phone number format";
			errors.phone = "Invalid phone number";
		  }else if ((values.phone.length < 10) || (values.phone.length > 10)) {
			errors.phone = 'Phone number must be 10 digits';
			}
  
		  if (!values.otp) {
			errors.otp = 'OTP must not be empty'
			} else if ((values.otp.length < 4) || (values.otp.length > 4)) {
			errors.otp = 'OTP must be 4 characters'
			} 
  
			if (!values.mpin) {
			  errors.mpin = 'New Mpin must not be empty'
			  } else if ((values.mpin.length < 4) || (values.mpin.length > 4)) {
			  errors.mpin = 'New Mpin must be 4 characters'
			  } 
			  if (!values.confirmmpin) {
				errors.confirmmpin = 'Must not be empty string'
			  } else if (values.mpin !== values.confirmmpin) {
				errors.confirmmpin =  "New Mpin and confirm Mpin do not match. Please re-enter";
			  }
  
		  return errors
		}

		

		const formik = useFormik({

			initialValues: {
        "phone":"",
        "otp":"",
        "mpin":"",
		confirmmpin:""
			},
			validate,
			onSubmit: values => {
			//   alert(JSON.stringify(values, null, 2))
			const data = {
        "phone":values.phone,
        "otp":values.otp,
        "newmpin":values.mpin,

			}
			console.log("data submit forget-mpin submitted",data);

			handleForgetMpin(data);
			
			}
		
		  })

		//   console.log("formik.values == ",formik.values);
	

		  const handleResendOTP = async() =>{
			try{
			 setSeconds(60);
			 setSendOTPhideShow(true);
			 setSendOTPDisable(true);
			 setforgetMpinDisable(false);
			 const data ={
			   "phone": `${phoneNumber}`
			 }
			 const result = await API.post(API_URL.RESEND_OTP,data);
			 let response = await result.data;
			 console.log("response values of the resend otp",response);
			 if(response.status === 200){
			   toaster('success', response.message);
			 
			 }
			 
		   }
		   catch( error){
			 toaster('error', error);
		   }
		 }
	 
		 const x=otp.divcontainer;

		 const onBlurPhone = (event) =>{
        
       
			if (!event.target.value) {
			  setPhoneNumberError('Phone number must not be empty');
			  } else if ((event.target.value.length < 10) || (event.target.value.length > 10)  ) {
				setPhoneNumberError('Phone number must be 10 digits')
			  } 
			  else if (!mobileTest.test(event.target.value)) {
				// errors.phone = "Invalid phone number format";
				setPhoneNumberError("Invalid phone number");
			  }
			  else{
				setPhoneNumberError(null);
			  setPhoneNumber(event.target.value);
	  
			  formik.setFieldValue("phone",event.target.value);
			  }
			}

	return(
		<div className="content">
				<div className="container-fluid">
					
					<div className="row">
						<div className="col-md-8 offset-md-2">
							
						
							<div className="account-content">
								<div className="row align-items-center justify-content-center">
									<div className="col-md-7 col-lg-6 login-left">
										<img src={loginBanner} className="img-fluid" alt="Login Banner" />	
									</div>
									<div className="col-md-12 col-lg-6 login-right">
										<div className="login-header">
											<h3>Forgot Mpin?</h3>
											{/* <p className="small text-muted">Enter your email to get a mpin reset link</p> */}
										</div>
									
										<form onSubmit={formik.handleSubmit}>
										{(sendOTPhideShow == false)?(<p className="small text-muted">Enter your mobile number and click on SEND OTP for OTP</p>):null}
										<div>
                                        <div className="form-group">
                                            <input type="text" className="form-control " id="mobile"
                                            placeholder="Mobile Number"
											maxLength={10}
                						 onBlur={(event) =>onBlurPhone(event)}
                                            />
                                            </div>
                                            {(phoneNumberError !== null)? (<div className='error'>{phoneNumberError}</div>):(formik.touched.phone && formik.errors.phone) ? (<div className='error'>{formik.errors.phone}</div>) : null}
                                           </div>
										   <div className="text-right">
												<Link to="/home/login" className="forgot-link">Remember your mpin? </Link>
											</div>
											<button 
                                            // className="btn-primary btn-block btn-sm login-btn" 
                                            className={(sendOTPDisable == true) ? "btn-secondary btn-block btn-sm login-btn":"btn-primary btn-block btn-sm login-btn"} 
                                            type="button" 
                                            onClick={handleSendOTP} 
                                            // disabled={sendOTPhideShow}
                                            disabled={sendOTPDisable}
                                            > {x?'Send OTP':'Send OTP'}</button> 
										   {     
                                       
                                       x&&(

                                        <>
                                       {/*} <span>00:{counter}</span>*/}

                                      <div className="resendotp"><strong>Resend OTP in</strong> <span style={{color:"green",fontWeight:"bold",align:"center"}}> 00:{seconds}</span></div>

                                      {(resendOTPhideShow == false)?(<h6 className="text-muted">Click on RESEND OTP for OTP to be sent again</h6>):null} 

                                      <button 
                                      // className="btn-primary btn-block btn-sm login-btn mb-3"
                                      className={(resendOTPhideShow == true)?"btn-secondary btn-block btn-sm login-btn mb-3":"btn-primary btn-block btn-sm login-btn mb-3"} 
                                      type="button" 
                                      onClick={() =>handleResendOTP()} 
                                      disabled={resendOTPhideShow}> {'Resend OTP'}</button> 
  

                        
                                      <div>
                                        <div className="form-group">
                                        <input type="password" 
                                        className="form-control " 
                                        id="otp"
										maxLength={4}
                                        onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.otp}
                                                    placeholder="Enter OTP"
                                         />
                                        {/* <label className="focus-label" htmlFor="otp">Enter OTP</label> */}
                                    </div>
                                    {formik.touched.otp && formik.errors.otp ? <div className='error'>{formik.errors.otp}</div> : null}
                                    </div>
                                                                                                          
                                <div>
                                    <div className="form-group">
                                        <input type="password" 
                                        className="form-control floating" 
                                        id="mpin"
                                        maxLength={4}
                                        onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.mpin}
                                                    placeholder="Enter MPIN" />
                                        {/* <label className="focus-label" htmlFor="mpin">Enter MPIN</label> */}
                                    </div>
                                    {formik.touched.mpin && formik.errors.mpin ? <div className='error'>{formik.errors.mpin}</div> : null}
                                    </div>

									<div>
                                    <div className="form-group">
                                        <input type="password" 
                                        className="form-control floating" 
                                        id="confirmmpin"
                                        maxLength={4}
                                        onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.confirmmpin}
                                                    placeholder="Re-enter MPIN" />
                                        {/* <label className="focus-label" htmlFor="mpin">Enter MPIN</label> */}
                                    </div>
                                    {formik.touched.confirmmpin && formik.errors.confirmmpin ? <div className='error'>{formik.errors.confirmmpin}</div> : null}
                                    </div>


                                    
                                    </>

                                       )
                                        

                                        }
										
                                        <button className="btn btn-primary btn-block btn-lg login-btn" 
										disabled={forgetMpinDisable}
										type="submit">Reset Mpin</button>

										</form>
										
										
									</div>
								</div>
							</div>
						
							
						</div>
					</div>

				</div>

			</div>	
		 

	)


}

export default ForgotMpin;