import * as constance from "../constance/action.constance";
import {  
   // get all my_address
    GET_ALL_MY_ADDRESS_REQ,
    GET_ALL_MY_ADDRESS_SUCC,
    GET_ALL_MY_ADDRESS_ERR,

    // ADD MY ADDRESS
    ADD_MY_ADDRESS_REQUEST,
    ADD_MY_ADDRESS_SUCCESS,
    ADD_MY_ADDRESS_ERROR,

     // DELETE MY ADDRESS
     DELETE_MY_ADDRESS_REQUEST,
     DELETE_MY_ADDRESS_SUCCESS,
     DELETE_MY_ADDRESS_ERROR,

    //  UPDATE_MY_ADDRESS_DETAILS
    UPDATE_MY_ADDRESS_DETAILS_REQUEST,
    UPDATE_MY_ADDRESS_DETAILS_SUCCESS,
    UPDATE_MY_ADDRESS_DETAILS_ERROR,

    //GET ALL CITY
    GET_ALL_CITY_REQ,
    GET_ALL_CITY_ERR,
    GET_ALL_CITY_SUCC,


//GET ALL AREA_BY_CITY_ID
    GET_ALL_AREA_BY_CITY_ID_REQ,
    GET_ALL_AREA_BY_CITY_ID_ERR,
    GET_ALL_AREA_BY_CITY_ID_SUCC,
  

    
  } from "./action.type";

  // GET ALL  MY_ADDRESS
export function get_all_my_address_req(data,callback) {
    return {
      type: GET_ALL_MY_ADDRESS_REQ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_my_address_err(error) {
    return {
      type: GET_ALL_MY_ADDRESS_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_my_address_success(result) {
    return {
      type: GET_ALL_MY_ADDRESS_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }

  // UPDATE_MY_ADDRESS_DETAILS


export function update_my_address_details_success(result) {
  return {
    type: UPDATE_MY_ADDRESS_DETAILS_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function update_my_address_details_request(data,callback) {
  return {
    type: UPDATE_MY_ADDRESS_DETAILS_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function update_my_address_details_error(error) {
  return {
    type: UPDATE_MY_ADDRESS_DETAILS_ERROR,
    error: error,
    status: constance.ERROR,
  };
}


// ADD MY ADDRESS


export function add_my_address_success(result) {
  return {
    type: ADD_MY_ADDRESS_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function add_my_address_request(data,callback) {
  return {
    type: ADD_MY_ADDRESS_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function add_my_address_error(error) {
  return {
    type: ADD_MY_ADDRESS_ERROR,
    error: error,
    status: constance.ERROR,
  };
}



// DELETE MY ADDRESS


export function delete_my_address_success(result) {
  return {
    type: DELETE_MY_ADDRESS_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function delete_my_address_request(data,callback) {
  return {
    type: DELETE_MY_ADDRESS_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function delete_my_address_error(error) {
  return {
    type: DELETE_MY_ADDRESS_ERROR,
    error: error,
    status: constance.ERROR,
  };
}



// GET ALL_CITY
export function get_all_city_req(data,callback) {
  return {
    type: GET_ALL_CITY_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_all_city_err(error) {
  return {
    type: GET_ALL_CITY_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_all_city_success(result) {
  return {
    type: GET_ALL_CITY_SUCC,
    result,
    status: constance.SUCCESS,
  };
}


// GET ALL AREA_BY_CITY_ID
export function get_all_area_by_city_id_req(data,callback) {
  return {
    type: GET_ALL_AREA_BY_CITY_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_all_area_by_city_id_err(error) {
  return {
    type: GET_ALL_AREA_BY_CITY_ID_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_all_area_by_city_id_success(result) {
  return {
    type: GET_ALL_AREA_BY_CITY_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };
}
