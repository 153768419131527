import React, { Component } from "react";
import HomeBanner from "./homebanner";
import HomePoints from "./points";
import Connectus from "./connectus";
import Location from "./location";
import Aboutus from "./about";
import Article from "./article";
// import Appointment from "./appointment";
import OurPackages from "./packages";
import OurTests from "./tests";
import Counts from "./counts";
import Testimonial from "./testimonials";
// import Content from "./AboutContent";

  

class Home extends Component {
  render() {
    return (
      <div>
        <div className="main-wrapper">
          <HomeBanner />
         <HomePoints />
         <OurTests/>
         <OurPackages />
         <Connectus />
         <Location />
        <Aboutus />
         <Testimonial />
        <Counts/>
        <Article />
         {/* <Content />        */}
        {/* <Appointment /> */}
       

        </div>
      </div>
    );
  }
};
export default Home;
