import React, { useState } from "react";
import AppContainer from './appcontainer.jsx';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { onMessageListener} from "./firebaseInit";
import Notifications from "./client/utils/Notifications/Notifications";
import ReactNotificationComponent from "./client/utils/Notifications/ReactNotification";
// import config from 'config';

const AppRouter = (props) => {

    const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  console.log(show, notification);
  console.log("onmessageLitener call pre" );

  onMessageListener().then((payload) => {
    console.log("onmessageLitener call post" );
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log("payload notification === ",payload);

    })
    .catch((err) => console.log("failed: ", err));
    return(
        <>
        <Router >
            <Route render={(props)=> <AppContainer {...props}/>} />
        </Router>
        {show ? (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )}
      <Notifications />
        </>
    );
    
}


export default AppRouter;