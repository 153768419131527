import React,{ useState, useEffect } from 'react';
import { createBrowserHistory } from "history";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import moment from "moment";
import { API_URL_ROOT } from '../../../../redux-services/actions/constance/action.constance';
import IMG01 from '../../../../assets/images/patients/avatar.png';
import './navlinkStyle.css';
import { logout_req } from "../../../../redux-services/actions/action.users/action.function";
import {  logout_request } from "../../../../redux-services/actions/action.logout/action.function";
import { API_URL } from '../../../../redux-services/api/api.js';
import { API } from '../../../../utils/networkApi.js';
import {persistor} from '../../../../redux-services/store/create.store.js';
import { toast } from "react-toastify"
export const DashboardSidebar = (props) => {
	const history = createBrowserHistory();
	 let pathnames = window.location.pathname;
	 const url = pathnames.split("/").slice(2).join("/");
	// const onHandleMobileMenu = () => {
	// 	var root = document.getElementsByTagName("html")[0];
	// 	root.classList.add("menu-opened");
	//   };
	
	//   const onhandleCloseMenu = () => {
	// 	var root = document.getElementsByTagName("html")[0];
	// 	root.classList.remove("menu-opened");
	//   };


	//   useEffect(() => {
	// 	$(".main-nav a").on("click", function (e) {
	// 	  if ($(this).parent().hasClass("has-submenu")) {
	// 		e.preventDefault();
	// 	  }
	// 	  if (!$(this).hasClass("submenu")) {
	// 		$("ul", $(this).parents("ul:first")).slideUp(350);
	// 		$("a", $(this).parents("ul:first")).removeClass("submenu");
	// 		$(this).next("ul").slideDown(350);
	// 		$(this).addClass("submenu");
	// 	  } else if ($(this).hasClass("submenu")) {
	// 		$(this).removeClass("submenu");
	// 		$(this).next("ul").slideUp(350);
	// 	  }
	// 	});
	//   }, []);
	const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [photo, setPhoto] = useState(null);
  const dateValue = moment(new Date()).format("YYYY-MM-DD");
  const [dateOfBirth, setDateOfBirth] = useState(dateValue);
  const [gender, setGender] = useState("");
  const [dateOfBirthStatus, setDateOfBirthStatus] = useState(false);

  
	

  const DateConversion = (data) =>{

	if(data == null){
		setDateOfBirthStatus(true);
	}
	const DateObtained = moment(data).format("DD-MM-YYYY");
        // console.log("age", DateObtained);
        const AgeValue = moment().diff(moment(`${DateObtained}`, "DD-MM-YYYY"),"years",false);
		 
		setDateOfBirth(AgeValue);

  }


	useEffect(() =>{
		setFirstName((props.user_by_id && (props.user_by_id.length!=0) && props.user_by_id.first_name));
        setLastName((props.user_by_id && (props.user_by_id.length!=0) && props.user_by_id.last_name));
        setPhoto((props.user_by_id && (props.user_by_id.length!=0) && props.user_by_id.photo));
        setEmail((props.user_by_id && (props.user_by_id.length!=0) && props.user_by_id.email));
		DateConversion((props.user_by_id && (props.user_by_id.length!=0) && props.user_by_id.date_of_birth));
		
		if((props.user_by_id && (props.user_by_id.length!=0) && props.user_by_id.gender)){
			if((props?.user_by_id?.gender == "male") || props?.user_by_id?.gender == "Male"){
				setGender("Male");
			}
			else if((props?.user_by_id?.gender == "female") || (props?.user_by_id?.gender == "Female")){
				setGender("Female");
			}
			

		}
		


	},[props.user_by_id])
	console.log("url from side bar",url);

	const verify_logout= async() =>{
		if(props.user?.userId != null){
		  
		//  await props.user_logout_req(null,(error,success)=>{
		//     if(error){
			  
		//       console.log("error",error); 
		//     } 
		  
		//     if(success){
		  
		//     if(success.status == 200){
		//       props.logout_req();
		//     }
		//     }
			
		//   })
	  
		const result = await API.get(API_URL.VERIFY_LOGOUT,API.getHeaders());
				   let response = await result.data;
				   console.log("response values of logout api ",response);
				   if(response.status === 200){
					//  toaster('success', response.message);
			//   props.logout_req();
			localStorage.removeItem('token');
			localStorage.clear();
			persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
	history.push("/home/login");
      window.location.reload();
	  toast.success("Log out successful", {
		position: toast.POSITION.BOTTOM_RIGHT
	  })
				   
				   }
		} 
		
	  }
	
    return(
            	<div className="profile-sidebar">
								<div className="widget-profile pro-widget-content">
									<div className="profile-info-widget">
										<a href="#0" className="booking-doc-img">
											<img 
											src={(photo == null)?IMG01:`${API_URL_ROOT}/${photo}`}
											// src={IMG01} 
											alt="User" />
										</a>
										<div className="profile-det-info">
											<h3>{((firstName != null) && (lastName != null))?`${firstName} ${lastName}` : "User name"}</h3>
											<div className="patient-details">
												{/* <h5><i className="fas fa-id-badge"></i>1WS140003</h5> */}
												<h5><i className="fas fa-venus-mars"></i> {(gender != null)? gender:"None"}</h5>
												{(dateOfBirthStatus == true)?null:(<h5 className="mb-0"><i className="fas fa-birthday-cake"></i> {(dateOfBirth != null)? dateOfBirth:null} years</h5>)}
												
											</div>
										</div>
									</div>
								</div>
								<div className="dashboard-widget">
									<nav className="dashboard-menu">
										<ul>
										
											<li  className={url.includes("dashboard") ? "active" : ""}>
												<Link to="/users/dashboard"   >
													<i className="fas fa-columns"></i><span className="dashboard-menu-span"
													>Dashboard</span>
												</Link>
											</li>
											
											<li className={url.includes("familyMembers") ? "active" : ""} >
												<Link to="/dashboard/familyMembers" >
													<i className="fas fa-bookmark"></i><span className="dashboard-menu-span">Family Members</span>
												</Link>
											</li>
											{/* <li>
												<Link to="/doctor/chat-doctor">
													<i className="fas fa-comments"></i>
													<span>Message</span>
													<small className="unread-msg">23</small>
												</Link>
											</li> */}

											<li className={url.includes("myAddress") ? "active" : ""}>
												<Link to="/dashboard/myAddress"  >
													<i className="fas fa-address-book"></i>
													<span className="dashboard-menu-span">My Address</span>
												</Link>
											</li>

											{/* <li>
											<ul className={`has-submenu`}>
											<a href="#0"><i className="fas fa-user-cog"></i> My Account</a>
											<ul className={`submenu`}>
											<li className={pathnames.includes("/users") ? "active" : ""}>
											<Link to="/patient/profile" onClick={()=>onhandleCloseMenu()}>
													<i className="fas fa-user-cog"></i>
													<span>Profile Settings</span>
													</Link>
													</li>

													<li className={pathnames.includes("/users") ? "active" : ""}>
												<Link to="/patient/change-password" onClick={()=>onhandleCloseMenu()}>
													<i className="fas fa-lock"></i>
													<span>Change Password</span>
												</Link>
											</li>

													</ul>
													</ul>
											</li> */}
											
											
											<li className={url.includes("Orders") ? "active" : ""} >
												<Link to="/dashboard/Orders" >
													<i className="fas fa-border-all"></i>
													<span className="dashboard-menu-span">My Orders</span>
												</Link>
											</li>
											<li className={url.includes("Reports") ? "active" : ""} >
												<Link to="/dashboard/Reports" >
													<i className="fas fa-toolbox"></i>
													<span className="dashboard-menu-span">Reports</span>
												</Link>
											</li>

											
											<li className={url.includes("Support") ? "active" : ""} >
												<Link to="/dashboard/Support" >
													<i className="fas fa-tools"></i>
													<span className="dashboard-menu-span">Support</span>
												</Link>
											</li>

											<li className={url.includes("Feedback") ? "active" : ""} >
												<Link to="/dashboard/Feedback" >
													<i className="fas fa-tools"></i>
													<span className="dashboard-menu-span">Feedback</span>
												</Link>
											</li>
											<li  className={url.includes("profile") ? "active" : ""}>
												<Link to="/dashboard/profile" >
													<i className="fas fa-user-cog"></i>
													<span className="dashboard-menu-span">Profile Settings</span>
												</Link>
											</li>
											<li  className={url.includes("change-mpin") ? "active" : ""}>
												<Link to="/dashboard/change-mpin" >
													<i className="fas fa-lock"></i>
													<span className="dashboard-menu-span">Change MPIN</span>
												</Link>
											</li>
											<li>
												<Link>
													<i className="fas fa-sign-out-alt"></i>
													<span className="dashboard-menu-span" onClick={() =>verify_logout()}>Logout</span>
												</Link>
											</li>
										</ul>
									</nav>
								</div>

							</div>
    );
}
const mapDispatchToProps = (dispatch) => {
	return {
		 logout_req:() =>dispatch(logout_req()),
		 user_logout_req: (data,callback) =>dispatch(logout_request(data,callback)),
    //  user_by_id_req: (data,callback) =>dispatch(get_user_by_id_req(data,callback)),
	};
  };
  const mapStateToProps = (state) => {
	return {
	  status: state.user.status,
	  error: state.user.error,
    user_by_id:state.user.user_by_id,
	  user: state.user.user,
	  isLoggedIn: state.user.isLoggedIn,
	  requesting: state.user.requesting,
  
  
	};
  };
  export default connect(mapStateToProps, mapDispatchToProps)(DashboardSidebar);
