import React,{ useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import moment from "moment";
import {useHistory} from "react-router";
import DashboardSidebar from '../../sidebar/sidebar.jsx';
import IMG01 from '../../../../../assets/images/placeholderImg300x300.png';
import {API_URL_ROOT} from "../../../../../redux-services/api/api.js";
import StickyBox from "react-sticky-box";
// import DatePicker from "react-datepicker";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
const optionsRdBtn = ["Male", "Female"];

function FamilyMemberView(props) {
    const [gender, setGender] = useState("");
    const dateValue = moment(new Date()).format("YYYY-MM-DD");
  const [dateOfBirth, setDateOfBirth] = useState(dateValue);
    const history = useHistory();
    const dataValue = history.location.state
    console.log("data passed form index to view",dataValue);
    const classes = useStyles();
    useEffect(() => {
        setDateOfBirth(moment(dataValue.patient_date_of_birth).format("YYYY-MM-DD"))
        if((dataValue.patient_gender == "male") || dataValue.patient_gender == "Male"){
            setGender("Male");
        }
        else if((dataValue.patient_gender == "female") || (dataValue.patient_gender == "Female")){
            setGender("Female");
        }
     
      }, []);

    const handleBack = () => {
        props.history.goBack();
      };
    return (
        <div>
        <div className="breadcrumb-bar">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/home">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Family Member View</li>
                        </ol>
                    </nav>
                    <h2 className="breadcrumb-title">Family Member View</h2>
                </div>
            </div>
        </div>
    </div>
    <div className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-5 col--lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                    < DashboardSidebar />
                </StickyBox>
               </div>

               <div className="col-md-7 col-lg-8 col-xl-9">
<div className="card">
<div className="card-body">
    
 
  
        <div className="row form-row">
            <div className="col-12 col-md-12">  
                <div className="form-group">
                    <div className="change-avatar">
                        <div className="profile-img">
                        <img
                className="avatar-img w-25 h-25 rounded-circle mr-1"
                src={
                  dataValue.patient_image == null
                    ? IMG01
                    : `${API_URL_ROOT}/${dataValue.patient_image}`
                }
                alt=""
              />
                        </div>
                        {/* <div className="upload-img">
                            <div className="change-photo-btn">
                                <span><i className="fa fa-upload"></i> Upload Photo</span>
                                <input type="file" className="upload" />
                            </div>
                            <small className="form-text text-muted">Allowed JPG, GIF or PNG. Max size of 2MB</small>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={dataValue.patient_firstname}
                            disabled="disabled"
                           
                          />
                        </div>
                        
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                        
                            
                            value={dataValue.patient_lastname}
                            disabled="disabled"
                           
                          />
                        </div>
                        
                      </div>
                     
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Gender</label>
                          <div className="row ml-4">
                            {optionsRdBtn.map((options, idxo) => (
                              <div
                                key={idxo}
                                className="text-primary col-md-3"
                                id={idxo}
                              >
                                <input
                                  className="form-check-input mr-2"
                                  type="radio"
                                  id={`optnRd[${idxo}]`}
                                  value={options}
                                  name={`optnameVal`}
                                  // checked={
                                  //   radioBtn == idxo
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={gender === options}
                                />
                                <label>{options}</label>
                              </div>
                            ))}
                          </div>
                          
                      </div>
                      
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Relation</label>
                          <input
                            type="text"
                            // placeholder="Enter relation ..."
                            // defaultValue="Relation"
                            className="form-control"
                            value={dataValue.relation}
                            disabled="disabled"
                            
                          />
                        </div>
                        
                         

                          
                        </div>

                      <div className="col-12 col-md-6">
                      <div className="form-group">
                            <label>Date of Birth</label>
                            
                              <div className="">
                                {/* <input type="text" className="form-control datetimepicker" defaultValue="24-07-198" */}
                                {/* <DatePicker
                                  className="form-control datetimepicker"
                                //   dateFormat="yyyy-mm-dd"
                                  selected={dateOfBirth}
                                  onChange={(date) => handleDateOfBirth(date)}
                                /> */}
                                <TextField
        id="date"
        type="date"
        value={dateOfBirth}
        className={classes.textField }
        InputLabelProps={{
          shrink: true,
        }}
        disabled="disabled"
      />
                              </div>
                            </div>
                            
                          </div>
                          <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Mobile</label>
                          <input
                            type="text"
                            // defaultValue="+91 9876543210"
                            className="form-control"
                            value={`+91 ${dataValue.patient_mobile}`}
                            disabled="disabled"
                          />
                        </div>
                        
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Email ID</label>
                          <input
                            type="email"
                            className="form-control"
                            // defaultValue="keshav@gmail.com"
                            value={dataValue.patient_email}
                            disabled="disabled"
                           
                          />
                        </div>
                        
                      </div>
                     

            


         
        </div>
   
        <div className="submit-section">
                    <button type="button" className="btn btn-primary submit-btn"  onClick={handleBack}>Back</button>
                </div>
</div>
</div>
</div>
            </div>
        </div>
    </div>      
    </div>
);
        }

        const useStyles = makeStyles((theme) => ({
            container: {
              display: 'flex',
              flexWrap: 'wrap',
            },
            textField: {
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
              width: 200,
            },
          }));


export default FamilyMemberView
