import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    // get_all_custom_notification
    get_all_custom_notification_req,
    get_all_custom_notification_err,
    get_all_custom_notification_success,

    //post_custom_notification_read_status_by_id
    post_custom_notification_read_status_by_id_success,
    post_custom_notification_read_status_by_id_request,
    post_custom_notification_read_status_by_id_error


} from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
import {API} from '../../../utils/networkApi';

import { 
    //Get  all Custom_notification
GET_ALL_CUSTOM_NOTIFICATION_REQ,

// POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID
POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID_REQ,
} from "./action.type";
import toaster from "../../../utils/ReactTostify";
import {isLoading} from '../action.loader/action.function.js';


    function* allCustomNotificationSaga(payload) {
        const {data,callback} =payload;
          try {
              const store = yield select();
              // const userId =  store.user.user?.userId;
          console.log("store values ==========", store?.user?.user?.userId)
          yield put(isLoading(true));
            let response = yield call(API.get,API_URL.GET_CUSTOM_NOTIFICATION,API.getHeaders());
            yield put(isLoading(false));
            const result  = response.data;
            console.log("all custom notification saga result === ", result);
            yield put(get_all_custom_notification_success(result));
            callback(null,result);
          }
          catch (error) {
            yield put(isLoading(false));
            yield put(get_all_custom_notification_err(error));
            callback(error,null);
            
          }
        }

        function* postCustomNotificationReadStatusSaga(payload) {
            const {data,callback} =payload;
            console.log("data sent to post_custom_notification_read_status saga", data)
            try {
             
              yield put(isLoading(true));
              let response = yield call(API.post,API_URL.POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID, data,API.getHeaders());
              yield put(isLoading(false));
              const result  = response.data;
              console.log("post_custom_notification_read_status response saga",result);
              yield put(post_custom_notification_read_status_by_id_success(result))
              callback(null,result);
            }
            catch(error) {
                yield put(isLoading(false));
              yield put(post_custom_notification_read_status_by_id_error(error));
              callback(error,null);
              
              toaster("error", error);
            }
          }   

          export default function* customNotificationSaga() { 
            yield takeEvery(GET_ALL_CUSTOM_NOTIFICATION_REQ,allCustomNotificationSaga );
            yield takeEvery(POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID_REQ, postCustomNotificationReadStatusSaga);
           
            
        } 