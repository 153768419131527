import * as constance from "../constance/action.constance";
import {  
   // get all DELETE_UPLOADPRESCRIPTION
GET_ALL_DELETE_UPLOADPRESCRIPTION_REQ ,
GET_ALL_DELETE_UPLOADPRESCRIPTION_ERR ,
GET_ALL_DELETE_UPLOADPRESCRIPTION_SUCC,
  

    // DELETE DELETE_UPLOADPRESCRIPTION
   DELETE_UPLOADPRESCRIPTION_REQUEST,
   DELETE_UPLOADPRESCRIPTION_ERROR,
   DELETE_UPLOADPRESCRIPTION_SUCCESS,

   //  UPDATE_DELETE_UPLOADPRESCRIPTION
//    UPDATE_DELETE_UPLOADPRESCRIPTION_REQUEST ,
//    UPDATE_DELETE_UPLOADPRESCRIPTION_ERROR ,
//    UPDATE_DELETE_UPLOADPRESCRIPTION_SUCCESS,


  } from "./action.type";

  // GET ALL  FAMILY_MEMBER
export function get_all_delete_uploadprescription_req(data,callback) {
    return {
      type: GET_ALL_DELETE_UPLOADPRESCRIPTION_REQ ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_delete_uploadprescription_err(error) {
    return {
      type: GET_ALL_DELETE_UPLOADPRESCRIPTION_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_delete_uploadprescription_succ(result) {
    return {
      type: GET_ALL_DELETE_UPLOADPRESCRIPTION_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }

  


export function delete_uploadprescription_success(result) {
  return {
    type: DELETE_UPLOADPRESCRIPTION_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function delete_uploadprescription_request(data,callback) {
  return {
    type: DELETE_UPLOADPRESCRIPTION_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function delete_uploadprescription_error(error) {
  return {
    type: DELETE_UPLOADPRESCRIPTION_ERROR,
    error: error,
    status: constance.ERROR,
  };
}