import * as constance from "../constance/action.constance";
import { 
    // CREATE FEEDBACK
    CREATE_FEEDBACK_SUCCESS,
    CREATE_FEEDBACK_REQUEST,
    CREATE_FEEDBACK_ERROR,
   
   //  GET ALL FEEDBACK Question
     GET_ALL_FEEDBACK_QUESTION_REQ ,
     GET_ALL_FEEDBACK_QUESTION_ERR,
     GET_ALL_FEEDBACK_QUESTION_SUCC,
   
   
   } from "./action.type";


   // GET_ALL_FEEDBACK_QUESTION

export function get_all_feedback_question_req(data,callback) {
    return {
      type:GET_ALL_FEEDBACK_QUESTION_REQ  ,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  
  export function get_all_feedback_question_err(error) {
    return {
      type: GET_ALL_FEEDBACK_QUESTION_ERR,
      error,
      status: constance.ERROR,
    };
  }
  
  export function get_all_feedback_question_success(result) {
    return {
      type: GET_ALL_FEEDBACK_QUESTION_SUCC,
      result,
      status: constance.SUCCESS,
    };
  }
  
  
  //CREATE_FEEDBACK
  export function create_feedback_success(result) {
      return {
        type: CREATE_FEEDBACK_SUCCESS,
        result,
        status: constance.SUCCESS,
      };
    }
    export function create_feedback_request(data,callback) {
      return {
        type: CREATE_FEEDBACK_REQUEST,
        data,
        callback,
        status: constance.REQUESTING,
      };
    }
    export function create_feedback_error(error) {
      return {
        type:CREATE_FEEDBACK_ERROR,
        error: error,
        status: constance.ERROR,
      };
    }
   