import * as delete_uploadPrescription_ActionType from "../../actions/action. uploaddiscription/action.type";

const deleteupload = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    // getallfamily_member_error: null,
    // getallfamily_member_status: null,
    // getallpopularfamily_member_error: null,
    // getallpopularfamily_member_status: null,

    // family_member

   
    get_all_delete_prescription: [],
    //  all_family_member: null,
    //  get_all_popular_family_member: [],
    //  all_popular_family_member: null,

    //  family_member_by_id: [],
    //  family_member_by_id_loading: false,
    //  family_member_by_id_error: null,
    //  family_member_enable_disable_status: null,
    //  update_family_member_details: null,
     delete_upload_prescription: null
    },
    action
) =>{

    switch (action.type) {
    
        //family_member reducers type

          // all family_members get
          case delete_uploadPrescription_ActionType.GET_ALL_DELETE_UPLOADPRESCRIPTION_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case delete_uploadPrescription_ActionType.GET_ALL_DELETE_UPLOADPRESCRIPTION_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_delete_prescription: action.result,
            });
          case delete_uploadPrescription_ActionType.GET_ALL_DELETE_UPLOADPRESCRIPTION_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

             // delete family_member
    case delete_uploadPrescription_ActionType.DELETE_UPLOADPRESCRIPTION_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
      });
    case delete_uploadPrescription_ActionType.DELETE_UPLOADPRESCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        delete_family_member: action.result,
      });
    case delete_uploadPrescription_ActionType.DELETE_UPLOADPRESCRIPTION_ERROR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default deleteupload ;
    
    
    