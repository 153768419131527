import React, {useState} from "react";
import {useParams} from "react-router-dom";
 import moment from "moment";
 import {connect} from "react-redux";
 import {useFormik} from "formik";
import DatePicker from "react-datepicker";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup';
import Select from "react-select";

// import IMG01 from "../../../../../assets/images/patients/patient.jpg";
import IMG01 from "../../../../../assets/images/patients/avatar.png";
import toaster from "../../../../../utils/ReactTostify";

import {add_family_member_request} from "../../../../../redux-services/actions/action.familymember/action.function.js"
import { values } from "lodash";
// import Select from "rc-select";
const optionsRdBtn = ["Male", "Female"];


const options = [
  { value: 'Self', label: 'Self' },
  { value: 'Father', label: 'Father' },
  { value: 'Mother', label: 'Mother' },
  { value: 'Wife', label: 'Wife' },
  { value: 'Husband', label: 'Husband' },
  { value: 'Childern', label: 'Children' },
  { value: 'Uncle', label: 'Uncle' },
  { value: 'Other', label: 'Other' },
 
];

function AddFamilyMember(props) {
    // const phoneVal = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const phoneVal = new RegExp(/^[6-9]{1}[0-9]{9}$/);
    
    const emailTest = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);
    // const relationArray = ["self", "father", "mother", "wife", "husband", "children", "aunty", "uncle", "other"];
    // console.log("arrayvalue",relationArray);

    const dateValue = moment(new Date()).format("YYYY-MM-DD");
  const [dateOfBirth, setDateOfBirth] = useState(dateValue);
  const [memberImage, setMemberImage] = useState(null);
  const [memberImageState, setMemberImageState] = useState(false);
  const [relation, setRelation]=useState("");
  const [submitButton, setSubmitButton] = useState(false);

// console.log("relation",relation)


  // const options = [
  //   { value: 'self', label: 'self' },
  //   { value: 'father', label: 'father' },
  //   { value: 'mother', label: 'mother' },
  //   { value: 'wife', label: 'wife' },
  //   { value: 'husband', label: 'husband' },
  //   { value: 'childern', label: 'children' },
  //   { value: 'uncle', label: 'uncle' },
  //   { value: 'other', label: 'other' },
   
  // ];

  

  // console.log("set date ======", dateOfBirth);

  

  
  const handleBack = () => {
    // props.history.goBack();
    props.reloadFamilyMembers();
  };

  const classes = useStyles();


  // const handleRelation = (e) => {
  //   console.log("relation_value", e);
  //   formik.setFieldValue("relation", e.value);
  //   // console.log("relation_values", relation);  
  //   //   console.log("value", e.value);
  //   setRelation(relation);
  // };


  const handleDateOfBirth = (event) =>{
    //  const Dateset = moment(data).format("YYYY-MM-DD");
     console.log("date of birth == " ,event.target.value);
    setDateOfBirth(event.target.value);
    formik.setFieldValue("dateofbirth",event.target.value);
  }
  const handleMemberPhotoUpload = (event) =>{
    formik.setFieldValue("memberimage", event.currentTarget.files[0]);
    if(event.target.files.length !== 0){
      setMemberImage(URL.createObjectURL(event.target.files[0]));
      setMemberImageState(true);
    }
  }
  
  const handleRadioOptn = (event) =>{
    formik.setFieldValue("gender", event.target.value);
  }

  const validate = Yup.object({
        firstname: Yup.string()
        .max(20,'Must be 20 character or less')
        .required('First name must not be empty'),
        lastname: Yup.string()
        .max(20,'Must be 20 character or less')
        .required('Last name must not be empty'),
        dateofbirth: Yup.string().required("Date of birth is required")
        .test("is-greater", "Date of birth should not be greater than present date", function(value) {
          return moment(value,"YYYY-MM-DD").isSameOrBefore(moment().format("YYYY-MM-DD"));
        }),
        mobile: Yup.string().matches(phoneVal, 'phone number is not valid')
            .min(10, 'Mobile number should include 10 character')
            .max(10, 'Mobile number allowed only 10 character')
            .required('mobile number required'),
            email: Yup.string().matches(emailTest, 'Invalid email address'),

        // .required('Email address is required'),   
        relation: Yup.string()
        .max(20,'Must be 20 character or less')
        .required('Relation is required'), 
        
        gender: Yup.string().required('Gender must not be empty'),
    
        memberimage:Yup.mixed(),
      
    
    })
    
    console.log("relation value_is",relation)

  const formik = useFormik({
    initialValues: 
    {
        "mobile":"",
        "dateofbirth":"",
        "firstname":"",
        "lastname":"",
        "email":"",
        "relation":relation,
        "memberimage":"",
        "gender":""
        

    },
    validationSchema: validate,
    onSubmit: async (values) => {
      setSubmitButton(true);
        console.log("values submission", values);
        console.log("user id ==", props.user.userId);
        var dt = formik.values;
        const memberImage = (dt.memberimage =="")?null:dt.memberimage;

        const formData = new FormData();
        formData.append('first_name', dt.firstname);
        formData.append('last_name',dt.lastname);
        formData.append('mobile', dt.mobile);
        formData.append('date_of_birth', dt.dateofbirth);
        formData.append('gender', dt.gender);
        formData.append('email', dt.email);
        formData.append('relation', dt.relation);
        formData.append('patient_image',memberImage);
        formData.append('user_id', props.user.userId);
        
      // console.log("values submission formData", relation);
        

           await props.add_family_member(formData,(error,success)=>{
            setSubmitButton(false);
			if(error){
			  
			  console.log("error",error);
			}    
			if(success.status == 200 ){
        toaster('success', success.message);
                handleBack();
			 
		   }
		   });
        //   await handleBack();

        //     var data = {
            
        //     first_name:dt.firstname,
        //     last_name:dt.lastname,
        //     mobile:dt.mobile,
        //     date_of_birth:dt.dateofbirth,
        //     gender:dt.gender,
        //     email:dt.email,
        //     relation:dt.relation,
        //    patient_image:dt.memberimage,
        //    user_id: props.user.userId
        //        };
        //        console.log("values submission jsonData", data);

    },
    onReset: (values) => {}
    

})
console.log("set realtion ======",formik.values);
  return (
    <div>
      <div className="content">
        <div className="container-fluid">
            <div className="col-md-12 col-lg-12 col-xl-12">
                  <form onSubmit={formik.handleSubmit}>
                    <div className=" form-row">
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <div className="change-avatar">
                            <div className="profile-img">
                             
                              { memberImageState?(
                      <img
            src={(memberImage != null)?memberImage:null}
            className="w-25 h-25"
            alt={"member photo"}
          />
                    ):
                    (<img src={IMG01} alt="User" />)

                    }
                            </div>
                            <div className="upload-img">
                              <div className="change-photo-btn">
                                <span>
                                  <i className="fa fa-upload"></i> Upload Photo
                                </span>
                                <input type="file" className="upload" onChange={(event) => handleMemberPhotoUpload(event)} />
                              </div>
                              <small className="form-text text-muted">
                                Allowed JPG, PNG. Max size of 2MB <span className="errorcolor ml-0">*</span>
                              </small>
                              {
            formik.touched.memberimage && formik.errors.memberimage ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.memberimage}
              </div>
            ) : null}
                            </div>
                            
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label className="pr-0">First Name <span className="errorcolor ml-0">*</span></label> 
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter first name ..."
                            // defaultValue={`Family Member Name ${member_id}`}
                            // disabled="disabled"
                            {...formik.getFieldProps("firstname")}
                          />
                        </div>
                        {
            formik.touched.firstname && formik.errors.firstname ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.firstname}
              </div>
            ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Last Name <span className="errorcolor ml-0">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter last name ..."
                            // defaultValue={`Family Member Name ${member_id}`}
                            // disabled="disabled"
                            {...formik.getFieldProps("lastname")}
                          />
                        </div>
                        {
            formik.touched.lastname && formik.errors.lastname ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.lastname}
              </div>
            ) : null}
                      </div>
                     
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Gender <span className="errorcolor ml-0">*</span></label>
                          <div className="row ml-2">
                            {optionsRdBtn.map((options, idxo) => (
                              <div
                                key={idxo}
                                className="text-primary col-md-4"
                                id={idxo}
                              >
                                <input
                                  className="form-check-input mr-2"
                                  type="radio"
                                  id={`optnRd[${idxo}]`}
                                  value={options}
                                  name={`optnameVal`}
                                  // checked={
                                  //   radioBtn == idxo
                                  //     ? true
                                  //     : false
                                  // }
                                  onChange={(event) => {
                                    handleRadioOptn(event);
                                  }}
                                />
                                <label>{options}</label>
                              </div>
                            ))}
                          </div>
                          
                      </div>
                      {
            formik.touched.gender && formik.errors.gender ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.gender}
              </div>
            ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Relation <span className="errorcolor ml-0">*</span></label>
                         <Select
                          placeholder="select relation"
       
        onChange={(event) => {
          setRelation(event);
          formik.setFieldValue("relation",event.value);
        }}
        value={relation}
        options={options}
        
      /> 

                           {/* <input
                            type="text"
                            placeholder="Enter relation ..."
                            // defaultValue="Relation"
                            className="form-control"
                            {...formik.getFieldProps("relation")}
                          />  */}
                        </div>
                        {
            formik.touched.relation && formik.errors.relation ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.relation}
              </div>
            ) : null}
                         

                          
                        </div>

                      <div className="col-12 col-md-6">
                      <div className="form-group">
                            <label>Date of Birth  <span className="errorcolor ml-0">*</span></label>
                            
                              <div className="">
                                {/* <input type="text" className="form-control datetimepicker" defaultValue="24-07-198" */}
                                {/* <DatePicker
                                  className="form-control datetimepicker"
                                //   dateFormat="yyyy-mm-dd"
                                  selected={dateOfBirth}
                                  onChange={(date) => handleDateOfBirth(date)}
                                /> */}
                                <TextField
        id="date"
        type="date"
        defaultValue={dateOfBirth}
        className={classes.textField }
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(date) => handleDateOfBirth(date)}
      />
                              </div>
                            </div>
                            {
            formik.touched.dateofbirth && formik.errors.dateofbirth ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.dateofbirth}
              </div>
            ) : null}
                          </div>
                          <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Mobile<span className="errorcolor ml-0">*</span></label>
                          <input
                            type="text"
                            // defaultValue="+91 9876543210"
                            maxLength={10}
                            className="form-control"
                            placeholder="Enter mobile number here ..."
                            {...formik.getFieldProps("mobile")}
                          />
                        </div>
                        {
            formik.touched.mobile && formik.errors.mobile ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.mobile}
              </div>
            ) : null}
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          {/* <label>Email ID <span className="errorcolor ml-0">*</span></label> */}
                          <label>Email ID</label>

                          <input
                            type="email"
                            className="form-control"
                            // defaultValue="keshav@gmail.com"
                            placeholder="Enter email address here .."
                            {...formik.getFieldProps("email")}
                          />
                        </div>
                        {
            formik.touched.email && formik.errors.email ? (
              <div
                style={{
                  color: "red"
                }}
              >
                {formik.errors.email}
              </div>
            ) : null}
                      </div>
                     


                     
                    </div>
                    <div className="mx-1 row">
                        <div className="submit-section mr-3">
                          <button
                            type="submit"
                            className="btn btn-primary submit-btn"
                           disabled={submitButton}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="submit-section">
                          <button
                            type="button"
                            className="btn btn-primary submit-btn"
                            // onClick={handleBack}
                           
                            onClick={props.toggle}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                  </form>
            </div>
        </div>
      </div>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const mapDispatchToProps = (dispatch) => {
    return {
        add_family_member: (data,callback) =>dispatch(add_family_member_request(data,callback)),
    };
  };
  const mapStateToProps = (state) => {
    return {
      status: state.user.status,
      error: state.user.error,
      all_family_member: state.familymember.all_family_member,
      user_by_id: state.user.user_by_id,
      user: state.user.user,
      isLoggedIn: state.user.isLoggedIn,
      requesting: state.user.requesting
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(AddFamilyMember);
