import * as cartActionType from "../../actions/action.cart/action.type";

const cart = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallcart_error: null,
    getallcart_status: null,
    // getallpopularcart_error: null,
    // getallpopularcart_status: null,

    // cart
      cartVal:[],
    add_to_cart: null,
    total_cost:null,
    get_all_cart: [],
     all_cart: null,
 

    
     update_cart_details: null,
     delete_from_cart: null
    },
    action
) =>{

    switch (action.type) {
    
        //cart reducers type
        
    
        //add new cart
        case cartActionType.ADD_TO_CART_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case cartActionType.ADD_TO_CART_SUCCESS:
          var totalAmount = 0;
          console.log("reducer result ===",action.result);
          if(action.result.serviceid){
            let parsedAmount = parseFloat(action.result.cost)
            totalAmount += parsedAmount;
            console.log("total value =",totalAmount);
          }
          if(action.result.package_id){
            totalAmount += parseFloat(action.result.package_cost);
          }
          

          return Object.assign({}, state, {
            status: action.status,
            // add_to_cart: action.result,
            cartVal: [...state.cartVal, action.result],
            total_cost: state.total_cost + totalAmount,
          });
        case cartActionType.ADD_TO_CART_ERROR:
          return Object.assign({}, state, {
            getallcart_status: action.status,
            getallcart_error: action.error,
          });
         


          //refresh cart
        case cartActionType.REFRESH_CART_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case cartActionType.REFRESH_CART_SUCCESS:
          var totalAmount = 0;
          console.log("reducer result REFRESH_CART_SUCCESS ===",action.result);
          return Object.assign({}, state, {
            status: action.status,
            cartVal: action.result,
            total_cost: 0,
          });
        case cartActionType.ADD_TO_CART_ERROR:
          return Object.assign({}, state, {
            status: action.status,
            cartVal: action.result,
            total_cost: 0,
          });




          // all carts get
        //   case cartActionType.GET_ALL_CART_REQ:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //     });
        //   case cartActionType.GET_ALL_CART_SUCC:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       all_cart: action.result,
        //     });
        //   case cartActionType.GET_ALL_CART_ERR:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       error: action.error,
        //     });

             // delete cart
    case cartActionType.DELETE_FROM_CART_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
      });
    case cartActionType.DELETE_FROM_CART_SUCCESS:
      var totalAmountReduced = 0;
      console.log("reducer result delete api===",action.result);
      if(action.result.length > 0){
        action.result.map((item, idx) =>{
          if(item.serviceid){
            totalAmountReduced += parseFloat(item.cost);
          }
          if(item.package_id){
            totalAmountReduced += parseFloat(item.package_cost);
          }
        })
      }
     else {
      totalAmountReduced = 0;
     }
      
      return Object.assign({}, state, {
        status: action.status,
        // delete_from_cart: action.result,
        cartVal: action.result,
        total_cost:totalAmountReduced,
      });
    case cartActionType.DELETE_FROM_CART_ERROR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default cart;
    