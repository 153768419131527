import React,  {Component } from 'react';
import './selectPatientStyle.css'

export class SelectPatient extends Component {
  constructor() {
    super();
    
    this.state = {
      showMenu: false,
    };
    
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  
  showMenu(event) {
    event.preventDefault();
    
    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }
  closeMenu(event) {
    
    if (!this.dropdownMenu.contains(event.target)) {
      
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });  
      
    }
  }
  
  render() {
    return (
      <div>
        <button onClick={this.showMenu}>
         Select Patient
        </button>
        {
          this.state.showMenu
            ? (
              <div
                className="menu w3-dropdown-content w3-bar-block"
                ref={(element) => {
                  this.dropdownMenu = element;
                }}
              >
                <button>Patient 1 </button>
                <button> Patient 2 </button>
                <button> Patient 3 </button>
              </div>
               )
               : (
                 null
               )
           }
         </div>
       );
     }
   }
   export default SelectPatient