import React, {useState, useEffect,useCallback} from "react";
import {useFormik} from "formik";
import Select from "react-select";
import * as Yup from "yup";
// import { useCookies } from 'react-cookie';
import Cookie from 'react-cookies';
import {connect} from "react-redux";
import {add_my_address_request,get_all_city_req, get_all_area_by_city_id_req, } from "../../../../../redux-services/actions/action.myaddress/action.function.js";
import MapView from "../../../../../utils/MapView";
import toaster from "../../../../../utils/ReactTostify";
import TextArea from "antd/lib/input/TextArea";
import { values } from "underscore";

function AddAddress(props) {
  // let { address_id } = useParams();
  const [mapViewBtn, setMapViewBtn] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);
  const [lat, setLat] = useState(12.974520);
  const [lng, setLng] = useState(77.543524);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [allCityData, setAllCityData] = useState([]);
  const [selectOptionsAllCity, setSelectOptionsAllCity] = useState([]);
  const [optionValueAllCity, setOptionValueAllCity] = useState([]);

  const [allAreaByCityIdData, setAllAreaByCityIdData] = useState([]);
  const [selectOptionsAllAreaByCityId, setSelectOptionsAllAreaByCityId] = useState([]);
  const [optionValueAllAreaByCityId, setOptionValueAllAreaByCityId] = useState([]);


  const [cityName, setCityName] = useState("");
  const [cityid, setCityid] = useState("");
  const [areaName, setAreaName] = useState("");
  const [areaid, setAreaid] = useState("");

 

  const optionsRdBtn = ["Home","office"];
  // const phoneVal =/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const phoneVal = new RegExp(/^[6-9]{1}[0-9]{9}$/);

    
    const [area, setArea] = useState("");
    const [streetName, setStreetName] = useState("");
    const [stateCnt, setStateCnt] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [houseNumber, setHouseNumber] = useState("");


   

    const getAllCityApiCall = async () => {
      await props.get_all_city(null, (error, success) => {
        if (error) {
          console.log("error", error);
        }
        if (success.status == 200) {
          if (success && success?.response.length > 0) {
            setAllCityData(success?.response);
            const optionsAllCity =
              success?.response.length > 0
                ? success.response.map((AllCitySelect, idx) => ({
                    city_id: AllCitySelect.city_id,
                    city_name: `${AllCitySelect.cityname}`
                  }))
                : null;
            setSelectOptionsAllCity(optionsAllCity);
          }
          if (success && success?.response.length <= 0) {
            console.log("data length empty", success?.message);
            
          }
        }
      });
    };


    

    const getAllAreaByCityIdApiCall = async (data) => {
      await props.get_all_area_by_city_id(data, (error, success) => {
        if (error) {
          console.log("error", error);
        }
        if (success.status == 200) {
          if (success && success?.areadata.length > 0) {
            setAllAreaByCityIdData(success?.areadata);
            const optionsAllAreaByCityId =
              success?.areadata.length > 0
                ? success.areadata.map((AllAreaByCityIdSelect, idx) => ({
                  areaid: AllAreaByCityIdSelect.areaid,
                  areaname: `${AllAreaByCityIdSelect.areaname}`
                  }))
                : null;
            setSelectOptionsAllAreaByCityId(optionsAllAreaByCityId);
          }
          if (success && success?.areadata.length <= 0) {
            console.log("data length empty", success?.message);
            
          }
        }
      });
    };

    const handleAllCity = async(e) => {
      console.log("values of select city ", e);
      formik.setFieldValue("city", e.city_name);
      var data ={
        cityid: e.city_id
      }
      await getAllAreaByCityIdApiCall(data);
      setOptionValueAllCity(e);
      setCityName(e.city_name);
      setCityid(e.city_id);

    };

    const handleAllAreaByCityId = async(e) => {
      console.log("values of select AllAreaByCityId", e);
      formik.setFieldValue("area", e.areaname);
      
      
      setOptionValueAllAreaByCityId(e);
      setAreaName(e.areaname);
      setAreaid(e.areaid);
    };


    useEffect(() => {
      getAllCityApiCall();
      
      // handleClickDebounce ();

    }, []);



  const handleBack = () => {
    //props.history.goBack();

    props.reloadAddress();
  };
  const setLatitiudeFnc = (lat) => setLat(lat);
  const setLongitudeFnc = (lng) => setLng(lng);
  const formattedAddressFnc = (formattedAddr) =>
    setFormattedAddress(formattedAddr);

    const areaFnc = (areaObtained) => setArea(areaObtained);
    const streetNameFnc = (streetNameObtained) =>{
      setStreetName(streetNameObtained);
      formik.setFieldValue("street_name",streetNameObtained);
    };
    const stateCntFnc = (stateCntObatined) =>{
      setStateCnt(stateCntObatined);
      formik.setFieldValue("state",stateCntObatined);
    };
    const pinCodeFnc = (pinCodeObtained) =>{
      setPinCode(pinCodeObtained);
      formik.setFieldValue("pincode",pinCodeObtained);
    };
    const houseNumberFnc = (houseNumberObtained) =>{
      setHouseNumber(houseNumberObtained);
      formik.setFieldValue("house_number",houseNumberObtained);
    };




    const onChangeStreetName = (event) =>{
      setStreetName(event.target.value);
      console.log("street value is",event.target.value)
  }
  
  const onBlurStreetName= (event) =>{
    formik.setFieldValue("street_name",event.target.value);
  }

  const onChangeHouseNumber = (event) =>{
    setHouseNumber(event.target.value);
}

const onBlurHouseNumber = (event) =>{
  formik.setFieldValue("house_number",event.target.value);
}


const onChangeState = (event) =>{
  setStateCnt(event.target.value);
}

const onBlurState= (event) =>{
formik.setFieldValue("state",event.target.value);
}

const onChangePincode = (event) =>{
  setPinCode(event.target.value);
}

const onBlurPincode= (event) =>{
formik.setFieldValue("pincode",event.target.value);
}

  const handleMapViewBtnClick = () => setMapViewBtn(!mapViewBtn);

  const handleRadioOptn = (event) => {
    formik.setFieldValue("address_type", event.target.value);
  };



  const validate = Yup.object({
    member_name: Yup.string()
      .max(20, "Must be 20 character or less")
      .required("Name must not be empty"),
    house_number: Yup.string()
      .max(10, "Must be 10 character or less")
      .required("House Number must not be empty"),
    street_name: Yup.string()
      .max(100, "Must be 100 character or less")
      .required("Street Name must not be empty"),
    area: Yup.string()
      .max(100, "Must be 100 character or less")
      .required("Area must not be empty"),
    landmark: Yup.string()
      .max(100, "Must be 100 character or less")
      .required("Landmark must not be empty"),
    city: Yup.string()
      .max(20, "Must be 20 character or less")
      .required("City must not be empty"),
    state: Yup.string()
      .max(20, "Must be 20 character or less")
      .required("State must not be empty"),
    pincode: Yup.string()
      .min(6, "Pincode should include 6 character")
      .max(6, "Pincode allowed only 6 character")
      .required("Pincode must not be empty"),
    address_type: Yup.string(),
    // mobile: Yup.string()
    //   .matches(phoneVal, "phone number is not valid")
    //   .min(10, "Mobile number should include 10 character")
    //   .max(10, "Mobile number allowed only 10 character")
    //   .required("mobile number required")
  });
  const formik = useFormik({
    initialValues: {
      member_name: "",
      house_number: houseNumber,
      street_name: streetName,
      area: areaName,
      landmark: "",
      city:cityName,
      state: stateCnt,
      pincode: pinCode,
      address_type: "",
      //  csrf_token:""
      // mobile: ""
    },
    // enableReinitialize: true,
    validationSchema: validate,
    onSubmit: async (values) => {
      setSubmitButton(true);
      console.log("values submission", values);
      console.log("user id ==", props.user.userId);
      var dt = formik.values;

      var data = {
        userid: props.user.userId,
        member_name: dt.member_name,
        house_number: dt.house_number,
        street_name: dt.street_name,
        area: dt.area,
        area_id:areaid,
        landmark: dt.landmark,
        city: dt.city,
        city_id:cityid,
        state: dt.state,
        pincode: dt.pincode,
        address_type: dt.address_type,
        mobile: dt.mobile,
        // csrf_token:dt.csrf_token,
        latitude: lat, 
        longitude: lng,

      };
      console.log("values submission jsonData add address", data);

      

      await props.add_my_address(data,(error, success) => {
        setSubmitButton(false);
        if (error) {
            console.log("error", error);
        }
        if (success.status == 200) {

          toaster('success', success.message);
          handleBack();
        }
      });
    },
    onReset: (values) => {}
  });
  console.log(`set values from map view StreetName :${streetName}, State:${stateCnt}, PinCode:${pinCode}, HouseNumber: ${houseNumber}`);
  console.log("formik error fromik values ",formik.errors);
  console.log("fromik values ",formik.values);
  

  return (
    <div>
      <div className="content">
        <div className="container-fluid">
          <div className="col-md-12 col-lg-12 col-xl-12 mx-auto mb-1">
            
            <form onSubmit={formik.handleSubmit}>
            {/* <input
            type="hidden"
            csrf_token="csrf_token"
            value={Cookie.load('XSRF-TOKEN')}
            
             {...formik.getFieldProps("csrf_token")}
            
              /> */}
              
            {/* <input 
            type="hidden" 
            name="csrf_token"
             value={Cookie.load('XSRF-TOKEN')}
            {...formik.getFieldProps(Cookie.load('XSRF-TOKEN'))}
            /> */}
            {/* {console.log("token",csrf_token)}  */}
            
            
              <div className="form-row">

             
              <div className="col-xl-12 col-md-12 map mb-2">
              <div id="map" className="map-listing">
                <div style={{height: "150px", width: "100%"}}>
                  <MapView
                    setLatitude={setLatitiudeFnc}
                    setLongitude={setLongitudeFnc}
                    setFormattedAddress={formattedAddressFnc}
                    setPinCode={pinCodeFnc}
                    setStateValue={stateCntFnc}
                    setHouseNumber={houseNumberFnc}
                    setStreetName={streetNameFnc}
                    lat={lat}
                    lng={lng}
                    priority={"1"}
                    center={{lat:12.9666, lng:77.530376}}
                    // center={{lat:13.941127421707419, lng:75.57465668236651}}
                   
                    gestureHandling="cooperative"
                  />
                 {console.log("streetNameFnc",streetNameFnc)} 
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>City <span className="errorcolor ml-0">*</span></label>
                    <Select
                            options={selectOptionsAllCity}
                            
                            onChange={(event) => {
                              handleAllCity(event);
                            }}
                            id="exampleFormControlInput2"
                            name="select_city"
                            getOptionLabel={(selectoptionsAllCity) =>
                              selectoptionsAllCity["city_name"]
                            }
                            getOptionValue={(selectoptionsAllCity) =>
                              selectoptionsAllCity["city_id"]
                            }
                          />
                  </div>
                  {formik.touched.city && formik.errors.city ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.city}
                    </div>
                  ) : null}
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Area <span className="errorcolor ml-0">*</span></label>
                    <Select
                            options={selectOptionsAllAreaByCityId}
                            
                            onChange={(event) =>
                               {
                              handleAllAreaByCityId(event);
                            }}
                            id="exampleFormControlInput2"
                            name="select_area"
                            getOptionLabel={(selectoptionsAllAreaByCityId) =>
                              selectoptionsAllAreaByCityId["areaname"]
                            }
                            getOptionValue={(selectoptionsAllAreaByCityId) =>
                              selectoptionsAllAreaByCityId["areaid"]
                            }
                          />
                  </div>
                  {formik.touched.area && formik.errors.area ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.area}
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Name <span className="errorcolor ml-0">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter address holder name"
                      // defaultValue={`Address Holder Name ${address_id}`}
                      // disabled="disabled"
                      {...formik.getFieldProps("member_name")}
                    />
                  </div>
                  {formik.touched.member_name && formik.errors.member_name ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.member_name}
                    </div>
                  ) : null}
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>House Number <span className="errorcolor ml-0">*</span></label>
                    <input
                      type="text"
                      // max={6}
                      className="form-control"
                      placeholder="Enter house number"
                      value={houseNumber}
                            
                            // defaultValue={`Address Holder Name ${address_id}`}
                            // disabled="disabled"
                            onChange={(event) => onChangeHouseNumber(event)}
                 onBlur={(event) =>onBlurHouseNumber(event)}
                    />
                  </div>
                  {formik.touched.house_number && formik.errors.house_number ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.house_number}
                    </div>
                  ) : null}
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Street Name <span className="errorcolor ml-0">*</span></label>
                    <textarea
                      type="text"
                      row={6}
                      // max={6}
                      className="form-control"
                      placeholder="Enter street name"
                      // defaultValue={`Address Holder Name ${address_id}`}
                      // disabled="disabled"
                      // {...formik.getFieldProps("street_name")}
                      value={streetName}
                            onChange={(event) => onChangeStreetName(event)}
                 onBlur={(event) =>onBlurStreetName(event)}
                    />
                  </div>
                  {formik.touched.street_name && formik.errors.street_name ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.street_name}
                    </div>
                  ) : null}
                </div>

                
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Landmark <span className="errorcolor ml-0">*</span></label>
                    <input
                      type="text"
                      // max={6}
                      className="form-control"
                      placeholder="Enter landmark"
                      // defaultValue={`Address Holder Name ${address_id}`}
                      // disabled="disabled"
                      {...formik.getFieldProps("landmark")}
                    />
                  </div>
                  {formik.touched.landmark && formik.errors.landmark ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.landmark}
                    </div>
                  ) : null}
                </div>

                

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>State <span className="errorcolor ml-0">*</span></label>
                    <input
                      type="text"
                      // max={6}
                      className="form-control"
                      placeholder="Enter state"
                      // defaultValue={`Address Holder Name ${address_id}`}
                      // disabled="disabled"
                      value={stateCnt}
                            onChange={(event) => onChangeState(event)}
                 onBlur={(event) =>onBlurState(event)}
                      // {...formik.getFieldProps("state")}
                    />
                  </div>
                  {formik.touched.state && formik.errors.state ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.state}
                    </div>
                  ) : null}
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Pincode <span className="errorcolor ml-0">*</span></label>
                    <input
                      type="text"
                      maxLength={6}
                      className="form-control"
                      placeholder="Enter pincode"
                      // defaultValue={`Address Holder Name ${address_id}`}
                      // disabled="disabled"
                      // {...formik.getFieldProps("pincode")}
                      value={pinCode}
                            onChange={(event) => onChangePincode(event)}
                 onBlur={(event) =>onBlurPincode(event)}
                    />
                  </div>
                  {formik.touched.pincode && formik.errors.pincode ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.pincode}
                    </div>
                  ) : null}
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Address Type</label>
                    <div className="row ml-2">
                      {optionsRdBtn.map((options, idxo) => (
                        <div
                          key={idxo}
                          className="text-primary col-md-4"
                          id={idxo}
                        >
                          <input
                            className="form-check-input mr-2"
                            type="radio"
                            id={`optnRd[${idxo}]`}
                            value={options}
                            name={`optnameVal`}
                            // checked={
                            //   radioBtn == idxo
                            //     ? true
                            //     : false
                            // }
                            onChange={(event) => {
                              handleRadioOptn(event);
                            }}
                          />
                          <label>{options}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                  {formik.touched.address_type && formik.errors.address_type ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.address_type}
                    </div>
                  ) : null}
                </div>

                <div className="col-12 col-md-6">
                  {/* <div className="form-group">
                    <label>Mobile <span className="errorcolor ml-0">*</span></label>
                    <input
                      type="text"
                      maxLength={10}
                      // defaultValue="+91 9876543210"
                      placeholder="Enter mobile number..."
                      className="form-control"
                      {...formik.getFieldProps("mobile")}
                    />
                  </div>
                  {formik.touched.mobile && formik.errors.mobile ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="mb-2"
                    >
                      {formik.errors.mobile}
                    </div>
                  ) : null} */}
                </div>

                {/* {
                        (mapViewBtn == true) ? (
                          <div className="col-xl-6 col-md-12 map mb-2">
            <div id="map" className="map-listing">
            <div style={{ height: '150px', width: '100%' }}>
            <MapView 
            setLatitude={setLatitiudeFnc} 
            setLongitude={setLongitudeFnc}
            setFormattedAddress={formattedAddressFnc} 
            center={{ lat: 	12.972442, lng: 77.580643 }}
              
            />
             </div>
             </div>
            </div>
                        ): null
                      } */}
                <div className="mx-1 row">
                  <div className="submit-section mr-3">
                    <button
                      type="submit"
                      className="btn btn-primary submit-btn"
                      disabled={submitButton}
                    //  disabled={(props.row.status == 1) ? true : false}
                    // disabled={(values.status == "1")?true:false} 
                    // onClick={handleClickDebounce()}
                    >
                      Submit
                    </button>
                  
                  </div>

                  {/* <div className="submit-section mr-3">
                          <button
                            type="button"
                            className="btn btn-primary submit-btn"
                            onClick={() => handleMapViewBtnClick()}
                          >
                            Set on Map
                          </button>
                        </div> */}

                  <div className="submit-section">
                    <button
                      type="button"
                      className="btn btn-primary submit-btn"
                      onClick={props.toggle}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
            
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    add_my_address: (data, callback) =>dispatch(add_my_address_request(data, callback)),
    get_all_city: (data, callback) =>dispatch(get_all_city_req(data, callback)),
    get_all_area_by_city_id: (data, callback) =>dispatch(get_all_area_by_city_id_req(data, callback))
  };
};
const mapStateToProps = (state) => {
  return {
    status: state.user.status,
    error: state.user.error,
    add_my_address: state.myaddress.add_my_address,
    user_by_id: state.user.user_by_id,
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn,
    requesting: state.user.requesting
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);
