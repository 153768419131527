import * as constance from "../constance/action.constance";
import {
  LOGIN_FAILURE, 
  LOGIN_REQUEST, 
  LOGIN_SUCCESS,

  LOGOUT_REQUEST, 
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,

  ADD_TOKEN_TO_STATE_REQUEST,

  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_SUCCESS,

   //  UPDATE_USER_DETAILS
   UPDATE_USER_DETAILS_REQUEST,
   UPDATE_USER_DETAILS_SUCCESS,
   UPDATE_USER_DETAILS_ERROR,
} from "./action.type";

export function login_req(data,callback) {
  return {
    type: LOGIN_REQUEST,
    data,
    callback,
    status: constance.REQUESTING
  };
}

export function login_fail(error) {
  return {
    type: LOGIN_FAILURE,
    error,
    status: constance.FAIL
  };
}

export function login_success(result) {
  return {
    type: LOGIN_SUCCESS,
    result,
    status: constance.SUCCESS
  };
}

// GET USER BY ID PROFILE
export const get_user_by_id_req = (data,callback) => ({
  type: GET_USER_BY_ID_REQUEST,
  data,
    callback,
});

export const get_user_by_id_fail = (error) => ({
  type: GET_USER_BY_ID_FAILURE,
  payload: error,
});

export const get_user_by_id_success = (result) => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: result,
});

//logout
export function logout_req(data) {
  return {
    type: LOGOUT_REQUEST,
    data,
    status: constance.REQUESTING
  };
}

export function logout_fail(error) {
  return {
    type: LOGOUT_FAILURE,
    error,
    status: constance.FAIL
  };
}

export function logout_success() {
  return {
    type: LOGOUT_SUCCESS,
    status: constance.SUCCESS
  };
}


export function addTokenToStateRequest() {
  return {
    type: ADD_TOKEN_TO_STATE_REQUEST,
    status: constance.REQUESTING
  };
}


// UPDATE_USER_DETAILS


export function update_user_details_success(result) {
  return {
    type: UPDATE_USER_DETAILS_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function update_user_details_request(data,callback) {
  return {
    type: UPDATE_USER_DETAILS_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function update_user_details_error(error) {
  return {
    type: UPDATE_USER_DETAILS_ERROR,
    error: error,
    status: constance.ERROR,
  };
}
