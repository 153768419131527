import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
     
    // all delete_uploadprescription
    
    get_all_delete_uploadprescription_req,
    get_all_delete_uploadprescription_err,
    get_all_delete_uploadprescription_succ,

    //delete delete_uploadprescription
    delete_uploadprescription_success,
    delete_uploadprescription_request,
    delete_uploadprescription_error

   
  } from './action.function.js';
import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import {isLoading} from '../action.loader/action.function.js';
    import { 
         GET_ALL_DELETE_UPLOADPRESCRIPTION_REQ,
         DELETE_UPLOADPRESCRIPTION_REQUEST

    } from './action.type';
    import toaster from "../../../utils/ReactTostify";
    
    function*  all_delete_uploadprescriptionSaga(payload) {
      const {data,callback} =payload;
        try {
            const store = yield select();
            // const userId =  store.user.user?.userId;
        console.log("store values ==========", store?.user?.user?.userId)
        console.log("api url concatication =============",(API_URL.GET_ALL_PRESCRIPTIONS + store?.user?.user?.userId))
        yield put(isLoading(true));
          let response = yield call(API.get,(API_URL.GET_ALL_PRESCRIPTIONS + store?.user?.user?.userId),API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("all family_member saga result === ", result);
          yield put( get_all_delete_uploadprescription_succ(result));
          callback(null,result);
          
         
        }
        catch (error) {
          yield put(get_all_delete_uploadprescription_err(error));
          yield put(isLoading(false));
          callback(error,null);
        }
      }

      

      function*  delete_uploadprescription_successSaga(payload) {
        const {data,callback} =payload;
        console.log("data sent to delete family members saga", data)

        try {
          // console.log("api url concatication =============",(API_URL.ADD_ADDRESS + store?.user?.user?.userId));
          yield put(isLoading(true));
          let response = yield call(API.post,API_URL.DELETE_UPLOADPRESCRIPTION , data,API.getHeaders());
          yield put(isLoading(false));
          const result  = response.data;
          console.log("delete family members response saga",result);
          yield put(delete_uploadprescription_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(delete_uploadprescription_error(error));
          callback(error,null);
          yield put(isLoading(false));
          toaster("error", error);
        }
      }

    export default function* delete_prescriptionSaga() { 
    yield takeEvery(GET_ALL_DELETE_UPLOADPRESCRIPTION_REQ , all_delete_uploadprescriptionSaga);
    yield takeEvery(DELETE_UPLOADPRESCRIPTION_REQUEST ,  delete_uploadprescription_successSaga);
} 
