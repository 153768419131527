import * as constance from "../constance/action.constance";
import { 
  //GET ALL TIME SLOT
  GET_ALL_TIME_SLOT_REQ ,
  GET_ALL_TIME_SLOT_ERR,
  GET_ALL_TIME_SLOT_SUCC,
  //GET ALL COUPONS
  GET_ALL_COUPONS_REQ,
  GET_ALL_COUPONS_ERR,
  GET_ALL_COUPONS_SUCC,

  // POST_VERIFY_COUPON_BY_ID

  POST_VERIFY_COUPON_BY_ID_REQ,
  POST_VERIFY_COUPON_BY_ID_ERR,
  POST_VERIFY_COUPON_BY_ID_SUCC,

 // CREATE ORDERS
 CREATE_ORDER_SUCCESS,
 CREATE_ORDER_REQUEST,
 CREATE_ORDER_ERROR,

//  GET ALL ORDERS
  GET_ALL_ORDERS_REQ ,
  GET_ALL_ORDERS_ERR,
  GET_ALL_ORDERS_SUCC,

//  GET ORDER_DETAILS_BY_ORDER_ID
GET_ORDER_DETAILS_BY_ORDER_ID_REQ ,
GET_ORDER_DETAILS_BY_ORDER_ID_ERR,
GET_ORDER_DETAILS_BY_ORDER_ID_SUCC,

 // VERIFY ORDERS
 VERIFY_ORDER_SUCCESS,
 VERIFY_ORDER_REQUEST,
 VERIFY_ORDER_ERROR,

 //DELETE ORDER_BY_ORDER_ID
DELETE_ORDER_BY_ORDER_ID_REQUEST,
DELETE_ORDER_BY_ORDER_ID_ERROR,
DELETE_ORDER_BY_ORDER_ID_SUCCESS,

//  GET DELIVERY_CHARGES
GET_DELIVERY_CHARGES_REQ ,
GET_DELIVERY_CHARGES_ERR,
GET_DELIVERY_CHARGES_SUCC,

} from "./action.type";
// GET ALL TIME SLOT
export function get_all_time_slot_req(data,callback) {
  return {
    type:GET_ALL_TIME_SLOT_REQ  ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_all_time_slot_err(error) {
  return {
    type: GET_ALL_TIME_SLOT_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_all_time_slot_success(result) {
  return {
    type: GET_ALL_TIME_SLOT_SUCC,
    result,
    status: constance.SUCCESS,
  };
}
//GET ALL COUPONS
export function get_all_coupons_req(data,callback) {
  return {
    type: GET_ALL_COUPONS_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_all_coupons_err(error) {
  return {
    type:GET_ALL_COUPONS_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_all_coupons_success(result) {
  return {
    type: GET_ALL_COUPONS_SUCC,
    result,
    status: constance.SUCCESS,
  };
}

//VERIFY_COUPON_BY_ID
export function verify_coupon_by_id_success(result) {
  return {
    type: POST_VERIFY_COUPON_BY_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };
}
export function verify_coupon_by_id_request(data,callback) {
  return {
    type: POST_VERIFY_COUPON_BY_ID_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function verify_coupon_by_id_error(error) {
  return {
    type:POST_VERIFY_COUPON_BY_ID_ERR,
    error: error,
    status: constance.ERROR,
  };
}



// GET ALL ORDERS

export function get_all_orders_req(data,callback) {
  return {
    type:GET_ALL_ORDERS_REQ  ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_all_orders_err(error) {
  return {
    type: GET_ALL_ORDERS_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_all_orders_success(result) {
  return {
    type: GET_ALL_ORDERS_SUCC,
    result,
    status: constance.SUCCESS,
  };
}



// GET ORDER_DETAILS_BY_ORDER

export function get_order_details_by_order_id_req(data,callback) {
  return {
    type:GET_ORDER_DETAILS_BY_ORDER_ID_REQ  ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_order_details_by_order_id_err(error) {
  return {
    type: GET_ORDER_DETAILS_BY_ORDER_ID_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_order_details_by_order_id_success(result) {
  return {
    type: GET_ORDER_DETAILS_BY_ORDER_ID_SUCC,
    result,
    status: constance.SUCCESS,
  };
}


//CREATE ORDER
export function create_order_success(result) {
    return {
      type: CREATE_ORDER_SUCCESS,
      result,
      status: constance.SUCCESS,
    };
  }
  export function create_order_request(data,callback) {
    return {
      type: CREATE_ORDER_REQUEST,
      data,
      callback,
      status: constance.REQUESTING,
    };
  }
  export function create_order_error(error) {
    return {
      type:CREATE_ORDER_ERROR,
      error: error,
      status: constance.ERROR,
    };
  }
  
  //VERIFY ORDER
export function verify_order_success(result) {
  return {
    type: VERIFY_ORDER_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function verify_order_request(data,callback) {
  return {
    type: VERIFY_ORDER_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function verify_order_error(error) {
  return {
    type:VERIFY_ORDER_ERROR,
    error: error,
    status: constance.ERROR,
  };
}

// DELETE ORDER_BY_ORDER_ID


export function delete_order_by_order_id_success(result) {
  return {
    type: DELETE_ORDER_BY_ORDER_ID_SUCCESS,
    result,
    status: constance.SUCCESS,
  };
}
export function delete_order_by_order_id_request(data,callback) {
  return {
    type: DELETE_ORDER_BY_ORDER_ID_REQUEST,
    data,
    callback,
    status: constance.REQUESTING,
  };
}
export function delete_order_by_order_id_error(error) {
  return {
    type: DELETE_ORDER_BY_ORDER_ID_ERROR,
    error: error,
    status: constance.ERROR,
  };
}


// GET DELIVERY_CHARGES

export function get_delivery_charges_req(data,callback) {
  return {
    type:GET_DELIVERY_CHARGES_REQ  ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_delivery_charges_err(error) {
  return {
    type: GET_DELIVERY_CHARGES_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_delivery_charges_success(result) {
  return {
    type: GET_DELIVERY_CHARGES_SUCC,
    result,
    status: constance.SUCCESS,
  };
}


