import { combineReducers } from "redux";
import users from "../reducers/reducer.users/userReducer";
import test from "../reducers/reducer.tests/testReducer";
import packages from "../reducers/reducer.packages/packageReducer"
import faq from "../reducers/reducer.faq/faqReducer";
import familymember from "../reducers/reducer.familymembers/familymemberReducer";
import deleteupload from "../reducers/reducer.deleteuploadprescription/deleteuploadprescription";
import myaddress from "../reducers/reducer.myaddress/myaddressReducer";
import cart from "../reducers/reducer.cart/cartReducer";
import order from "../reducers/reducer.order/orderReducer";
import loaderReducer from "../reducers/reducer.loader/loaderReducer";
import support from "../reducers/reducer.support/supportReducer";
import feedback from "../reducers/reducer.feedback/feedbackReducer";
import article from "../reducers/reducer.article/articleReducer";
import customnotification from "../reducers/reducer.customnotification/customNotificatioReducer";
// combining all the defined reducers in a single root reducer for the access
export const rootReducer = combineReducers({
  user: users,
  test: test,
  packages:packages,
  faq:faq,
  article:article,
  familymember:familymember,
  deleteupload:deleteupload,
  myaddress:myaddress,
  cart:cart,
  order:order,
  support:support,
  feedback:feedback,
  loaderReducer:loaderReducer,
  customnotification:customnotification,




  
});
