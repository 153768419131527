import { colors } from '@material-ui/core';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class HomeBanner extends Component{
	constructor(props) {
		super(props);
		
	}
    
	
    render(){
       
        return(
    	<section className="section section-search">
				<div className="container">
					<div className="banner-wrapper">
						<div className="banner-header">
							<h6>We work to take care of your health</h6>
							<h1>Great Smile For Healthy Lifestyle!</h1>
							<p>Your health remains our top priority! Taking care of your health right now is the most important thing you can do for yourself and your family...</p>
							<a href={(this.props.user.userId)?"/users/dashboard":"/home/login"} className="btn-yellow">Make an Appointment</a>
						</div>
					</div>
				</div>
			</section>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
	return {
		
	};
  };
  const mapStateToProps = (state) => {
	return {
	  status: state.user.status,
	  error: state.user.error,
	  user: state.user.user,
	  isLoggedIn: state.user.isLoggedIn,
	  requesting: state.user.requesting,
  
  
	};
  };
export default connect(mapStateToProps, mapDispatchToProps)(HomeBanner);