import React, {Component} from 'react';

class Location extends Component{
    render(){
        return(
            <section className="location-col">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="left">
                            <div className="location-box text-center">
                                <h4>Lab Report</h4>
                                <p>Please use Login ID and Password provided by Padmashree Diagnostics to access the reports.The online Laboratory reports are available to access and download for 1 month duration only (30 days).</p>
                               
                                <div className="location-btn">
                                    <a href="#"><i className="fas fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="right">
                            <div className="location-box text-center">
                            <h4>Advance imaging services</h4>
                                <p>CT and MRI Scanning facilities are available on a routine basis between 07:00 AM to 09:00 PM on all week days. Round the clock services are available on all days of the week for emergencies.
                                   For appointments call: +91 8050022411/311.</p>
                                <div className="location-btn">
                                    <a href="#"><i className="fas fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       
        );
    }
}

export default Location;
	
    