import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { 
     
    // all faq
    get_all_faq_req,
    get_all_faq_err,
    get_all_faq_success,
   
    
  } from './action.function.js';

  import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';

    import { 
        GET_ALL_FAQ_REQ,
   } from './action.type';
   import {isLoading} from '../action.loader/action.function.js';

   function* allFaqSaga() {
    try {
       

      // let Login_Api_Call = API.post(API_URL.LOGIN)
      yield put(isLoading(true));
      let response = yield call(API.get,API_URL.ALL_FAQ);
      yield put(isLoading(false));
      //console.log("resposne saga --------------",response);
      const result  = response.data;
      console.log("all faqs === ", result);
      yield put(get_all_faq_success(result));
      // yield put(addTokenToState());
     
    }
    catch (error) {
      yield put(isLoading(false));
      yield put(get_all_faq_err(error));
    }
  }
  export default function* faqSaga() {
  
    yield takeEvery(GET_ALL_FAQ_REQ, allFaqSaga);
}