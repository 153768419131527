import * as constance from "../constance/action.constance";
import {  
   // get all package
    GET_ALL_PACKAGE_REQ,
    GET_ALL_PACKAGE_SUCC,
    GET_ALL_PACKAGE_ERR,

     // get all popular package
     GET_ALL_POPULAR_PACKAGE_REQ,
     GET_ALL_POPULAR_PACKAGE_SUCC,
     GET_ALL_POPULAR_PACKAGE_ERR,
    
  } from "./action.type";

  // GET ALL  PACKAGE
// export function get_all_package_req() {
//     return {
//       type: GET_ALL_PACKAGE_REQ,
//       status: constance.REQUESTING,
//     };
//   }
  
//   export function get_all_package_err(error) {
//     return {
//       type: GET_ALL_PACKAGE_ERR,
//       error,
//       status: constance.ERROR,
//     };
//   }
  
//   export function get_all_package_success(result) {
//     return {
//       type: GET_ALL_PACKAGE_SUCC,
//       result,
//       status: constance.SUCCESS,
//     };
//   }

export function get_all_package_req(data,callback) {
  return {
    type: GET_ALL_PACKAGE_REQ,
    data,
    callback,
    status: constance.REQUESTING,
  };
}

export function get_all_package_err(error) {
  return {
    type: GET_ALL_PACKAGE_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_all_package_success(result) {
  return {
    type: GET_ALL_PACKAGE_SUCC,
    result,
    status: constance.SUCCESS,
  };
}




   // GET ALL POPULAR PACKAGE
export function get_all_popular_package_req() {
  return {
    type: GET_ALL_POPULAR_PACKAGE_REQ,
    status: constance.REQUESTING,
  };
}

export function get_all_popular_package_err(error) {
  return {
    type: GET_ALL_POPULAR_PACKAGE_ERR,
    error,
    status: constance.ERROR,
  };
}

export function get_all_popular_package_success(result) {
  return {
    type: GET_ALL_POPULAR_PACKAGE_SUCC,
    result,
    status: constance.SUCCESS,
  };
}
  