import * as articleActionType from "../../actions/action.article/action.type";


const article = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    all_articles:null,
    article_by_article_id:null,
    },
    action
)=>{

    switch (action.type) {
           // get all articles
           case articleActionType.GET_ALL_ARTICLES_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case articleActionType.GET_ALL_ARTICLES_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_articles: action.result,
            });
          case articleActionType.GET_ALL_ARTICLES_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

             // article_by_article_id
           case articleActionType.GET_ARTICLE_BY_ARTICLE_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case articleActionType.GET_ARTICLE_BY_ARTICLE_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              article_by_article_id: action.result,
            });
          case articleActionType.GET_ARTICLE_BY_ARTICLE_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default article;
    
    
    