import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import _ from 'underscore';
import { 
    //  add to cart
    add_to_cart_request,
    add_to_cart_success,
    add_to_cart_error,

    // delete from cart
    delete_from_cart_request,
    delete_from_cart_success,
    delete_from_cart_error,

    refresh_cart_success,
    refresh_cart_request,
    refresh_cart_error
  } from './action.function.js';


  import { 
    API_URL,
    } from '../../api/api.js';
    import {API} from '../../../utils/networkApi';
    import { 
        ADD_TO_CART_REQUEST,
        DELETE_FROM_CART_REQUEST,
        REFRESH_CART_REQUEST

   } from './action.type';
    import toaster from "../../../utils/ReactTostify";
   

    function* addTo_cartSaga(payload) {
        const {data,callback} =payload;
        console.log("data sent to add to cart saga", data)
        try {
          yield put(add_to_cart_success(data));
          callback(null,data);
        }
        catch(error) {
          yield put(add_to_cart_error(error));
          callback(error,null);
        toaster("error", error);
        }
      }


      function* refresh_cartSaga() {
        
        console.log("refresh cart saga called")
        try {
          yield put(refresh_cart_success([]));
        }
        catch(error) {
          yield put(refresh_cart_error([]));
        toaster("error", error);
        }
      }



      function* deleteFrom_cartSaga(payload) {
        const {data,callback} =payload;
        console.log("data sent to delete from cart saga", data)
        try {
          const store = yield select();
          var cartData = store?.cart?.cartVal;
          var result = _.filter(cartData, function (item) {
            if(item.package_id){
              return item.package_id !== data.package_id;
            }
            if(item.serviceid){
              return item.serviceid !== data.serviceid
            }
              
          });
          yield put(delete_from_cart_success(result))
          callback(null,result);
        }
        catch(error) {
          yield put(delete_from_cart_error(error));
          callback(error,null);
        toaster("error", error);
        }
      }


      export default function* cartSaga() {
        yield takeEvery(ADD_TO_CART_REQUEST, addTo_cartSaga);
        yield takeEvery(REFRESH_CART_REQUEST, refresh_cartSaga);
        yield takeEvery(DELETE_FROM_CART_REQUEST, deleteFrom_cartSaga);
    } 