import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { DashboardSidebar } from "../sidebar/sidebar.jsx";
import { Tab, Tabs } from "react-bootstrap";
import StickyBox from "react-sticky-box";
import { useHistory } from "react-router";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";
import CreateSupport from "./Createsupport/index.jsx";
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';

import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


import {
  IMG01,
  IMG02,
  IMG03,
  IMG04,
  IMG05,
  IMG06,
  IMG07,
  IMG08,
  IMG09,
  IMG10,
} from "./img";

import {get_all_support_req} from "../../../../redux-services/actions/action.support/action.function.js";
import { statusTicketConstants } from "../../../../utils/constants.js";
function Support(props){
  const [openModal, setOpenModal] = React.useState(false);
  const { SearchBar, ClearSearchButton } = Search;
  const [supportData, setSupportData] = useState([]);
  const [noSupportData, setNoSupportData] = useState("");

  const history = useHistory();
  const nextPath = (path) => {
      history.push(path);
    }

    // const handle_edit =  (id) => {
    //   console.log("edit id passed ====",id);
   
        // nextPath(`/Orders/OrderView/${id}`);
      
      
    // };

    const nextPathDataPush = (path,data) =>{
      props.history.push({pathname: path,state: {
      data: data,
      }
    })
    }

    const getSupportApiCall = async() =>{
    
      await props.get_all_support(null,(error,success)=>{
       if(error){
         
         console.log("error",error);
       }    
       if(success.status == 200 ){
         if (success && success?.response.length > 0) {
          setSupportData(success?.response);
         }
         if (success && success?.response.length <= 0) {
           console.log("data length empty",success?.message);
           setNoSupportData(success?.message);
         }
        }
        });
       
   
     }
     useEffect(() => {
       getSupportApiCall();
     }, []);


     const reloadDataModal = async() => {
      await getSupportApiCall();
     // await props.get_all_family_member();
     await setOpenModal(!openModal);
   }


    const handle_view =  (id,row) => {
      console.log("view id passed ====",id);
      var data = {
        "ticketid":`${id}`,
         "ticketdata":row
      }
   
        // nextPath(`/Support/SupportView/${id}`);
        nextPathDataPush(`/Support/SupportView`,data);
      
      
    };
    const handleOpen = () => {
      setOpenModal(true);
    };
  
    const handleClose = () => {
      setOpenModal(false);
    };



    const columnsData = [
      { dataField: 'created_at', 
      text: 'Date',
      formatter: (cell, row) => {
        // console.log("age",row);
        const DateObtained = moment(row.created_at).format("DD-MM-YYYY");
        console.log("age", DateObtained);
       
        return <div>{`${DateObtained}`}</div>;
      }
     },
      { dataField: 'ticketid', text: 'Ticket ID' },
      { dataField: 'orderid', text: 'Order ID' },
      { dataField: 'patient_name', text: 'Patient Name' },
      {
        dataField: "ticket_status",
        text: "Status",
        formatter: (cell, row) => {
          console.log("row values ==", row);
          return (
            <div className={(row.ticket_status == statusTicketConstants.raised) ? "text-primary" : (row.ticket_status == statusTicketConstants.processing) ? "text-dark": (row.ticket_status == statusTicketConstants.resolved) ? "text-success": "text-danger"}>
              <strong>
                {row.ticket_status != null
                  ? row.ticket_status == statusTicketConstants.raised
                    ? "Raised"
                    : row.ticket_status == statusTicketConstants.processing
                    ? "Processing"
                    : row.ticket_status == statusTicketConstants.resolved
                    ? "resolved"
                    : "Cancelled"
                  : "Nill"}
              </strong>
            </div>
          );
        }
      },
      {
    dataField: 'link',
    text: '',
    formatter: (rowContent, row) => {
      return (    
        <div className="table-action">
                                    <button type="button" className="btn btn-sm bg-info-light"   onClick={() =>handle_view(row.ticketid,row)}>
                                        <i className="far fa-eye"></i> View
                                      </button>


                                      </div>                           
      )
    }
}
      
    ];

    const pagination = paginationFactory({
      page: 1,
      sizePerPage: 5,
      lastPageText: '>>',
      firstPageText: '<<',
      nextPageText: '>',
      prePageText: '<',
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {
        console.log('page', page);
        console.log('sizePerPage', sizePerPage);
      },
      onSizePerPageChange: function (page, sizePerPage) {
        console.log('page', page);
        console.log('sizePerPage', sizePerPage);
      }
    });
    







    return (
      <div className="content">
        <div className="container-fluid">
       <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DashboardSidebar {...props}/>
              </StickyBox>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="card">
              <div className="card-body user-tabs">
                <div className="row">
                <div className="mr-auto p-1">
                <strong> Support </strong>
                  </div>
                  <div className="" >
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleOpen}

                    // onClick={() =>
                    //   nextPath("/Package/AddPackage")
                    // }
                    // onClick={handleModalClick}
                  >
                    <span >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </span>
                    <span>Create Support</span>
                  </button>
                </div>
              </div>
                  </div>
                  
                      <div className="card card-table mb-0 mt-2">
                        <div className="card-body">
                          <div className="table-responsive">

                          <ToolkitProvider
                             bootstrap4
                             keyField='support_id'
                             data={supportData}
                             columns={columnsData}
                             search
                           > 
       
      {
          props => (
            <div>
              {/* <h6>Input something at below input field:</h6> */}
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable striped bordered hover
                // defaultSorted={defaultSorted}
                pagination={pagination}
                noDataIndication={() => (
                                          <div className="text-alert">
                                             <i>{noSupportData}</i>
                                          </div>
                                    )}
                {...props.baseProps}
              />
              </div>
          )
        }
      </ToolkitProvider>
                            
                          </div>
                        </div>
                      </div>
                    
                    
                   
                
                </div> 
              </div>
            </div>
          </div> 

          <Modal
          size="lg"
          isOpen={openModal}
          toggle={handleClose}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
        // className="mx-auto"
        className="addSupport"
      >
        <CreateSupport
          {...props}
          toggle={handleClose}
              reloadSupport={reloadDataModal}
        />
         </Modal>
        </div>
      </div>
    );
  }
  const mapDispatchToProps = (dispatch) => {
    return {
      //  logout_req: () =>dispatch(logout_req()),
      //  user_by_id_req: (data,callback) =>dispatch(get_user_by_id_req(data,callback)),
      get_all_support: (data,callback) =>dispatch(get_all_support_req(data,callback))
    };
    };
    const mapStateToProps = (state) => {
    return {
      status: state.user.status,
      error: state.user.error,
      user_by_id:state.user.user_by_id,
      user: state.user.user,
      isLoggedIn: state.user.isLoggedIn,
      requesting: state.user.requesting,
    
    
    };
    };
    export default connect(mapStateToProps, mapDispatchToProps)(Support);
