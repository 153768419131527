import React, {useState,useEffect}  from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from "react-redux";
import {get_all_faq_req} from "../../../redux-services/actions/action.faq/action.function.js"

function Faq(props) {
  const [search, setSearch] = useState(null);
  useEffect(() => {
        const { get_all_faq } =props;
        get_all_faq();
         const all_faq = props.all_faq;
      console.log("gen_faq =====",all_faq);
  
      },[]);
    const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const searchSpace = (event) => {
    let keyword = event.target.value;
    setSearch(keyword);
  };
    return (
        <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/home">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Faqs</li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Faqs</h2>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        {/* Page Content */}
        <div className="content">
          <div className="container-fluid">
          <div className="card search-widget mb-4 w-50 justify-content-center mx-auto">
            <div className="card-body">
                <form className="search-form">
                    <div className="input-group">
                        <input type="text" 
                        placeholder="Search Faqs ...." 
                        className="form-control"
                        onChange={(e) => searchSpace(e)}
                         />
                        <div className="input-group-append">
                            <button type="submit" className="btn btn-primary"><i className="fa fa-search"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
          <div className={classes.root}>

          {(props.all_faq && (props.all_faq?.response.length > 0)) ? (props.all_faq?.response.map((faq,idx) =>(
            <>
            {(search == null)?(<Accordion expanded={expanded === `panel${faq.faqid}`} onChange={handleChange(`panel${faq.faqid}`)} key={idx}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${faq.faqid}bh-content`}
          id={`panel${faq.faqid}bh-header`}
        >
          <Typography className={classes.heading}>{(faq.questions !== null)? faq.questions: `FAQ`}</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           {(faq.answers !== null)? faq.answers: `FAQ ANSWER`}
          </Typography>
        </AccordionDetails>
      </Accordion>): 
      (faq.questions.toLowerCase().includes(search.toLowerCase()))?(<Accordion expanded={expanded === `panel${faq.faqid}`} onChange={handleChange(`panel${faq.faqid}`)} key={idx}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${faq.faqid}bh-content`}
          id={`panel${faq.faqid}bh-header`}
        >
          <Typography className={classes.heading}>{(faq.questions !== null)? faq.questions: `FAQ`}</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           {(faq.answers !== null)? faq.answers: `FAQ ANSWER`}
          </Typography>
        </AccordionDetails>
      </Accordion>
      ): null}
            </>
            )))
      :(
        <div className="bg-white p-1 justify-content-center mx-auto rounded">
        <h6 className="text-center font-weight-bold">No FAQs found</h6>
        </div>
      )
            }
     
       
    </div>
          </div>
        </div>		
        {/* /Page Content */}
      </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      paddingLeft: '5%',
      paddingRight: '5%',
      marginBottom:'2%'
      
      
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      
      
    },
   
  }));

  const mapDispatchToProps = (dispatch) => {
    return {
      get_all_faq: () =>dispatch(get_all_faq_req())
    };
    };
    const mapStateToProps = (state) => {
    return {
      status: state.user.status,
      error: state.user.error,
        all_faq: state.faq.all_faq,
      user: state.user.user,
      isLoggedIn: state.user.isLoggedIn,
      requesting: state.user.requesting,
    
    
    };
    };
    export default connect(mapStateToProps, mapDispatchToProps)(Faq);

