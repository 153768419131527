//GET ALL MY_ADDRESS
export const GET_ALL_MY_ADDRESS_REQ = "GET_ALL_MY_ADDRESS_REQ";
export const GET_ALL_MY_ADDRESS_ERR = "GET_ALL_MY_ADDRESS_ERR";
export const GET_ALL_MY_ADDRESS_SUCC = "GET_ALL_MY_ADDRESS_SUCC";

//ADD MY_ADDRESS
export const ADD_MY_ADDRESS_REQUEST = "ADD_MY_ADDRESS_REQUEST";
export const ADD_MY_ADDRESS_ERROR = "ADD_MY_ADDRESS_ERROR";
export const ADD_MY_ADDRESS_SUCCESS = "ADD_MY_ADDRESS_SUCCESS";

//DELETE MY_ADDRESS
export const DELETE_MY_ADDRESS_REQUEST = "DELETE_MY_ADDRESS_REQUEST";
export const DELETE_MY_ADDRESS_ERROR = "DELETE_MY_ADDRESS_ERROR";
export const DELETE_MY_ADDRESS_SUCCESS = "DELETE_MY_ADDRESS_SUCCESS";

// UPDATE MY_ADDRESS DETAILS

export const UPDATE_MY_ADDRESS_DETAILS_REQUEST = "UPDATE_MY_ADDRESS_DETAILS_REQUEST";
export const UPDATE_MY_ADDRESS_DETAILS_ERROR = "UPDATE_MY_ADDRESS_DETAILS_ERROR";
export const UPDATE_MY_ADDRESS_DETAILS_SUCCESS = "UPDATE_MY_ADDRESS_DETAILS_SUCCESS";


//GET ALL CITY
export const GET_ALL_CITY_REQ = "GET_ALL_CITY_REQ";
export const GET_ALL_CITY_ERR = "GET_ALL_CITY_ERR";
export const GET_ALL_CITY_SUCC = "GET_ALL_CITY_SUCC";

//GET ALL AREA_BY_CITY_ID
export const GET_ALL_AREA_BY_CITY_ID_REQ = "GET_ALL_AREA_BY_CITY_ID_REQ";
export const GET_ALL_AREA_BY_CITY_ID_ERR = "GET_ALL_AREA_BY_CITY_ID_ERR";
export const GET_ALL_AREA_BY_CITY_ID_SUCC = "GET_ALL_AREA_BY_CITY_ID_SUCC";