import * as testActionType from "../../actions/action.test/action.type";


const test = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getalltest_error: null,
    getalltest_status: null,
    getallpopulartest_error: null,
    getallpopulartest_status: null,

    // test

    // create_test: null,
    get_all_test: [],
     all_test: null,
     get_all_popular_test: [],
     all_popular_test: null,
     all_category: null,

    //  test_by_id: [],
    //  test_by_id_loading: false,
    //  test_by_id_error: null,
    //  test_enable_disable_status: null,
    //  update_test_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //test reducers type
        
    
        //create new test
        // case testActionType.CREATE_TEST_REQUEST:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //   });
        // case testActionType.CREATE_TEST_SUCCESS:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //     create_test: action.result,
        //   });
        // case testActionType.CREATE_TEST_ERROR:
        //   return Object.assign({}, state, {
        //     getalltest_status: action.status,
        //     getalltest_error: action.error,
        //   });
         
          // all tests get
          case testActionType.GET_ALL_TEST_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case testActionType.GET_ALL_TEST_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_test: action.result,
            });
          case testActionType.GET_ALL_TEST_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });


            // all popular tests get
          case testActionType.GET_ALL_POPULAR_TEST_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case testActionType.GET_ALL_POPULAR_TEST_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_popular_test: action.result,
            });
          case testActionType.GET_ALL_POPULAR_TEST_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

                // all category get
          case testActionType.GET_ALL_CATEGORY_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case testActionType.GET_ALL_CATEGORY_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_category: action.result,
            });
          case testActionType.GET_ALL_CATEGORY_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get tests by id 
        //   case testActionType.GET_TEST_BY_ID_REQ:
        //   return Object.assign({}, state, {
        //     test_by_id: [],
        //     test_by_id_loading: true,
        //     test_by_id_error: null,
        //   });
        // case testActionType.GET_TEST_BY_ID_SUCCESS:
        //   return Object.assign({}, state, {
        //     test_by_id: action.payload,
        //     test_by_id_loading: false,
        //     test_by_id_error: null,
        //   });
        // case testActionType.GET_TEST_BY_ID_ERR:
        //   return Object.assign({}, state, {
        //     test_by_id: [],
        //     test_by_id_loading: false,
        //     test_by_id_error: action.payload,
        //   });
          // update enable disable test status 
        //   case testActionType.PUT_TEST_STATUS_BY_ID_REQ:
        //     return Object.assign({}, state, {
        //       status: action.status,
              
        //     });
        //   case testActionType.PUT_TEST_STATUS_BY_ID_SUCC:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       test_enable_disable_status:action.payload,
        //     });
        //   case testActionType.PUT_TEST_STATUS_BY_ID_ERR:
        //     return Object.assign({}, state, {
        //       status: action.status,
        //       error: action.error,
        //     });
    
              // UPDATE test DETAILS
        // case testActionType.PUT_TEST_DETAILS_REQ:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //   });
        // case testActionType.PUT_TEST_DETAILS_SUCC:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //     update_test_details: action.result,
        //   });
        // case testActionType.PUT_TEST_DETAILS_ERR:
        //   return Object.assign({}, state, {
        //     status: action.status,
        //     error: action.error,
        //   });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default test;
    
    
    