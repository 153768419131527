import React, {Component} from 'react';
import reports  from '../../../assets/images/reports.png';
import radiology  from '../../../assets/images/radiolgy.png';
import consultant1  from '../../../assets/images/consultant1.png';

class HomePoints extends Component{
    render(){
        return(
          <section className="top-service-col">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="service-box">
                  <div className="card">
                    <div className="card-body">
                      <div className="service-icon">
                        <img src={reports} alt="reports" />
                      </div>
                      <h3>NABL Lab</h3>
                      <p>NABL follows ISO 15189:2012, which is specific ISO followed worldwide for medical laboratories. our lab is continuously striving for quality and this will further progress our cause of providing quality services.</p>
                      {/* <div className="service-btn">
                        <a href="#"><i className="fas fa-chevron-right"></i></a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="service-box">
                  <div className="card">
                    <div className="card-body">
                      <div className="service-icon">
                        <img src={radiology} alt="radiology" />
                      </div>
                      <h3>Radiology</h3>
                      <p>Our radiologists work with well trained technologists and nursing staff to provide personalized imaging for our patients. We are committed to quality service and patient safety.</p>
                      {/* <div className="service-btn">
                        <a href="#"><i className="fas fa-chevron-right"></i></a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 text-center">
                <div className="service-box">
                  <div className="card">
                    <div className="card-body">
                      <div className="service-icon">
                        <img src={consultant1} alt="consultant" />
                      </div>
                      <h3>Specialty Consultation</h3>
                      <p>We provide expertise healthcare consultation services, evaluation and management of a specific problem with appropriate counseling related to Cardiology, Urology, Neurology etc.</p>
                      {/* <div className="service-btn">
                        <a href="#"><i className="fas fa-chevron-right"></i></a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       
        );
    }
}

export default HomePoints;
	
    