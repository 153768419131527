import * as customNotificationActionType from "../../actions/action.customnotification/action.type";

const customnotification = (
    //initialise the initial state
    state = {
        //common error and status
    error: null,
    status: null,

    getallcustomnotification_error: null,
    getallcustomnotification_status: null,
    custom_notification_read_status_error: null,
    custom_notification_read_status_status: null,

    get_all_customnotification:[],
    all_customnotification:null,

    custom_notification_read_status : null,

    
},
action
) =>{
    switch (action.type) {

          //POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID
         case customNotificationActionType.POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case customNotificationActionType.POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              custom_notification_read_status: action.result,
            });
          case customNotificationActionType.POST_CUSTOM_NOTIFICATION_READ_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              custom_notification_read_status_status: action.status,
              custom_notification_read_status_error: action.error,
            });
  
     
             // GET Custom_notification
             case customNotificationActionType.GET_ALL_CUSTOM_NOTIFICATION_REQ:
              return Object.assign({}, state, {
                status: action.status,
              });
            case customNotificationActionType.GET_ALL_CUSTOM_NOTIFICATION_SUCC:
              return Object.assign({}, state, {
                status: action.status,
                all_customnotification: action.result,
              });
            case customNotificationActionType.GET_ALL_CUSTOM_NOTIFICATION_ERR:
              return Object.assign({}, state, {
                status: action.status,
                error: action.error,
              });
  
              //DEFAULT BEHAVIOR
        default:
            return state;
        }
      };
      export default customnotification;